<template>
  <loan-status-alert-container :loan="loan">
    <template #borrower>
      <p>
        Une personne responsable du véhicule a refusé cet emprunt.
        <icon-button
          v-if="loan.comments && loan.comments.length > 0"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'messages')"
          >Voir les messages</icon-button
        >
      </p>
    </template>

    <template #owner>
      <p>
        Une personne responsable du véhicule a refusé cet emprunt.
        <icon-button
          v-if="loan.comments && loan.comments.length > 0"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'messages')"
          >Voir les messages</icon-button
        >
      </p>
    </template>

    <template #admin>
      <p>
        Une personne responsable du véhicule a refusé cet emprunt.
        <icon-button
          v-if="loan.comments && loan.comments.length > 0"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'messages')"
          >Voir les messages</icon-button
        >
      </p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "LoanRejected",
  components: {
    LoanStatusAlertContainer,
    IconButton,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
