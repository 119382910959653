<script>
export default {
  name: "LoanPageEditableBox",
  props: {
    initiallyEditing: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isEditing: this.initiallyEditing,
      size: null,
      transitioning: false,
    };
  },
  computed: {
    style() {
      if (this.size) {
        return `transition: none; height: ${this.size}px !important;`;
      }
      return null;
    },
  },
  watch: {
    initiallyEditing(newVal) {
      if (newVal !== this.isEditing) {
        this.setIsEditing(newVal);
      }
    },
  },
  methods: {
    setIsEditing(isEditing) {
      this.size = this.$el.scrollHeight;
      this.transitioning = true;
      setTimeout(() => {
        this.isEditing = isEditing;
        this.$nextTick(() => (this.size = null));
        setTimeout(() => (this.transitioning = false), 500);
      }, 50);
    },
  },
};
</script>

<template>
  <div
    class="loan-box loan-card"
    :class="{ 'is-editing': isEditing, transitioning: transitioning }"
    :style="style"
  >
    <div class="position-relative">
      <div v-if="transitioning || !isEditing" key="default" class="default">
        <slot name="default" :edit="() => setIsEditing(true)" />
      </div>
      <div v-if="transitioning || isEditing" key="form-section" class="form-section">
        <slot name="form" :close="() => setIsEditing(false)" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loan-box {
  overflow: hidden;
  transition: height 0.5s;

  .default,
  .form-section {
    opacity: 0;
  }
  .default {
    transform: translateY(0);
  }
  .form-section {
    transform: translateY(0);
  }

  &.is-editing {
    .default {
      position: absolute;
      width: 100%;
      background: white;
      left: 0;
      top: 0;
      transition:
        transform 0.2s,
        opacity 0.2s;
      transform: translateY(-1rem);
      opacity: 0;
    }
    .form-section {
      transition:
        transform 0.4s,
        opacity 0.4s;
      transition-delay: 0.1s;
      transform: translateY(0);
      opacity: 1;
    }
  }

  &:not(.is-editing) {
    .form-section {
      position: absolute;
      width: 100%;
      background: white;
      left: 0;
      top: 0;
      transition:
        transform 0.2s,
        opacity 0.2s;
      transform: translateY(1rem);
      opacity: 0;
    }
    .default {
      transition:
        transform 0.4s,
        opacity 0.4s;
      transition-delay: 0.1s;
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>
