<script>
import Bike from "@/assets/svg/bike.svg";
import BikeCargo from "@/assets/svg/bike_cargo.svg";
import BikeCargoElectric from "@/assets/svg/bike_cargo_electric.svg";
import BikeElectric from "@/assets/svg/bike_electric.svg";
import CarElectric from "@/assets/svg/car_electric.svg";
import CarLarge from "@/assets/svg/car_large.svg";
import CarSmall from "@/assets/svg/car_small.svg";
import Trailer from "@/assets/svg/trailer.svg";

export default {
  name: "LoanableIcon",
  components: {
    bike_regular: Bike,
    bike_electric: BikeElectric,
    bike_cargo_regular: BikeCargo,
    bike_cargo_electric: BikeCargoElectric,
    car_small: CarSmall,
    car_large: CarLarge,
    car_electric: CarElectric,
    trailer: Trailer,
    // Backups in case the pricing loanable type is not present
    bike: Bike,
    car: CarLarge,
  },
  props: {
    loanable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      if (this.loanable.pricing_loanable_type) {
        return this.loanable.pricing_loanable_type;
      }

      return this.loanable.type;
    },
  },
  methods: {
    getVariant(loanableType) {
      if (loanableType.startsWith("car")) {
        return "warning";
      }
      if (loanableType.startsWith("bike")) {
        return "success";
      }
      return "primary";
    },
  },
};
</script>

<template>
  <component :is="icon" />
</template>

<style scoped lang="scss"></style>
