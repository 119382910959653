<template>
  <div
    class="loanable-details"
    :class="{
      'fixed-width': fixedWidth,
      'scrolled-to-bottom': scrolledToBottom,
    }"
  >
    <header
      class="loanable-details__header"
      :style="selected ? { 'view-transition-name': 'loanable-image' } : {}"
    >
      <image-carousel
        v-if="loanable && loanable.images && loanable.images.length > 0"
        class="loanable-details__image"
        :images="loanable.images"
        aspect-ratio="16 / 10"
        :preferred-sizes="['loan', 'thumbnail', 'original']"
      />
      <loanable-icon
        v-else
        :loanable="loanable"
        class="loanable-details__image loanable-details__image--default"
      />

      <user-avatar :user="ownerUser" variant="cut-out" class="loanable-details__avatar" />
      <div class="loanable-details__tags">
        <div
          v-if="
            loanable.sharing_mode === 'self_service' ||
            (loanable.sharing_mode === 'hybrid' && userTrustedForLoanable)
          "
        >
          <b-badge variant="primary"> {{ $t("sharing_modes.self_service") | capitalize }} </b-badge>
        </div>
        <div v-else>
          <b-badge variant="warning"> {{ $t("sharing_modes.on_demand") | capitalize }} </b-badge>
        </div>
      </div>
    </header>
    <main class="loanable-details__content">
      <div v-if="basic" class="mb-2">
        <h5 class="p-2">{{ loanable.name }}</h5>
        <pretty-description small :loanable="loanable" />
      </div>
      <template v-else>
        <borrower-profile-warning
          v-if="loanable.type === 'car'"
          size="sm"
          class="borrower-warning"
        />
        <div>
          <b-tabs
            class="loanable-details__tabs"
            nav-wrapper-class="sticky-top bg-white"
            fill
            @input="handleScroll"
          >
            <b-tab title="Véhicule" :active.sync="overviewShown">
              <h5 class="p-2">{{ loanable.name }}</h5>
              <pretty-description small :loanable="loanable" />
            </b-tab>
            <b-tab title="Disponibilité" @click="onAvailabilityFocus">
              <loanable-calendar
                v-if="loadAvailabilities"
                default-view="week"
                :events="availability"
                :editable-events="potentialLoanEvent"
                variant="small"
                :initial-date="potentialLoanEvent[0].start"
                @ready="getAvailability"
                @view-change="getAvailability"
                @event-change="eventChanged"
              ></loanable-calendar>
            </b-tab>
            <b-tab class="mt-3" title="Tarifs" @click="onEstimationFocus">
              <!-- field estimated distance -->
              <div class="p-2">
                <b-form-group :label="$t('loans.fields.estimated_distance') | capitalize">
                  <b-form-input
                    :id="`estimated_distance_${loanable.id}`"
                    v-mask="'#######'"
                    name="estimated_distance"
                    type="text"
                    :min="0"
                    :rules="{ min_value: 0 }"
                    :formatter="integer"
                    :value="potentialLoan.estimated_distance"
                    size="sm"
                    @input="updateEstimatedDistance"
                  />
                </b-form-group>

                <div class="layout-loading-overlay-container">
                  <tiny-invoice v-if="borrowerInvoice.items.length > 0" :invoice="borrowerInvoice">
                  </tiny-invoice>
                  <div v-else class="text-center h4">Gratuit!</div>
                  <layout-loading v-if="loadingEstimation" overlay />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>
    </main>
    <footer v-if="!basic" class="loanable-details__footer">
      <!-- Only one button will be displayed at a time. -->
      <icon-button
        v-if="loanable.available"
        variant="success"
        :disabled="loanable.type === 'car' && !canLoanCar"
        size="sm"
        @click="createLoan"
      >
        Demande d'emprunt
      </icon-button>
      <icon-button
        v-else-if="!loanable.tested"
        v-b-tooltip.hover
        variant="outline-success"
        :title="
          `Cliquez pour valider la disponibilité avec les paramètres ` + `d'emprunt sélectionnés`
        "
        :disabled="invalidDuration"
        :loading="searchingLoanables"
        size="sm"
        @click.stop.prevent="$emit('test')"
      >
        Valider la disponibilité
      </icon-button>
      <icon-button v-else variant="outline-info" disabled size="sm"> Indisponible </icon-button>
    </footer>
  </div>
</template>

<script>
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";
import BorrowerProfileWarning from "@/components/Loan/BorrowerProfileWarning.vue";

import LoanableCalendar from "@/components/Loanable/Calendar.vue";
import LoanableIcon from "@/components/Loanable/LoanableIcon.vue";
import PrettyDescription from "@/components/Loanable/PrettyDescription.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ImageCarousel from "@/components/shared/ImageCarousel.vue";
import UserAvatar from "@/components/User/Avatar.vue";
import { debounce } from "@/helpers/debounce";
import { integer } from "@/helpers/filters";
import { summarizeBorrowerInvoice } from "@/helpers/invoices";
import { canLoanCar } from "@/helpers/permissions/users";

import locales from "@/locales";
import { get } from "@/requests/server";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "LoanableDetails",
  components: {
    LoanableIcon,
    PrettyDescription,
    IconButton,
    ImageCarousel,
    BorrowerProfileWarning,
    LayoutLoading,
    LoanableCalendar,
    TinyInvoice,
    UserAvatar,
  },
  props: {
    loanable: {
      type: Object,
      required: false,
      default: undefined,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    // weather to display only the images and description list
    basic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availability: [],
      loadingEstimation: false,
      overviewShown: true,
      // Lazy-load loanable availabilities, only once the proper tab is focused
      loadAvailabilities: false,
      scrolledToBottom: true,
      borrowerInvoiceData: null,
    };
  },
  computed: {
    potentialLoan() {
      return this.$store.state.loans.item;
    },
    selected() {
      return this.potentialLoan?.loanable?.id === this.loanable.id;
    },
    searchingLoanables() {
      return this.$store.state["loanable.search"].availabilitiesLoading;
    },
    invalidDuration() {
      return this.potentialLoan?.duration_in_minutes <= 0;
    },
    ownerUser() {
      return this?.loanable?.owner?.user;
    },
    potentialLoanEvent() {
      const searchDuration = this.potentialLoan.duration_in_minutes;
      const end = dayjs(this.potentialLoan.departure_at)
        .add(searchDuration, "minute")
        .format("YYYY-MM-DD HH:mm");
      const start = dayjs(this.potentialLoan.departure_at).format("YYYY-MM-DD HH:mm");

      return [
        {
          type: "loan",
          start,
          end,
          data: {
            status: "potential",
          },
        },
      ];
    },
    searchParams() {
      return {
        departure_at: this.potentialLoan?.departure_at,
        duration_in_minutes: this.potentialLoan?.duration_in_minutes,
        estimated_distance: this.potentialLoan?.estimated_distance,
      };
    },
    canLoanCar() {
      return canLoanCar(this.$store.state.user);
    },
    borrowerInvoice() {
      return summarizeBorrowerInvoice(this.borrowerInvoiceData, {
        ownerName: this.loanable.owner.user.name,
        groupContributions: true,
      });
    },
    userTrustedForLoanable() {
      return this.$store.state.user?.trusted_for_loanables?.includes(this.loanable.id);
    },
  },
  watch: {
    searchParams: function (val, oldVal) {
      // Only load estimation for calendar or estimation tabs
      if (
        !this.overviewShown &&
        (val.departure_at !== oldVal.departure_at ||
          val.duration_in_minutes !== oldVal.duration_in_minutes ||
          val.estimated_distance !== oldVal.estimated_distance)
      ) {
        this.debouncedLoadEstimation();
      }
    },
  },
  destroyed() {
    this.$el.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$el.addEventListener("scroll", this.handleScroll);
    // Do it on mount to figure out if we need a scroll shadow right at the beginning
    setTimeout(this.handleScroll, 50);

    this.$emit("mounted", this.loanable);
  },
  created() {
    this.debouncedLoadEstimation = debounce(this.loadEstimation);
  },
  methods: {
    integer,
    handleScroll() {
      this.scrolledToBottom =
        this.$el.scrollHeight - this.$el.scrollTop - this.$el.clientHeight < 5;
    },
    async eventChanged(e) {
      const searchDate = dayjs(e.startDate).format("YYYY-MM-DD HH:mm");

      // When dragging from one week to the next, we lose the event refrence and it's length, in which
      // case we can fallback to the stored last duration.
      const duration = e.endTimeMinutes
        ? (e.daysCount - 1) * 24 * 60 + e.endTimeMinutes - e.startTimeMinutes
        : this.$store.state["loanable.search"].lastSearchDuration;

      await this.$store.commit("loans/patchItem", {
        departure_at: searchDate,
        duration_in_minutes: duration,
      });
    },
    createLoan() {
      this.$emit("select");
    },
    onAvailabilityFocus() {
      this.loadAvailabilities = true;
      this.loadEstimation();
    },
    async onEstimationFocus() {
      if (!this.potentialLoan.estimated_distance) {
        // If no estimated distance set previously, we set it to some value so that the estimation
        // doesn't return 0 (free) when evaluation distance-based pricings.
        await this.updateEstimatedDistance(10);
      }

      await this.loadEstimation();
    },
    async updateEstimatedDistance(estimatedDistance) {
      await this.$store.commit("loans/patchItem", {
        estimated_distance: estimatedDistance,
      });
    },
    async loadEstimation() {
      if (this.searchParams.duration_in_minutes < 15) {
        return;
      }

      this.loadingEstimation = true;

      const { data } = await get(`/loanables/${this.loanable.id}/test`, {
        axiosRequestConfig: {
          params: {
            loanable_id: this.loanable.id,
            ...this.searchParams,
          },
        },
        notifications: { action: "estimation du coût" },
        requestOptions: { cancelId: `/loanables/${this.loanable.id}/test` },
        cleanupCallback: () => (this.loadingEstimation = false),
      });

      this.borrowerInvoiceData = data.borrower_invoice;

      this.$store.commit("loans/patchItem", { loanable: data });

      this.$store.commit("loanable.search/setLoanableTested", this.loanable.id);
      await this.$store.commit("loanable.search/setLoanableAvailable", {
        id: this.loanable.id,
        available: data.available,
      });
    },
    async getAvailability({ view, startDate, endDate, firstCellDate, lastCellDate }) {
      let start, end;

      // Include out-of-scope days in month view.
      if (view === "month") {
        // Must convert [, ] interval to [, ) by adding one second to the end time.
        start = this.$dayjs(firstCellDate);
        end = this.$dayjs(lastCellDate).add(1, "s");
      } else {
        // Must convert [, ] interval to [, ) by adding one second to the end time.
        start = this.$dayjs(startDate);
        end = this.$dayjs(endDate).add(1, "s");
      }

      try {
        const { data } = await get(`/loanables/${this.loanable.id}/availability`, {
          axiosRequestConfig: {
            params: {
              start: start.format("YYYY-MM-DD HH:mm:ss"),
              end: end.format("YYYY-MM-DD HH:mm:ss"),
              responseMode: "available",
            },
          },
          requestOptions: { cancelId: "loanable-availability" },
          notifications: { action: "disponibilités du véhicule" },
        });

        this.availability = data.map((e) => {
          e.type = "availability";
          return e;
        });
      } catch (e) {
        if (axios.isCancel(e)) {
          // expected, do nothing.
        } else {
          throw e;
        }
      }
    },
  },
  i18n: {
    messages: {
      en: {
        loans: locales.en.loans,
        ...locales.en.loanables,
      },
      fr: {
        loans: locales.fr.loans,
        ...locales.fr.loanables,
        bill_item_types: locales.fr.bill_items.fields.item_types,
      },
    },
  },
};
</script>

<style lang="scss">
.loanable-details {
  font-size: 0.875rem;
  max-height: 28rem;

  .loanable-details__content {
    padding: 0;

    dl {
      margin-bottom: 0;
    }
  }

  .loanable-details__footer {
    position: sticky;
    bottom: 0;
    width: 100%;

    background-color: white;
    // to be over the sticky tabs
    z-index: 1050;
  }

  &.fixed-width {
    // Fixed width for the moment. We'll deal with resizing later.
    width: 16rem;
  }
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  .badge {
    padding: 0.4rem;
  }

  dt,
  dd {
    padding-left: 0.5rem;
  }

  &__header {
    position: relative;
    width: 100%;
  }
  &__avatar {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  &__tabs .nav-link {
    padding: 0.5rem 0;
  }

  .borrower-warning {
    padding: 0.75rem;
    margin: 0.5rem;
  }

  &__footer {
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 3px 10px 0 $grey;
    transition-duration: 0.1s;
    border-top: 1px solid $light-grey;
  }

  &.scrolled-to-bottom .loanable-details__footer {
    box-shadow: 0 0 10px 0 transparent;
  }

  &__image {
    position: relative;
    aspect-ratio: 16 / 10;
    width: 100%;
  }
  &__image--default {
    fill: $locomotion-green;
    position: relative;
    padding: 1rem;
  }
  &__owner-avatar {
    position: absolute;
    width: 4rem;
    height: 4rem;
    bottom: 0;
    right: 0;
  }
  /* Temporary element until we create sections. */
  &__loanable-title {
    text-align: center;
  }
  &__tags {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  &__estimated-fare {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    min-height: 3rem;
  }
  &__estimated-fare-loading {
    height: 3rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trip-details {
    margin: 0 auto;

    th {
      text-align: left;
    }
    th,
    td {
      padding: 0 0.75rem;
    }
  }

  .trip-details__total {
    border-top: 1px solid black;
  }
}
</style>
