<template>
  <b-form-group
    class="forms-files-uploader"
    :label="noLabel ? null : label"
    :label-for="field"
    :class="{ center }"
  >
    <ul class="mb-0">
      <template v-if="value">
        <li v-for="file in value" :key="file.id" class="mb-1">
          <safe-file :file="file" />
          <icon-button
            v-if="!disabled"
            size="sm"
            class="ml-2"
            role="remove-item"
            @click="() => removeFile(file.id)"
          />
        </li>
      </template>
      <li v-if="loading">
        <layout-loading with-button />
      </li>
      <li class="input">
        <validation-provider
          ref="validator"
          v-slot="{ validated, valid, errors }"
          :rules="{ required }"
          :name="label"
          :detect-input="false"
        >
          <b-form-file
            :id="field"
            :ref="`${field}fileinput`"
            :state="uploadError ? false : validated ? valid : null"
            :placeholder="placeholder"
            :disabled="disabled || loading || value.length >= max"
            :name="field"
            :accept="accept.join(',')"
            browse-text="Sélectionner"
            drop-placeholder="Déposer le fichier ici..."
            @change="handleChange"
          />
          <div v-if="errors || uploadError" class="invalid-feedback">
            {{ uploadError ? uploadError : errors[0] }}
          </div>
        </validation-provider>
        <b-alert v-if="value.length >= max" variant="warning" show class="mt-2">
          Vous avez atteint le nombre maximum de fichiers pour ce champ. Supprimez d'anciens
          fichiers pour en ajouter d'autres.
        </b-alert>
      </li>
    </ul>
  </b-form-group>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { uploadFile } from "@/requests/fileRequests";

export default {
  name: "FormsFilesUploader",
  components: { IconButton, SafeFile },
  props: {
    accept: {
      default: () => [
        "*.png",
        "*.jpg",
        "*.jpeg",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "*.pdf",
        "application/pdf",
      ],
      type: Array,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    field: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    placeholder: {
      default: "Ajouter un fichier...",
      type: String,
    },
    removeFileText: {
      default: "Retirer le fichier",
      type: String,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Array,
      require: false,
      default: () => [],
    },
    max: {
      type: Number,
      require: false,
      default: 5,
    },
    center: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    uploadError: null,
  }),
  watch: {
    value(newValue) {
      this.$refs.validator.syncValue(newValue);
      this.$refs.validator.validate();
    },
  },
  mounted() {
    this.$refs.validator.value = this.value;
    this.$refs.validator.initialValue = this.value;
  },
  methods: {
    handleChange(event) {
      switch (event.type) {
        case "drop":
          this.uploadFile(event.dataTransfer.files);
          break;
        default:
          this.uploadFile(event.target.files);
          break;
      }
    },
    removeFile(fileId) {
      this.$emit(
        "input",
        this.value.filter((f) => f.id !== fileId)
      );
    },
    async uploadFile(fileList) {
      this.loading = true;
      this.$emit("uploading");
      const { file, error } = await uploadFile(this.field, fileList);
      this.$emit("uploaded");
      this.loading = false;

      if (file) {
        this.$emit("input", [...this.value, file]);
      }
      this.uploadError = error;

      if (this.$refs[`${this.field}fileinput`]) {
        this.$refs[`${this.field}fileinput`].reset();
      }
      return file;
    },
  },
};
</script>

<style lang="scss">
.forms-files-uploader {
  .custom-file-label {
    overflow: hidden;
  }
  ul {
    padding-left: 0;
    li {
      margin-left: 2rem;
    }
    .input {
      min-width: 15rem;
      list-style: none;
    }
  }
  &.center {
    text-align: center;
    ul {
      text-align: initial;
      display: inline-block;
    }
  }
}
</style>
