import RestModule from "../RestModule";

export default new RestModule("communityUsers", {
  exportFields: [
    "approved_at",
    "suspended_at",
    "community.id",
    "community.name",
    "user.id",
    "user.created_at",
    "user.email",
    "user.last_name",
    "user.name",
    "user.phone",
    "user.postal_code",
    "user.borrower.approved_at",
    "user.deleted_at",
  ],
});
