import { post } from "@/requests/server";

export default {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    data(state, data) {
      state.data = data;
    },
    cancelToken(state, cancelToken) {
      state.cancelToken = cancelToken;
    },
  },
  actions: {
    async request({ commit }, request) {
      const { data } = await post("/auth/password/request", request, {
        requestOptions: { cancelId: "password-request" },
        notifications: { action: "demande de réinitialisation de mot de passe" },
      });

      commit("data", data);
    },
    async reset({ commit, dispatch }, { email, newPassword, newPasswordRepeat, token }) {
      // Ensure user is logged out when restting password
      await dispatch("logout", {}, { root: true });

      const { data } = post(
        "/auth/password/reset",
        {
          email,
          password: newPassword,
          password_confirmation: newPasswordRepeat,
          token,
        },
        {
          requestOptions: { cancelId: "password-reset" },
          notifications: { action: "enregistrement du nouveau mot de passe" },
        }
      );

      commit("data", data);
    },
  },
};
