<script>
import Vue from "vue";

export default Vue.extend({
  name: "InvitationHeader",
  props: {
    invitation: {
      type: Object,
      required: true,
    },
  },
});
</script>

<template>
  <h2 class="text-center">
    <span v-if="invitation.inviterName" class="h4 text-locomotion">
      {{ invitation.inviterName }}
    </span>
    <span v-else class="h4 text-muted"> On </span>
    <span class="h4 text-muted">vous invite à rejoindre </span><br />
    {{ invitation.communityName ? "LocoMotion " + invitation.communityName : "LocoMotion" }}
  </h2>
</template>

<style scoped lang="scss"></style>
