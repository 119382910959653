<template>
  <loan-status-alert-container :loan="loan" :borrower-action-required="!noCharge">
    <template v-if="noCharge" #borrower>
      <p>
        Merci d'avoir participé à LocoMotion en faisant votre emprunt! L'emprunt se complétera
        automatiquement d'ici une heure.
      </p>
    </template>
    <template v-else-if="contributionOnly" #borrower>
      <p>
        Merci d'avoir participé à LocoMotion en faisant votre emprunt! Vous pouvez modifier votre
        <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'contribution')"
          >contribution</icon-button
        >
        pour compléter l'emprunt.
      </p>
    </template>
    <template v-else #borrower>
      <p>
        Merci de compléter cet emprunt en
        <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'contribution')"
          >payant</icon-button
        >.
      </p>
    </template>

    <template v-if="noCharge" #owner>
      <p>
        Merci d'avoir participé à LocoMotion avec cet emprunt! L'emprunt se complétera
        automatiquement d'ici une heure.
      </p>
    </template>
    <template v-else-if="contributionOnly" #owner>
      <p>{{ loan.borrower_user.name }} peut modifier sa contribution avant de clore l'emprunt.</p>
    </template>
    <template v-else #owner>
      <p>{{ loan.borrower_user.name }} doit payer l'emprunt.</p>
    </template>

    <template v-if="noCharge" #admin>
      <p>L'emprunt est gratuit et se complétera automatiquement d'ici une heure.</p>
    </template>
    <template v-else-if="contributionOnly" #admin>
      <p>{{ loan.borrower_user.name }} peut modifier sa contribution avant de clore l'emprunt.</p>
    </template>
    <template v-else #admin>
      <p>{{ loan.borrower_user.name }} doit payer l'emprunt.</p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "LoanValidated",
  components: {
    IconButton,
    LoanStatusAlertContainer,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contributionOnly() {
      return (
        this.loan.borrower_may_contribute &&
        !this.loan.borrower_must_pay_compensation &&
        !this.loan.borrower_must_pay_insurance
      );
    },
    noCharge() {
      return (
        !this.loan.borrower_must_pay_insurance &&
        !this.loan.borrower_must_pay_compensation &&
        !this.loan.borrower_may_contribute
      );
    },
  },
};
</script>

<style lang="scss"></style>
