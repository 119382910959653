<template>
  <b-row class="loanable-calendar-legend">
    <b-col md="4" class="loanable-calendar-legend__item">
      <calendar-month-cell class="loanable-calendar-legend__icon" availability="available" current
        >XX</calendar-month-cell
      >
      Disponible
    </b-col>
    <b-col md="4" class="loanable-calendar-legend__item">
      <calendar-month-cell
        class="loanable-calendar-legend__icon"
        availability="partially-available"
        current
        >XX</calendar-month-cell
      >
      Disponibilité limitée
    </b-col>
    <b-col md="4" class="loanable-calendar-legend__item">
      <calendar-month-cell class="loanable-calendar-legend__icon" availability="unavailable" current
        >XX</calendar-month-cell
      >
      Indisponible
    </b-col>
  </b-row>
</template>

<script>
import CalendarMonthCell from "@/components/Loanable/CalendarMonthCellContent.vue";

export default {
  name: "LoanableCalendarLegend",
  components: {
    CalendarMonthCell,
  },
};
</script>

<style lang="scss">
.loanable-calendar-legend {
  .loanable-calendar-legend__item {
    display: flex;
    align-items: center;
  }

  .loanable-calendar-legend__icon {
    width: 3rem;
    height: 3rem;
  }
}
</style>
