<template>
  <div v-if="itemLoaded && routeDataLoaded" class="profile-locomotion">
    <profile-form
      v-if="item"
      :loading="loading"
      :user="item"
      :form="form"
      :changed="changed"
      show-reset
      @reset="reset"
      @submit="submit"
    >
      <b-row v-if="hasAddressChanged">
        <b-col>
          <b-alert variant="danger" show class="address-change-warning">
            Si votre changement d'adresse entraine un changement de quartier, vous devrez soumettre
            une nouvelle preuve de résidence.
          </b-alert>
        </b-col>
      </b-row>
    </profile-form>
  </div>
  <layout-loading v-else />
</template>

<script>
import ProfileForm from "@/components/Profile/ProfileForm.vue";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "ProfileLocomotion",
  components: { ProfileForm },
  mixins: [DataRouteGuards, FormMixin],
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "me",
    },
  },
  computed: {
    hasAddressChanged() {
      return this.item.address !== this.initialItem.address;
    },
  },
};
</script>

<style lang="scss">
.profile-locomotion {
  margin-bottom: 3em;
  .address-change-warning {
    margin-top: 20px;
  }
}
</style>
