import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timeZone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const locale = {
  name: "fr",
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  months:
    "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort: "janv_févr_mars_avril_mai_juin_juil_août_sept_oct_nov_déc".split("_"),
  weekStart: 1,
  formats: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  ordinal: (n) => {
    const o = n === 1 ? "er" : "";
    return `${n}${o}`;
  },
};

dayjs.locale(locale, null, true);
dayjs.locale("fr");

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(timeZone);
dayjs.extend(utc);

dayjs.prototype.startOfDay = function () {
  return this.hour(0).minute(0).second(0).millisecond(0);
};

const REGEX_TIMEZONE_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;

/**
 * Creates a dayjs object with the timezone flag with the given timezone. If date already had a
 * timezone, the resulting date is converted, shifting the time and offset to the provided timezone.
 *
 * This is necessary since dayjs.tz doesn't take into account the date's initial timezone.
 *
 * @param {String} date
 * @param {String} timezone
 * @return {dayjs.Dayjs}
 */
dayjs.atTz = (date, timezone) => {
  const match = date.match(REGEX_TIMEZONE_OFFSET_FORMAT);
  if (match === null) {
    return dayjs.tz(date, timezone);
  }
  return dayjs(date).tz(timezone);
};

export default dayjs;
