<template>
  <layout-loading v-if="!itemLoaded" />
  <div v-else class="loanable-form">
    <loanable-type-selection
      v-if="!item.id && !item.type"
      :available-types="ownerAvaliableTypes"
      :loanable="item"
      :owner-user="ownerUser"
    />

    <validation-observer v-else ref="observer" v-slot="{ validate }">
      <!--
        Submit is bound to buttons directly so as to prevent vue-cal to
        trigger a submit event for an unknown reason.
      -->
      <availability-status :loanable="item" />
      <b-form :novalidate="true" class="form loanable-form__form" @submit.stop.prevent>
        <form-section
          toggleable
          :force-show="openSections"
          class="mt-2"
          section-title="Informations de base"
          :inititally-visible="!item.published"
        >
          <b-row>
            <b-col lg="8">
              <forms-validated-input
                v-model="item.name"
                name="name"
                :label="$t('fields.name') | capitalize"
                :rules="form.general.name.rules"
                type="text"
                :placeholder="placeholderOrLabel('name') | capitalize"
                :description="$t('descriptions.name')"
              />
            </b-col>

            <b-col lg="4">
              <forms-validated-input
                v-model="item.type"
                name="type"
                :label="$t('fields.type') | capitalize"
                :rules="form.general.type.rules"
                type="select"
                :options="typeOptions"
                :placeholder="placeholderOrLabel('type') | capitalize"
                :disabled="!!item.id"
                disabled-tooltip="On ne peut pas changer le type d'un véhicule existant."
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="8">
              <!-- If we want to limit the position to the community area, we can added the
               'bounded' attribute to this input. -->
              <forms-validated-input
                v-model="item.position"
                name="position"
                :description="form.general.position.description"
                :rules="{
                  required: item.published || validatePublishing,
                }"
                :label="$t('fields.position') | capitalize"
                type="point"
                :placeholder="placeholderOrLabel('position') | capitalize"
                :polygons="loanablePolygons"
              />
            </b-col>

            <b-col>
              <forms-validated-input
                v-model="item.location_description"
                name="location_description"
                :description="$t('descriptions.location_description')"
                :rules="form.general.location_description.rules"
                :rows="12"
                :label="$t('fields.location_description') | capitalize"
                type="textarea"
                :placeholder="placeholderOrLabel('location_description') | capitalize"
              />
            </b-col>
          </b-row>

          <forms-validated-input
            v-model="item.images"
            type="images"
            name="image"
            :label="$t('fields.images') | capitalize"
            :preview-aspect-ratio="'16 / 10'"
            :description="$t('descriptions.images')"
            main-image-field="main_image"
            :rules="{
              required: item.published || validatePublishing,
            }"
          />
          <b-row>
            <b-col>
              <forms-validated-input
                v-model="item.comments"
                name="comments"
                :description="$t('descriptions.comments')"
                :rules="form.general.comments.rules"
                :label="$t('fields.comments') | capitalize"
                type="textarea"
                :placeholder="placeholderOrLabel('comments') | capitalize"
              />
            </b-col>
          </b-row>
        </form-section>

        <form-section
          section-title="Partage"
          :force-show="openSections"
          toggleable
          :inititally-visible="!item.published"
        >
          <forms-validated-input
            v-model="item.instructions"
            name="instructions"
            :description="$t('descriptions.instructions')"
            :rules="form.general.instructions.rules"
            :label="$t('fields.instructions') | capitalize"
            type="textarea"
            :placeholder="placeholderOrLabel('instructions') | capitalize"
          />

          <forms-validated-input
            v-if="item.type === 'car'"
            v-model="item.details.has_onboard_notebook"
            type="checkbox"
            name="has_onboard_notebook"
            label="J’ai placé un carnet de bord dans le coffre à gants"
            :rules="{
              ...carForm.has_onboard_notebook.rules,
              accepted: item.published || validatePublishing,
            }"
          >
            <template v-if="guideUrl" #description
              ><a :href="guideUrl" target="_blank">Où récupérer mon carnet de bord?</a>
            </template>
          </forms-validated-input>

          <forms-validated-input
            v-model="item.shared_publicly"
            type="checkbox"
            name="shared_publicly"
            label="Afficher publiquement"
            description="Partager le type du véhicule, sa position et sa disponibilité avec nos partenaires, par example sur l'application Transit."
          />

          <forms-validated-input
            v-if="showSelfServiceInput"
            v-model="item.sharing_mode"
            type="select"
            :options="sharingModeOptions"
            name="sharing_mode"
            :label="$t('fields.sharing_mode') | capitalize"
            :description="$t('descriptions.sharing_mode') | capitalize"
          />

          <conditional-context
            v-if="item.sharing_mode === 'hybrid'"
            show
            label="Options pour le libre partage limité"
          >
            <forms-validated-input
              v-model="item.trusted_borrower_instructions"
              name="trusted_borrower_instructions"
              :description="$t('descriptions.trusted_borrower_instructions')"
              :label="$t('fields.trusted_borrower_instructions') | capitalize"
              type="textarea"
              :placeholder="placeholderOrLabel('trusted_borrower_instructions') | capitalize"
            />

            <b-alert v-if="!item.owner || !item.id" variant="info" show>
              Sauvegardez le nouveau véhicule avant d'ajouter des membres au réseau de confiance.
            </b-alert>
            <paginated-table
              v-else
              ref="trustedBorrowerTable"
              endpoint="/loanables/roles"
              :columns="trustedBorrowersColumns"
              :show-generate-csv="false"
              :fetch-params="{
                loanable_id: item.id,
                role: 'trusted_borrower',
              }"
            >
              <template #head>
                <h4 class="m-lg-0">
                  <a id="trusted_borrowers" href="#trusted_borrowers"></a>
                  <b-icon class="text-primary" icon="shield-fill-check" />&nbsp;Réseau de confiance
                </h4>
              </template>
              <template #head-buttons>
                <relation-input
                  placeholder="Ajouter"
                  class="add-user-input"
                  :value="null"
                  reset-after-select
                  :min-search-length="1"
                  :query="{
                    slug: 'users',
                    value: 'id',
                    text: 'full_name',
                    details: 'description',
                    params: {
                      fields: 'id,full_name,description',
                      canBeAddedToTrustedBorrowers: item.id,
                    },
                  }"
                  name="new_trusted_member"
                  @input="addTrustedBorrower"
                />
              </template>
              <template #cell(actions)="{ item }">
                <validated-button
                  :action="async () => removeTrustedBorrower(item)"
                  :question="`Êtes-vous sûr de vouloir retirer ${item.user.full_name}?`"
                  label="retirer"
                  icon="trash"
                  size="sm"
                  variant="ghost-danger"
                />
              </template>
            </paginated-table>
          </conditional-context>
        </form-section>

        <form-section
          v-if="item.type === 'bike'"
          :force-show="openSections"
          section-title="Détails du vélo"
          :inititally-visible="!item.published"
          toggleable
        >
          <forms-validated-input
            v-model="item.details.model"
            :label="$t('bikes.fields.model') | capitalize"
            :rules="{
              required: item.published || validatePublishing,
            }"
            name="model"
            type="text"
          />

          <forms-validated-input
            v-model="item.details.bike_type"
            :label="$t('bikes.fields.bike_type') | capitalize"
            :rules="{
              required: item.published || validatePublishing,
            }"
            name="bike_type"
            type="select"
            :options="form.bike.bike_type.options"
          />

          <forms-validated-input
            v-model="item.details.size"
            :label="$t('bikes.fields.size') | capitalize"
            :rules="{
              required: item.published || validatePublishing,
            }"
            name="size"
            type="select"
            :options="form.bike.size.options"
          />
        </form-section>
        <template v-else-if="item.type === 'car'">
          <form-section
            section-title="Détails de l'auto"
            :force-show="openSections"
            :inititally-visible="!item.published"
            toggleable
          >
            <conditional-context
              show
              label="Ne peut être modifié une fois le véhicule publié"
              additional-info="Si vous avez fait une erreur et désirez la corriger, contactez info@locomotion.app."
            >
              <forms-validated-input
                v-model="item.details.brand"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.brand') | capitalize"
                :rules="{
                  required: validatePublishing,
                }"
                name="brand"
                type="text"
              />

              <forms-validated-input
                v-model="item.details.model"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.model') | capitalize"
                :rules="{
                  required: validatePublishing,
                }"
                name="model"
                type="text"
              />

              <forms-validated-input
                v-model="item.details.transmission_mode"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.transmission_mode') | capitalize"
                :options="[
                  { text: 'Automatique', value: 'automatic' },
                  { text: 'Manuelle', value: 'manual' },
                ]"
                :rules="{
                  required: validatePublishing,
                  oneOf: ['manual', 'automatic'],
                }"
                name="transmission_mode"
                type="select"
              />

              <forms-validated-input
                v-model="item.details.engine"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.engine') | capitalize"
                :options="[
                  { text: 'Essence', value: 'fuel' },
                  { text: 'Diesel', value: 'diesel' },
                  { text: 'Électrique', value: 'electric' },
                  { text: 'Hybride', value: 'hybrid' },
                ]"
                :rules="{
                  required: validatePublishing,
                  oneOf: ['fuel', 'diesel', 'electric', 'hybrid'],
                }"
                name="engine"
                type="select"
              />

              <forms-validated-input
                v-model="item.details.pricing_category"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.pricing_category') | capitalize"
                :options="[
                  {
                    text: 'Petite auto (compacte, sous-compacte, hybride non-branchable)',
                    value: 'small',
                  },
                  { text: 'Grosse auto (van, VUS, pick-up)', value: 'large' },
                  { text: 'Auto électrique (électrique, hybride branchable)', value: 'electric' },
                ]"
                :rules="{
                  required: validatePublishing,
                  oneOf: ['small', 'large', 'electric'],
                }"
                name="pricing_category"
                type="select"
              >
                <template #description>
                  La catégorie choisie détermine la compensation monétaire que vous recevrez des
                  emprunteurs.
                  <a target="_blank" href="https://info.locomotion.app/tarifs-autopartage/"
                    >Voir les détails sur cette page.</a
                  >
                </template>
              </forms-validated-input>
            </conditional-context>

            <forms-validated-input
              v-model="item.details.plate_number"
              :label="$t('cars.fields.plate_number') | capitalize"
              :rules="{
                required: validatePublishing,
              }"
              name="plate_number"
              type="text"
            />
          </form-section>
          <form-section
            id="report-section"
            section-title="État de l'auto"
            :force-show="openSections"
            toggleable
            :inititally-visible="!item.published"
          >
            <p>
              Faites part à votre voisinage de l'état de votre auto. Lorsque vous la partagerez,
              vous pourrez vous appuyer sur ce document dans vos discussions.
            </p>
            <ul>
              <li>
                Téléchargez
                <a href="/fiche_etat_de_l_auto.pdf" download
                  >la fiche d'état de l'auto <b-icon icon="download"
                /></a>
              </li>
              <li>Remplissez-la</li>
              <li>
                Placez-en une copie imprimée dans le
                <template v-if="guideUrl">
                  <a :href="guideUrl" target="_blank">carnet de bord</a>
                </template>
                <template v-else>carnet de bord</template>

                <forms-validated-input
                  v-model="item.details.has_report_in_notebook"
                  type="checkbox"
                  :rules="{
                    accepted: item.published || validatePublishing,
                  }"
                  name="has_report_in_notebook"
                  label="C'est fait!"
                />
              </li>
              <li>
                Placez-en une copie ici :

                <forms-validated-input
                  v-model="item.details.report"
                  :label="$t('cars.fields.report') | capitalize"
                  :rules="{
                    ...carForm.report.rules,
                    required: item.published || validatePublishing,
                  }"
                  name="report"
                  type="file"
                />
              </li>
              <li>Gardez-la bien à jour!</li>
            </ul>
          </form-section>
          <form-section
            section-title="Assurance"
            :force-show="openSections"
            toggleable
            :inititally-visible="!item.published"
          >
            <conditional-context
              show
              label="Ne peut être modifié une fois le véhicule publié"
              additional-info="Si vous avez fait une erreur et désirez la corriger, contactez info@locomotion.app."
            >
              <forms-validated-input
                v-model="item.details.year_of_circulation"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.year_of_circulation') | capitalize"
                :min="1900"
                :max="nextYear"
                :rules="{
                  required: validatePublishing,
                  digits: 4,
                  min_value: 1900,
                  max_value: nextYear,
                }"
                name="year_of_circulation"
                type="number"
              />

              <forms-validated-input
                v-model="item.details.value_category"
                :disabled="publishedFieldsDisabled"
                :label="$t('cars.fields.value_category') | capitalize"
                :options="[
                  {
                    text: '50 000$ et moins',
                    value: 'lte50k',
                  },
                  { text: '50 001$ à 70 000$', value: 'lte70k' },
                  { text: '70 001$ à 100 000$', value: 'lte100k' },
                ]"
                :rules="{
                  required: validatePublishing,
                  oneOf: ['lte50k', 'lte70k', 'lte100k'],
                }"
                name="value_category"
                type="select"
              />
            </conditional-context>

            <forms-validated-input
              v-model="item.details.has_informed_insurer"
              :label="$t('cars.fields.has_informed_insurer') | capitalize"
              name="has_informed_insurer"
              :rules="{
                ...carForm.has_informed_insurer.rules,
                accepted: item.published || validatePublishing,
              }"
              type="checkbox"
            >
              <template #label>
                C’est l’assureur de LocoMotion qui couvre l’auto lorsqu’elle est empruntée via ce
                site web. Je dois tout de même aviser mon assureur automobile que mon auto est en
                partage en lui faisant parvenir
                <a
                  href="https://drive.google.com/uc?export=download&id=1rL5w_APLJM5o_Qny0SPwQM3p4zjrfkdZ"
                  download
                >
                  cette lettre</a
                >.
              </template>
            </forms-validated-input>

            <forms-validated-input
              v-model="item.details.insurer"
              :label="$t('cars.fields.insurer') | capitalize"
              name="insurer"
              :rules="{ ...carForm.insurer.rules, required: item.published || validatePublishing }"
              type="suggest"
              :options="insurers"
            />

            <forms-validated-input
              v-model="item.details.papers_location"
              :label="$t('cars.fields.papers_location') | capitalize"
              :options="[
                { text: 'Dans l\'auto', value: 'in_the_car' },
                { text: 'À récupérer avec la clé', value: 'to_request_with_car' },
              ]"
              :rules="{
                required: item.published || validatePublishing,
                oneOf: ['in_the_car', 'to_request_with_car'],
              }"
              name="papers_location"
              type="select"
            />
          </form-section>
          <form-section
            v-if="currentUserIsOwner"
            section-title="Paiements"
            :inititally-visible="!item.published"
            toggleable
          >
            Les paiements des emprunteurs seront versés à votre solde sur locomotion.app. Vous
            pouvez réclamer cet argent à n'importe quel moment sur le
            <router-link to="/app">tableau de bord</router-link>. Vous pouvez
            <b-link v-b-modal:bank-details-form>fournir vos informations bancaires</b-link> pour les
            versements dès maintenant.
            <b-modal id="bank-details-form" hide-footer hide-header size="lg">
              <bank-details-form
                show-cancel
                @saved="() => $bvModal.hide('bank-details-form')"
                @cancel="() => $bvModal.hide('bank-details-form')"
              />
            </b-modal>
          </form-section>
        </template>
        <form-section
          v-else-if="item.type === 'trailer'"
          :force-show="openSections"
          section-title="Détails de la remorque"
          :inititally-visible="!item.published"
          toggleable
        >
          <forms-builder v-model="item.details" :definition="form.trailer" entity="trailers" />
        </form-section>
        <div v-else class="form__section text-center">
          <span> Sélectionnez un type de véhicule pour poursuivre la configuration. </span>
        </div>

        <form-section :force-show="openSections" toggleable section-title="Droits de gestion">
          <coowners-form :loanable="item" />
        </form-section>

        <form-section toggleable class="form__section" section-title="Disponibilités">
          <a id="availability" />
          <loanable-availability-rules :changed="changed" :loanable="item" :loading="loading" />
        </form-section>

        <form-buttons v-if="!hideButtons">
          <template v-if="!item.published">
            <icon-button
              v-if="changed"
              role="send"
              :onclick="
                async function () {
                  await saveAndPublish(validate);
                }
              "
              :disabled="loading || publishing || wrongLoanableType"
            >
              Enregistrer et publier
            </icon-button>
            <icon-button
              v-else
              role="send"
              :onclick="
                async function () {
                  await publish(validate);
                }
              "
              :disabled="loading || publishing || wrongLoanableType"
            >
              Publier
            </icon-button>
            <b-icon
              v-if="wrongLoanableType"
              v-b-tooltip="
                `Ce type de véhicule ne peut être partagé dans ${
                  currentUserIsOwner ? ' votre' : 'la'
                } communauté${currentUserIsOwner ? '' : ' du propriétaire'}.`
              "
              scale="0.85"
              shift-v="5"
              icon="question-circle"
            />

            &nbsp;
            <icon-button
              variant="link"
              :disabled="!changed || loading"
              :onclick="
                async function () {
                  publishing = false;
                  validatePublishing = false;
                  await $nextTick();
                  await save(validate);
                }
              "
            >
              Enregister et continuer plus tard
            </icon-button>
          </template>
          <template v-else>
            <icon-button
              role="save"
              :onclick="
                async function () {
                  await save(validate);
                }
              "
              :disabled="!changed || loading"
            >
              Enregistrer
            </icon-button>
          </template>
          &nbsp;
          <icon-button
            v-if="showReset"
            role="reset"
            :disabled="!changed || loading"
            @click="$emit('reset')"
          >
            {{ $t("reset") | capitalize }}
          </icon-button>
        </form-buttons>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import FormsBuilder from "@/components/Forms/Builder.vue";
import RelationInput from "@/components/Forms/RelationInput.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import LoanableAvailabilityRules from "@/components/Loanable/AvailabilityRules.vue";
import AvailabilityStatus from "@/components/Loanable/AvailabilityStatus.vue";
import CoownersForm from "@/components/Loanable/CoownersForm.vue";
import FormSection from "@/components/Loanable/FormSection.vue";
import LoanableTypeSelection from "@/components/Loanable/LoanableTypeSelection.vue";
import BankDetailsForm from "@/components/PaymentMethod/BankDetailsForm.vue";
import ConditionalContext from "@/components/shared/ConditionalContext.vue";
import FormButtons from "@/components/shared/FormButtons.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { capitalize } from "@/helpers/filters";
import { extractMultipolygonPaths } from "@/helpers/geoJson";
import { isAdminOfLoanable, isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { canSeeDeletedUser, isGlobalAdmin } from "@/helpers/permissions/users";
import locales from "@/locales";
import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import FormMixin from "@/mixins/FormMixin";
import UserMixin from "@/mixins/UserMixin";
import { put } from "@/requests/server";
import { addTrustedBorrower, removeTrustedBorrower } from "@/requests/trustedBorrowersRequests";
import dayjs from "dayjs";

export default {
  name: "LoanableForm",
  components: {
    ValidatedButton,
    RelationInput,
    PaginatedTable,
    IconButton,
    FormButtons,
    ConditionalContext,
    AvailabilityStatus,
    LoanableTypeSelection,
    BankDetailsForm,
    CoownersForm,
    FormSection,
    FormsBuilder,
    FormsValidatedInput,
    LoanableAvailabilityRules,
  },
  mixins: [UserMixin, FormLabelsMixin, FormMixin],
  props: {
    hideButtons: {
      type: Boolean,
      required: false,
    },
    showReset: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUserIsOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openSections: false,
      publishing: false,
      validatePublishing: false,
      insurers: [
        "Allstate",
        "Alpha Assurance",
        "Arar",
        "Aviva",
        "Banque Nationale Assurances",
        "Belairdirect",
        "CAA Québec",
        "CIBC",
        "Co-operators",
        "Desjardins Assurances",
        "Financière ScotiaVie",
        "iA Groupe financier",
        "Intact",
        "La Capitale",
        "PMT Roy",
        "Promutuel",
        "Prysm Assurances",
        "RBC",
        "RSA",
        "Sonnet",
        "SSQ",
        "TD Meloche Monnex",
        "Wawanesa",
      ],
    };
  },
  computed: {
    wrongLoanableType() {
      return !this.ownerAvaliableTypes.includes(this.item?.type);
    },
    loanableCommunities() {
      return this.ownerUser?.communities ?? [];
    },
    loanablePolygons() {
      return this.loanableCommunities
        .filter((c) => !!c.area?.coordinates)
        .flatMap((c) => extractMultipolygonPaths(c.area));
    },
    guideUrl() {
      for (const community of this.loanableCommunities) {
        if (community.starting_guide_url) {
          return community.starting_guide_url;
        }
      }
      return null;
    },
    carForm() {
      const carKeys = Object.keys(this.form.car);

      const form = {};

      // we add all the car form property and our custom report_template property at the right time, see https://262.ecma-international.org/6.0/#sec-ordinary-object-internal-methods-and-internal-slots-ownpropertykeys
      for (let i = 0; i < carKeys.length; i++) {
        const key = carKeys[i];
        form[key] = this.form.car[key];

        if (key === "report") {
          form["report_template"] = {};
        }
      }

      return form;
    },
    ownerAvaliableTypes() {
      return this.ownerUser?.available_loanable_types || [];
    },
    typeOptions() {
      return [
        {
          text: "Types",
          disabled: true,
          value: null,
        },
        ...(this.form?.general.type.options || []).filter(
          (t) => this.ownerAvaliableTypes.includes(t.value) || this.item?.type === t.value
        ),
      ];
    },
    isAvailable() {
      return (
        !!this.item.id &&
        (this.item.availability_mode === "always" ||
          (this.item.availability_json && JSON.parse(this.item.availability_json).length > 0))
      );
    },
    nextYear() {
      return dayjs().add(1, "year").year();
    },
    ownerUser() {
      if (this.currentUserIsOwner) {
        return this.$store.state.user;
      }

      return this.item?.owner?.user ?? {};
    },
    publishedFieldsDisabled() {
      return this.item?.published && !isGlobalAdmin(this.user);
    },
    showSelfServiceInput() {
      return isAdminOfLoanable(this.user, this.item) || isCoownerOrOwner(this.user, this.item);
    },
    ignoreFields() {
      let ignoreFields = ["coowners", "show_owner_as_contact", "published", "availability_status"];

      if (this.currentUserIsOwner) {
        ignoreFields = [...ignoreFields, "owner", "owner_id"];
      }

      if (this.publishedFieldsDisabled) {
        ignoreFields = [
          ...ignoreFields,
          "details.pricing_category",
          "details.year_of_circulation",
          "details.transmission_mode",
          "details.engine",
          "details.value_category",
          "details.brand",
          "details.model",
        ];
      }
      return ignoreFields;
    },
    sharingModeOptions() {
      return [
        { value: "on_demand", text: capitalize(this.$t("sharing_modes.on_demand")) },
        { value: "self_service", text: capitalize(this.$t("sharing_modes.self_service")) },
        { value: "hybrid", text: capitalize(this.$t("sharing_modes.hybrid")) },
      ];
    },
    trustedBorrowersColumns() {
      let trustedBorrowersColumns = [
        new Column("user.full_name", "Nom"),
        Column.withRelation(
          "granted_by_user.full_name",
          "Accordé par",
          {
            relation: "users",
            label: "full_name",
            field: "id",
            details: "email",
            params: { with_deleted: canSeeDeletedUser(this.$store.state.user) },
          },
          "granted_by_user_id",
          { showByDefault: false }
        ),
      ];

      if (this.item && isAdminOfLoanable(this.$store.state.user, this.item)) {
        trustedBorrowersColumns.push(new Column("user.phone", "Téléphone"));
        trustedBorrowersColumns.push(new Column("user.email", "Courriel"));
      }

      return trustedBorrowersColumns;
    },
  },
  watch: {
    ownerAvaliableTypes(newTypes) {
      // For new loanables, we clear the loanable type selection if it is not valid when owner
      // is changed.
      if (this.item && !this.item.id && !newTypes.find((o) => o === this.item.type)) {
        this.item.type = null;
      }
    },
  },
  methods: {
    itemLoadedCallback() {
      if (!this.item.id && this.currentUserIsOwner) {
        this.$store.commit("loanables/mergeItem", {
          owner: { id: this.user.owner.id, user: this.user },
        });
      }
    },
    async saveAndPublish(validate) {
      this.validatePublishing = true;
      // Next tick so that the publishing state updates
      await this.$nextTick();

      const saved = await this.save(validate);
      if (saved) {
        await this.publish(validate);
      }
    },
    async publish(validate) {
      if (this.item.published || this.wrongLoanableType) {
        return;
      }

      this.validatePublishing = true;
      // Next tick so that the publishing state updates
      await this.$nextTick();
      const validForPublishing = await validate();
      if (!validForPublishing) {
        // this.publishing = false;
        // Next tick so that the UI catches up with validation errors
        await this.scrollToFirstError();
        return;
      }

      this.publishing = true;

      const { data } = await put(
        `/loanables/${this.item.id}/publish`,
        {},
        {
          notifications: { action: "publication", onSuccess: "Véhicule publié" },
          cleanupCallback: () => (this.publishing = false),
        }
      );

      this.item.published = true;
      this.item.availability_status = data.availability_status;
      this.publishing = false;
    },
    async save(validate) {
      this.openSections = false;
      const valid = await validate();
      if (!valid) {
        await this.scrollToFirstError();
        return false;
      }
      await this.submitLoanable();

      return true;
    },
    async submitLoanable() {
      const isNew = !this.item.id;
      await this.submit();

      if (isNew) {
        this.$store.commit("addNotification", {
          title: "Véhicule créé!",
          variant: "success",
        });
      }

      this.$emit("submitted");
    },

    async scrollToFirstError() {
      await this.$nextTick();
      const invalidItems = document.getElementsByClassName("is-invalid");
      if (invalidItems.length > 0) {
        this.openSections = true;
        setTimeout(() => this.$scrollTo(invalidItems[0]), 250);
      }
    },
    async addTrustedBorrower(user) {
      if (!this.item.id || !user?.id) {
        return;
      }

      await addTrustedBorrower(this.item.id, user.id);
      this.$refs.trustedBorrowerTable.refresh();
    },
    async removeTrustedBorrower(trustedBorrower) {
      await removeTrustedBorrower(trustedBorrower);
      this.$refs.trustedBorrowerTable.refresh();
    },
  },
  i18n: {
    messages: {
      en: {
        cars: locales.en.cars,
        bikes: locales.en.bikes,
        ...locales.en.loanables,
        ...locales.en.forms,
      },
      fr: {
        cars: locales.fr.cars,
        bikes: locales.fr.bikes,
        ...locales.fr.loanables,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
.loanable-form {
  .add-user-input {
    min-width: 15rem;
  }
}
</style>
