<template>
  <div class="tag">
    <slot />
  </div>
</template>

<script>
export default {
  name: "RoundedTag",
};
</script>

<style scoped lang="scss">
.tag {
  font-size: 1.25rem;
  display: flex;
  background: $light;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
}
</style>
