export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit(`${this.slug}/cancelRequests`);

    next();
  },
  mounted() {
    this.loadItem(true);
  },
  beforeRouteUpdate(to, from, next) {
    // Handle navigating to the same page with a different ID
    if (to.params.id != from.params.id && from.params.id !== "new") {
      this.itemLoaded = false;
      next();
      // Reload new item, making sure to clear it while it loads.
      this.$nextTick(() => this.loadItem(true));
    }

    next();
  },
  data() {
    return {
      itemLoaded: false,
      slug: this.$route.meta.slug,
    };
  },
  computed: {
    changed() {
      // shallow clone
      const initialItem = {
        ...this.initialItem,
      };
      const currentItem = {
        ...this.item,
      };

      // remove ignored fields set on the component using this mixin
      for (const ignoreField of this.ignoreFields ?? []) {
        initialItem[ignoreField] = null;
        currentItem[ignoreField] = null;
      }

      return JSON.stringify(initialItem) !== JSON.stringify(currentItem);
    },
    context() {
      return this.$store.state[this.slug];
    },
    form() {
      return this.context.form || this.$route.meta.form;
    },
    initialItem() {
      return this.$store.getters[`${this.slug}/initialItem`];
    },
    initialItemJson() {
      return this.$store.getters[`${this.slug}/initialItemJson`];
    },
    item: {
      get() {
        return this.context.item;
      },
      set(item) {
        this.$store.commit(`${this.slug}/item`, item);
      },
    },
    loading() {
      return this.context.loading;
    },
    params() {
      return this.$route.meta.params;
    },
    parentPath() {
      const parentPathParts = this.$route.path.split("/").filter((p) => !!p);
      parentPathParts.pop();
      return `/${parentPathParts.join("/")}`;
    },
  },
  methods: {
    onDestroy() {
      this.$router.push(this.parentPath);
    },
    onCreated() {
      this.$router.replace(this.$route.fullPath.replace("new", this.item.id));
    },
    shouldSkipLoad() {
      return this.skipLoadItem && typeof this.skipLoadItem === "function" && this.skipLoadItem();
    },
    async loadItem(clearPreviousItem = false) {
      const { dispatch } = this.$store;
      try {
        if (!this.shouldSkipLoad()) {
          if (clearPreviousItem) {
            this.$store.commit(`${this.slug}/item`, null);
            this.$store.commit(`${this.slug}/initialItem`, null);
          }
          if (this.id === "new") {
            await dispatch(`${this.slug}/loadEmpty`);
          } else {
            await dispatch(`${this.slug}/retrieveOne`, {
              id: this.id,
              params: this.params,
            });
          }
        }
        this.itemLoaded = true;

        if (this.itemLoadedCallback && typeof this.itemLoadedCallback === "function") {
          await this.itemLoadedCallback();
        }
      } catch (e) {
        if (e.request) {
          switch (e.request.status) {
            case 404:
              this.$router.replace(this.parentPath);
              break;
            default:
              throw e;
          }
        }

        throw e;
      }
    },
    reset() {
      this.$store.commit(`${this.slug}/resetItem`);
    },
    async submit() {
      if (!this.item.id) {
        await this.$store.dispatch(`${this.slug}/createItem`, {
          params: this.params,
          fieldsAllowlist: this.requestFieldsAllowlist,
        });
        this.onCreated();
      } else {
        // remove ignored fields set on the component using this mixin
        await this.$store.dispatch(`${this.slug}/updateItem`, {
          params: this.params,
          fieldsBlocklist: this.ignoreFields ?? [],
          fieldsAllowlist: this.requestFieldsAllowlist,
          // If no custom toast defined in component using this mixin, use default toast
          showToastOnSuccess: !this.savingToast,
        });
      }

      if (typeof this.afterSubmit === "function") {
        this.afterSubmit();
      }

      if (this.savingToast) {
        this.$store.commit("addNotification", this.savingToast);
      }
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  watch: {
    item: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue === null && newValue) {
          if (this.$route.hash) {
            setTimeout(() => {
              const el = document.getElementById(this.$route.hash.substring(1));
              if (el) {
                this.$scrollTo(el);
              }
            }, 100);
          }
        }
        this.$store.commit(`${this.slug}/item`, this.item);
      },
    },
  },
};
