export default {
  fr: {
    email: "Courriel",
    forgot_password: "J'ai oublié mon mot de passe",
    google: "Se connecter avec Google",
    login: "Connexion",
    login_submit: "Se connecter",
    or: "OU",
    password: "Mot de passe",
    remember_me: "Se souvenir de moi",
  },
  en: {
    email: "Email",
    forgot_password: "Forgot password?",
    google: "Sign in with Google",
    login: "Login",
    login_submit: "Login",
    or: "OR",
    password: "Password",
    remember_me: "Remember me",
  },
};
