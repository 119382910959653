<template>
  <div class="loan-search-form">
    <b-form class="form loan-search-form" @submit.stop.prevent="submit">
      <!-- title -->
      <div>
        <h4 class="loan-search-form--no-margin">Qu'aimeriez-vous emprunter</h4>
        <h4 class="loan-search-form--green">à vos voisin-e-s?</h4>
      </div>
      <!---->
      <!-- buttons to select types of vehicles -->
      <b-form-group>
        <b-form-checkbox-group
          v-if="possibleLoanableTypes.length > 1"
          id="loanable_type"
          buttons
          button-variant="outline-primary"
          :disabled="loading"
          class="loanable-buttons"
          :checked="selectedLoanableTypes"
          @change="emitLoanableTypes"
        >
          <b-checkbox v-if="possibleLoanableTypes.includes('car')" class="no-inset" value="car">
            <svg-car />
            <div>Auto</div>
          </b-checkbox>
          <b-checkbox v-if="possibleLoanableTypes.includes('bike')" class="no-inset" value="bike">
            <svg-bike />
            <div>Vélo</div>
          </b-checkbox>
          <b-checkbox
            v-if="possibleLoanableTypes.includes('trailer')"
            class="no-inset"
            value="trailer"
          >
            <svg-trailer />
            <div>Remorque</div>
          </b-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
      <BorrowerProfileWarning v-if="selectedLoanableTypes.includes('car')" />
      <!---->
      <div class="search-dates">
        <div class="search-date-input">
          <label>{{ $t("fields.departure_at") | capitalize }}</label>
          <forms-date-time-picker
            ref="depature"
            zoneless
            :value="item.departure_at"
            :disabled-dates-fct="disabledBeforeToday"
            :disabled-times-fct="disabledBeforeNow"
            @input="changeSearchDate"
          >
            <template #footer>
              <button class="mx-btn mx-btn-text" @click="setToEarliestDeparture">Maintenant</button>
            </template>
          </forms-date-time-picker>
        </div>

        <div class="search-date-input">
          <label>{{ $t("fields.return_at") | capitalize }}</label>

          <forms-date-time-picker
            ref="return"
            v-model="returnAt"
            zoneless
            :disabled-dates-fct="disabledBeforeDepartureDay"
            :disabled-times-fct="disabledBeforeDeparture"
            @input="changeSearchDuration"
          >
            <template #footer>
              <button class="mx-btn mx-btn-text" @click="() => setDuration(60)">1 heure</button>
              <button class="mx-btn mx-btn-text" @click="() => setDuration(60 * 3)">
                3 heures
              </button>
              <button class="mx-btn mx-btn-text" @click="() => setDuration(60 * 24)">1 jour</button>
            </template>
          </forms-date-time-picker>
        </div>
      </div>

      <!-- text for loan invalid duration -->
      <b-alert v-if="invalidDuration" show variant="danger">
        La durée de l'emprunt doit être supérieure ou égale à 15 minutes.
      </b-alert>
      <!---->
      <!-- search button -->
      <icon-button
        type="submit"
        pill
        variant="success"
        class="mr-2 mb-2"
        :disabled="loading || invalidDuration"
        :loading="loading"
        icon="search"
      >
        Rechercher
      </icon-button>
      <!---->
    </b-form>
  </div>
</template>

<script>
import Bike from "@/assets/svg/bike.svg";
import Car from "@/assets/svg/car.svg";
import Trailer from "@/assets/svg/trailer.svg";
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import BorrowerProfileWarning from "@/components/Loan/BorrowerProfileWarning.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { getEarliestDeparture } from "@/helpers/loanDepartures";

import locales from "@/locales";

import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import dayjs from "dayjs";

export default {
  name: "SearchForm",
  components: {
    FormsDateTimePicker,
    IconButton,
    BorrowerProfileWarning,
    "svg-bike": Bike,
    "svg-car": Car,
    "svg-trailer": Trailer,
  },
  mixins: [FormLabelsMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedLoanableTypes: {
      type: Array,
      required: true,
    },
    possibleLoanableTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    invalidDuration() {
      // Invalid if the duration of a loan is not greater than 0 minute.
      return !(this.item.duration_in_minutes > 0);
    },
    returnAt: {
      get() {
        return dayjs
          .utc(this.item.departure_at)
          .add(this.item.duration_in_minutes, "minute")
          .format("YYYY-MM-DD HH:mm");
      },
      set(val) {
        this.$store.commit("loans/patchItem", {
          duration_in_minutes: dayjs.utc(val).diff(dayjs.utc(this.item.departure_at), "minute"),
        });
      },
    },
  },
  beforeMount() {
    const { lastSearchDate, lastSearchDuration } = this.$store.state["loanable.search"];
    if (lastSearchDate) {
      this.item.departure_at = lastSearchDate;
    }
    if (lastSearchDuration) {
      this.item.duration_in_minutes = lastSearchDuration;
    }
    const minimumTime = getEarliestDeparture();
    if (!this.item.departure_at || minimumTime.isAfter(this.item.departure_at)) {
      this.item.departure_at = minimumTime.format("YYYY-MM-DD HH:mm");
      this.$store.commit("loanable.search/lastSearchDate", null);
    }
    if (!this.item.duration_in_minutes) {
      this.item.duration_in_minutes = 60;
    }
    this.$store.commit("loans/item", this.item);
  },
  methods: {
    disabledBeforeToday(date) {
      return dayjs().isAfter(date, "day");
    },
    disabledBeforeNow(date) {
      return dayjs().isSameOrAfter(date, "minute") || dayjs(date).minute() > 45;
    },
    disabledBeforeDeparture(date) {
      return dayjs(date).isSameOrBefore(this.item.departure_at, "minute");
    },
    disabledBeforeDepartureDay(date) {
      return dayjs(date).isBefore(this.item.departure_at, "day");
    },
    setToEarliestDeparture() {
      this.item.departure_at = getEarliestDeparture().format("YYYY-MM-DD HH:mm");
      this.$refs.depature.close();
    },
    setDuration(duration) {
      this.item.duration_in_minutes = duration;
      this.$refs.return.close();
    },
    emitLoanableTypes(value) {
      this.$emit("selectLoanableTypes", value);
    },
    submit() {
      this.$emit("submit");
    },
    changeSearchDate(newDepartureAt) {
      if (this.invalidDuration) {
        // If duration is negative, we allow user to move each date independently
        const newDuration = dayjs.utc(this.returnAt).diff(dayjs.utc(newDepartureAt), "minute");
        this.$store.commit("loans/patchItem", {
          departure_at: newDepartureAt,
          duration_in_minutes: newDuration,
        });
      } else {
        this.$store.commit("loans/patchItem", {
          departure_at: newDepartureAt,
        });
      }

      this.$store.commit("loanable.search/lastSearchDate", newDepartureAt);
      this.$emit("changed");
    },
    changeSearchDuration() {
      this.$store.commit("loanable.search/lastSearchDuration", this.item.duration_in_minutes);
      this.$emit("changed");
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.loans,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.loan-search-form {
  container-type: inline-size;

  &__button-borrower {
    margin: 15px 0 0 0;
  }

  .search-dates {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
    @container (width > 30rem) {
      flex-direction: row;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 700;
  }

  .b-icon.bi {
    font-size: 100%;
    vertical-align: -0.15em;
  }

  .loanable-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    svg {
      height: 22px;
      width: 34px;
    }
    svg path {
      fill: currentColor;
    }
  }
  .loanable-buttons label.btn {
    border-radius: 10px;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    flex-basis: 5rem;
    border-width: 2px;
    border-radius: 10px !important;
    padding-left: 6px;
    padding-right: 6px;
    align-items: center;
    justify-content: flex-end;
  }

  .loanable-buttons label:hover {
    background-color: #fff;
    color: $primary;
  }

  .loanable-buttons .btn.disabled {
    background-color: #fff;
    border-color: $light !important;
    color: #7a7a7a;
  }

  .loanable-buttons .active.btn.disabled {
    background-color: $light;
  }
}

.loan-search-form--green {
  color: #00b1aa;
}

.loan-search-form--no-margin {
  margin: 0;
}

.loan-search-form--margin-bottom {
  margin-bottom: 15px;
}
</style>
