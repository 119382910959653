<template>
  <footer class="footer">
    <b-container class="layout-footer">
      <b-row>
        <b-col md="6" lg="8">
          <div class="layout-footer__logo">
            <a href="https://info.locomotion.app">
              <logo />
            </a>
          </div>
          <div class="layout-footer__text">
            <p class="introduction">
              Le réseau québécois pour partager les véhicules de votre quartier.
            </p>

            <p class="social-media-icons">
              <a
                href="https://www.facebook.com/locomotion.qc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon icon="facebook" />
              </a>
              <a
                href="https://www.instagram.com/locomotion.qc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon icon="instagram" />
              </a>
              <a
                href="https://www.youtube.com/@locomotion-qc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon icon="youtube" />
              </a>
              <a
                href="https://www.linkedin.com/company/locomotion-qc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b-icon icon="linkedin" />
              </a>
            </p>
          </div>
        </b-col>
        <b-col md="3" lg="2" class="layout-footer__text align-self-end">
          <b-link to="/conditions" title="Conditions d'utilisation - LocoMotion"
            >Conditions d'utilisation</b-link
          >
        </b-col>
        <b-col md="3" lg="2" class="layout-footer__text">
          <h4>Contact</h4>
          <ul>
            <li>
              <a href="https://info.locomotion.app/medias/"> Médias </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://8646f2c8.sibforms.com/serve/MUIFAKjm7MMOOwefloIhdI_Oac8fyd8pUHdoug6__OHIVoaqy1DysvM_RyYBtbAxLMmJq2mqWT1fWB46SGRO9jV2ibYyN-Gw37qlqSCFqWwwNBxAZLWoQVt0TyPH9VUN5z84IYMLVKsfS54tnxtelOy_YfPnaQ-zTQ4lSHsUGd2Ld6NB2LHAGcevHCjsTFfWs0CzxsZAJltwnMRf"
              >
                M'inscrire à l'infolettre
              </a>
            </li>
            <li>
              <b-link href="mailto:info@locomotion.app"> info@locomotion.app </b-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <div class="footer-license">
      <a href="https://gitlab.com/solon-collectif/locomotion.app" target="_blank"> Site web</a> sous
      licence
      <a href="https://www.gnu.org/licenses/agpl-3.0.fr.html" target="_blank"> AGPLv3 </a>
    </div>
  </footer>
</template>

<script>
import logo from "@/assets/svg/logo.svg";

export default {
  name: "LayoutFooter",
  components: {
    logo,
  },
  data() {
    return {
      googleFormUrl:
        "https://docs.google.com/forms/d/e/" +
        "1FAIpQLSd-O_aK6WycUjD2Vd3Xsle_UwV61P84-3kFZGzWAzeASg2bHA/viewform",
    };
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
footer.footer {
  background-color: $main-bg;
  border-top: 1px solid $light-grey;
}
.layout-footer.container {
  padding: 2.5rem;
  color: $locomotion-dark-green;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .layout-footer__logo {
    margin-bottom: 0.5rem;
    svg {
      width: 164px;
      height: 24px;
      max-width: 100%;
      path {
        fill: #339b93;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .layout-footer__text {
    font-size: 1rem;
    line-height: 18px;

    .introduction {
      margin-bottom: 2rem;
      a {
        text-decoration: none;
      }
    }

    h4 {
      margin-bottom: 2rem;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      list-style: none;
      padding: 0;
      margin: 0 0 1rem 0;
      &:last-child {
        margin: 0;
      }
    }

    a {
      text-decoration: none;
      color: $locomotion-dark-green;

      &:hover {
        text-decoration: underline;
        color: $locomotion-light-green;
      }
    }
  }
  .social-media-icons {
    display: flex;
    gap: 1rem;
    margin-bottom: 0;

    img,
    svg {
      width: 2rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;

    .row {
      gap: 1.5rem;
    }

    .layout-footer__text h4 {
      margin-bottom: 1rem;
    }
    .layout-footer__text li {
      margin-bottom: 0.5rem;
    }

    .social-media-icons {
      justify-content: center;
    }
  }
}
.footer-license {
  background: $locomotion-dark-green;
  color: rgb(213, 219, 230);
  text-align: center;
  font-size: 0.75rem;
  padding: 0.5rem;
  a {
    color: white;
    &:hover {
      color: white;
    }
  }
}
</style>
