<script>
import IconButton from "@/components/shared/IconButton.vue";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower } from "@/helpers/permissions/loans";

export default {
  name: "LoanPriceSummary",
  components: { IconButton },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    isNewLoan: {
      type: Boolean,
      default: false,
    },
    borrowerInvoice: {
      type: Object,
      default: () => null,
    },
    ownerInvoice: {
      type: Object,
      default: () => null,
    },
    estimating: {
      type: Boolean,
      default: false,
    },
    estimatedDistance: {
      type: Boolean,
      default: false,
    },
    estimatedDuration: {
      type: Boolean,
      default: false,
    },
    estimatedContribution: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBorrower() {
      return isBorrower(this.$store.state.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.$store.state.user, this.loan.loanable);
    },
    showOwnerInvoice() {
      return this.isCoownerOrOwner && !this.isBorrower;
    },
    currentTotal() {
      if (this.isNewLoan) {
        return null;
      }

      if (this.showOwnerInvoice) {
        return this.loan.owner_invoice?.user_balance_change ?? 0;
      }
      return -(this.loan.borrower_invoice?.user_balance_change ?? 0);
    },
    newTotal() {
      if (this.showOwnerInvoice) {
        return this.ownerInvoice?.user_balance_change ?? 0;
      }
      return -(this.borrowerInvoice?.user_balance_change ?? 0);
    },
    hasInvoice() {
      if (this.showOwnerInvoice) {
        return !!this.loan.owner_invoice;
      }
      return !!this.loan.borrower_invoice;
    },
    totalChanged() {
      return this.hasInvoice && !this.isNewLoan && this.newTotal !== this.currentTotal;
    },
    label() {
      if (this.loan.status !== "completed" && this.loan.status !== "validated") {
        if (this.showOwnerInvoice) {
          return "Compensation estimée";
        }
        return "Coût estimé";
      }

      if (this.showOwnerInvoice) {
        return "Compensation";
      }
      return "Coût";
    },
  },
};
</script>

<template>
  <div class="price-summary-card">
    {{ label }}
    <template v-if="!isNewLoan"
      >(<b-link @click="$emit('jump', 'contribution')">détails</b-link>)</template
    >
    <div class="price-summary">
      <span v-if="totalChanged || showOwnerInvoice || newTotal !== 0" class="total"
        >{{ newTotal | currency }}{{ totalChanged ? "*" : "" }}</span
      >
      <span v-else class="total">Gratuit</span>
      <span v-if="totalChanged" class="old-total">{{ currentTotal | currency }}</span>
      <b-spinner v-if="estimating" class="spinner" small />
    </div>
    <div v-if="totalChanged" class="included-changes">
      <hr class="my-2" />
      * Inclut les modification en cours&nbsp;:
      <span>
        <icon-button
          v-if="estimatedDuration"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'dates')"
        >
          dates
        </icon-button>
        <span v-if="estimatedDuration && (estimatedDistance || estimatedContribution)"
          >{{ estimatedContribution ? ", " : " et " }}
        </span>
        <icon-button
          v-if="estimatedDistance"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'info')"
        >
          distance parcourue
        </icon-button>
        <span v-if="estimatedContribution && (estimatedDistance || estimatedDuration)"> et </span>
        <icon-button
          v-if="estimatedContribution"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'contribution')"
        >
          contribution </icon-button
        >.
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.price-summary-card {
  padding: 1rem;
  background: $white;
  border: 1px solid $light-grey;
  border-radius: 1rem;
}
.included-changes {
  font-size: 0.925rem;
}
.price-summary {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.total {
  color: $primary;
  font-size: 1.125rem;
  font-weight: bold;
}
.old-total {
  color: $grey;
  text-decoration-line: line-through;
  text-decoration-thickness: 0.1rem;
}

.spinner {
  align-self: center;
  color: $grey;
}
</style>
