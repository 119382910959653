<template>
  <div class="layout-page">
    <div :class="`page ${name}`">
      <vue-headful :title="fullTitle" />

      <div class="page__background" :class="bgClass">
        <b-container v-if="!wide" :fluid="fluid" tag="main" :class="mainClass">
          <b-card v-if="box" class="box">
            <layout-loading v-if="loading" />
            <slot v-else />
          </b-card>
          <layout-loading v-else-if="loading" />
          <slot v-else />
        </b-container>
        <main v-else :class="mainClass">
          <b-card v-if="box" class="box">
            <layout-loading v-if="loading" />
            <slot v-else />
          </b-card>
          <layout-loading v-else-if="loading" />
          <slot v-else />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from "@/helpers/filters";

export default {
  name: "LayoutPage",
  props: {
    bgColor: {
      type: String,
      required: false,
      default: "",
    },
    bgImage: {
      type: Boolean,
      default: false,
      required: false,
    },
    centered: {
      type: Boolean,
      default: false,
      required: false,
    },
    fluid: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    wide: {
      type: Boolean,
      required: false,
      default: false,
    },
    box: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fullTitle() {
      if (this.treeTitle) {
        return `${this.treeTitle} | LocoMotion`;
      }

      return "LocoMotion";
    },
    bgClass() {
      return [this.bgImage ? "with-tiled-bg" : "", this.bgColor].filter((c) => !!c).join(" ");
    },
    mainClass() {
      return ["page__content", this.centered ? "centered" : ""].filter((c) => !!c).join(" ");
    },
    treeTitle() {
      const firstMatchedParts = this.$route.matched.slice(0, this.$route.matched.length - 1);
      const parts = firstMatchedParts.reduce((acc, r) => {
        if (r.meta && r.meta.title) {
          acc.push(capitalize(this.$i18n.t(r.meta.title)));
        }
        return acc;
      }, []);

      if (this.pageTitle) {
        parts.push(this.pageTitle);
      }

      return parts.reverse().join(" | ");
    },
    pageTitle() {
      if (this.$route.meta && this.$route.meta.title) {
        return capitalize(this.$i18n.t(this.$route.meta.title));
      }

      return "";
    },
  },
};
</script>

<style lang="scss">
.page {
  &__content {
    &.green {
      background-color: $locomotion-green;

      .box {
        // Copy $medium-shadow from _variables.scss, but with more appropriate color
        box-shadow:
          0 1px 1px hsl(178deg 92% 23% / 0.6),
          0 2px 4px hsl(178deg 92% 23% / 0.4),
          0 12px 16px hsl(178deg 92% 23% / 0.3);
      }
    }

    &.centered {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .box {
      width: 36rem;
      max-width: 100%;
      margin: 0 auto;
      box-shadow: $medium-shadow;
    }
  }

  &__background {
    background-color: $main-bg;

    &.with-tiled-bg {
      background-image: url("/home-motif.png");
      // Add padding so we can see the background even if the content is full width
      padding: 1rem;
      &.green {
        background-image: url("/bg-tile.png");
      }
    }
    &.green {
      background-color: $locomotion-green;
    }
  }
}
</style>
