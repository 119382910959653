<template>
  <paginated-table
    id="admin-exports-table"
    ref="table"
    endpoint="exports"
    :columns="columns"
    :extra-data="extraData"
    selectable
    label="Exports"
    sync-filters-with-url
    :show-generate-csv="false"
    @row-selected="
      (exports) => {
        selectedExports = exports;
        allExportsSelected = false;
      }
    "
  >
    <template #head-buttons>
      <b-button-group variant="secondary" class="ml-1">
        <b-dropdown left :disabled="selectedExports.length === 0" text="Actions groupées">
          <b-dropdown-item disabled
            >{{ $tc("list.selected", selectedExportsCount, { count: selectedExportsCount }) }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              !allExportsSelected && $refs.table && $refs.table.total !== selectedExports.length
            "
            @click="selectAll"
          >
            Sélectionner tous les exports correspondant aux filtres ({{
              $refs.table && $refs.table.total
            }})
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item v-if="canDeleteExport" @click="deleteExports">
            Supprimer définitivement
          </b-dropdown-item>
          <b-dropdown-item
            v-if="canBatchCancelExports"
            :disabled="!selectedCanceleable"
            @click="cancelExports"
          >
            Annuler export(s) en cours
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
    </template>
    <template #cell(file.original_filename)="{ item }">
      <span v-if="item.status !== 'completed'">
        [Non complété] <br />
        {{ item.file.original_filename }}
      </span>
      <safe-file v-else-if="item.file" :file="item.file" download />
    </template>

    <template #cell(actions)="{ item }">
      <b-dropdown size="sm" variant="white-primary" no-caret toggle-class="mr-0">
        <template #button-content>
          <b-icon icon="three-dots-vertical" /><span class="sr-only">actions</span>
        </template>
        <b-dropdown-item
          v-if="canCancelExport(item) && item.status === 'in_process'"
          variant="warning"
          @click="() => cancelExport(item)"
        >
          <b-icon icon="stop-circle" />
          Annuler
        </b-dropdown-item>

        <b-dropdown-item v-if="canDeleteExport" variant="danger" @click="() => deleteExport(item)">
          <b-icon icon="trash" />
          Supprimer
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </paginated-table>
</template>

<script>
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import SafeFile from "@/components/shared/SafeFile.vue";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import locales from "@/locales";
import { del, put } from "@/requests/server";

export default {
  name: "AdminExports",
  components: {
    SafeFile,
    PaginatedTable,
  },
  data() {
    const columns = [
      new Column("id", "ID", "id"),
      Column.withSelect("status", "Statut", [
        { value: null, label: "Tous" },
        { value: "in_process", label: "En cours", variant: "warning" },
        { value: "completed", label: "Complété", variant: "success" },
        { value: "failed", label: "Échoué", variant: "danger" },
        { value: "canceled", label: "Annulé", variant: "danger" },
      ]),
      Column.withRelation(
        "user.full_name",
        "Exporté par",
        {
          relation: "users",
          label: "full_name",
          field: "id",
          params: { for: "admin" },
        },
        "user_id",
        {
          urlFct: (item) => `/admin/users/${item.user.id}`,
        }
      ),
      new Column("file.original_filename", "Fichier", "text", { sortable: false }),
      new Column("started_at", "Date", "date"),
      new Column("duration_in_seconds", "Temps d'exécution (s)", "number"),
      new Column("progress", "Progrès", "number", { showByDefault: false }),
    ];

    const extraData = ["file.*"];

    return {
      allExportsSelected: false,
      selectedExports: [],
      columns,
      extraData,
    };
  },
  computed: {
    canDeleteExport() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    canBatchCancelExports() {
      // Global admins only for the moment.
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser);
    },
    selectedExportsCount() {
      return this.allExportsSelected ? this.$refs.table.total : this.selectedExports.length;
    },
    selectedCanceleable() {
      if (this.allExportsSelected) {
        return true;
      }

      for (const selectedExport of this.selectedExports) {
        if (selectedExport.status === "in_process") {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    canCancelExport(item) {
      const loggedInUser = this.$store.state.user;
      return isGlobalAdmin(loggedInUser) || item.user.id === loggedInUser.id;
    },
    selectAll() {
      this.$refs.table.selectAll();
      // Need to do this on the next tick, since the table.selectAll would undo it
      this.$nextTick(() => (this.allExportsSelected = true));
    },
    getMassActionParams() {
      if (this.allExportsSelected) {
        return {
          ...this.$refs.table.fetchParams,
          ...this.$refs.table.filters,
          per_page: "*",
        };
      }
      return {
        id: this.selectedExports.map((s) => s.id).join(","),
      };
    },
    async cancelExport(item) {
      await put(`exports/${item.id}/cancel`, null, {
        notifications: { action: "Annulation de l'export" },
      });
      this.$refs.table.refresh();
    },
    async deleteExport(item) {
      await del(`exports/${item.id}`, {
        notifications: { action: "Supression de l'export" },
      });
      this.$refs.table.refresh();
    },
    async cancelExports() {
      await put("exports/cancel", this.getMassActionParams(), {
        notifications: { action: "Annulation des exports" },
      });
      this.$refs.table.refresh();
    },
    async deleteExports() {
      await del("exports", {
        axiosRequestConfig: { params: this.getMassActionParams() },
        notifications: { action: "Annulation des exports" },
      });
      this.$refs.table.refresh();
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.exports,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.exports,
        ...locales.fr.forms,
      },
    },
  },
};
</script>
