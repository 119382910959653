import { get } from "@/requests/server";
import dayjs from "dayjs";

// Cache for 5 minutes
const loanablesCacheTTL = 5;

export function getInitialState() {
  return {
    center: null,
    selectedLoanableTypes: [],
    lastSearchDate: null,
    lastSearchDuration: null,
    loanablesByType: {},
    loading: false,
    lastTypeFetchDate: null,
    availableLoanableIds: [],
    availabilitiesLoading: false,
    loanablesLoading: false,
    testedLoanableIds: [],
    allTested: false,
  };
}

export function persistState(state) {
  // Do not persist loading state, availability and
  // invalidate the cache (lastTypeFetchDate not set).
  return {
    selectedLoanableTypes: state.selectedLoanableTypes,
    lastSearchDate: state.lastSearchDate,
    lastSearchDuration: state.lastSearchDuration,
    loanablesByType: state.loanablesByType,
  };
}

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    reset(state) {
      Object.assign(state, getInitialState());
    },
    center(state, center) {
      state.center = center;
    },
    availbilitiesLoading(state, value) {
      state.availabilitiesLoading = value;
    },
    loanablesLoading(state, value) {
      state.loanablesLoading = value;
    },
    selectedLoanableTypes(state, selectedLoanableTypes) {
      state.selectedLoanableTypes = selectedLoanableTypes;
    },
    lastSearchDate(state, date) {
      if (dayjs(date).isValid()) {
        state.lastSearchDate = date;
      } else {
        state.lastSearchDate = null;
      }
    },
    lastSearchDuration(state, duration) {
      if (duration && duration > 15) {
        state.lastSearchDuration = duration;
      } else {
        state.lastSearchDuration = null;
      }
    },
    resetAvailabilities(state) {
      state.availableLoanableIds = [];
      state.testedLoanableIds = [];
      state.allTested = false;
    },
    updateAvailabilities(state, availableLoanableIds) {
      state.availableLoanableIds = availableLoanableIds;
      state.allTested = true;
    },
    setLoanableTested(state, loanableId) {
      if (!state.testedLoanableIds.includes(loanableId)) {
        state.testedLoanableIds.push(loanableId);
      }
    },
    setLoanableAvailable(state, { id, available }) {
      const previouslyAvailable = state.availableLoanableIds.includes(id);
      if (available && !previouslyAvailable) {
        state.availableLoanableIds.push(id);
        return;
      }

      if (!available && previouslyAvailable) {
        state.availableLoanableIds = state.availableLoanableIds.filter((i) => i !== id);
      }
    },
    mergeLoanablesByType(state, loanableByTypes) {
      state.lastTypeFetchDate = new dayjs().toISOString();
      state.loanablesByType = { ...state.loanablesByType, ...loanableByTypes };
    },
    invalidateCache(state) {
      state.lastTypeFetchDate = null;
    },
  },
  actions: {
    async list({ commit, state }, { types }) {
      commit("selectedLoanableTypes", types);

      if (!types || types.length === 0) {
        return;
      }

      // Check if cached loanables are enough
      if (
        state.lastTypeFetchDate &&
        new dayjs().diff(state.lastTypeFetchDate, "minutes") < loanablesCacheTTL
      ) {
        return;
      }

      commit("loanablesLoading", true);
      const { data } = await get("/loanables/listByType", {
        notifications: { action: "mise à jour de la liste des véhicules" },
        cleanupCallback: () => commit("loanablesLoading", false),
      });
      commit("mergeLoanablesByType", data);
    },
    async testAll({ commit, state, dispatch }, { loan }) {
      // We ensure the shown loanables are at most loanablesCacheTTL minutes old
      // when searching for available loanables
      // This can be async since both available loanable ids and the loanable list are independent
      dispatch("list", { types: state.selectedLoanableTypes });

      const params = {
        departure_at: loan.departure_at,
        duration_in_minutes: loan.duration_in_minutes,
      };

      commit("availbilitiesLoading", true);
      const { data } = await get(`/loanables/search`, {
        axiosRequestConfig: { params },
        notifications: { action: "recherche des véhicule disponibles" },
        cleanupCallback: () => commit("availbilitiesLoading", false),
      });

      commit("updateAvailabilities", data);
    },
  },
  getters: {
    loanables(state) {
      const loanables = [];
      const availableLoanableIds = new Set(state.availableLoanableIds);
      const testedLoanableIds = new Set(state.testedLoanableIds);

      for (const loanableType of state.selectedLoanableTypes) {
        loanables.push(
          ...(state.loanablesByType[loanableType] || []).map((loanable) => ({
            ...loanable,
            available: availableLoanableIds.has(loanable.id),
            tested: state.allTested || testedLoanableIds.has(loanable.id),
          }))
        );
      }

      loanables.sort((a, b) => a.name.localeCompare(b.name));

      return loanables;
    },
    loading(state) {
      return state.loanablesLoading || state.availabilitiesLoading;
    },
  },
};
