<template>
  <b-container class="invoice-view" fluid>
    <b-row>
      <b-col sm="6">
        <p class="text-left">
          Réseau LocoMotion<br />
          6450, Ave Christophe-Colomb<br />
          Montréal, QC<br />
          H2S 2G7
        </p>
      </b-col>

      <b-col sm="6">
        <p class="text-right">
          {{ invoice.user.full_name }}<br />
          {{ invoice.user.address }}<br />
          {{ invoice.user.postal_code }}
        </p>
      </b-col>
    </b-row>

    <b-row class="mt-3 mb-3">
      <b-col>
        <p>
          Date&nbsp;: {{ invoice.created_at | date }}<br />
          <strong>{{ invoice.id }}</strong> &bull; <strong>{{ invoice.period }}</strong>
        </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table :items="invoice.bill_items" :fields="fields" no-local-sorting no-sort-reset>
          <template #cell(amount)="row">
            {{ row.item.amount | currency }}
          </template>

          <template #cell(item_type)="row">
            {{ $t(`bill_item_types["${row.item.item_type}"]`) }}
          </template>

          <template #custom-foot>
            <b-tr class="invoice-view__footer-row">
              <b-td colspan="2">
                Sous-total<br />
                TPS<br />
                TVQ<br />
                Total
              </b-td>
              <b-td class="text-right tabular-nums">
                {{ invoice.total | currency }}<br />
                {{ invoice.total_tps | currency }}<br />
                {{ invoice.total_tvq | currency }}<br />
                {{ invoice.total_with_taxes | currency }}
              </b-td>
            </b-tr>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import locales from "@/locales";

export default {
  name: "SingleInvoice",
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "label", label: "Description", sortable: false },
        { key: "item_type", label: "Type", sortable: false },
        { key: "amount", label: "Montant", sortable: false, class: "text-right tabular-nums" },
      ],
    };
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.invoices,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.invoices,
        ...locales.fr.forms,
        bill_item_types: locales.fr.bill_items.fields.item_types,
      },
    },
  },
};
</script>

<style lang="scss">
.invoice-view {
  margin-bottom: 20px;

  &__footer-row {
    td:nth-child(1) {
      text-align: right;
      font-weight: bold;
    }

    td:nth-child(2) {
      text-style: italic;
    }
  }
}
</style>
