import merge from "deepmerge";
import { del, get, options, post, put } from "@/requests/server";
import i18n from "@/i18n";

export default function RestModule(slug, initialState, actions = {}, mutations = {}) {
  return {
    namespaced: true,
    state: {
      cancelToken: null,
      data: [],
      deleted: null,
      empty: null,
      error: null,
      exportFields: ["id"],
      exportNotFields: [],
      form: null,
      // Used in forms to determine whether a field has changed or to reset it's content.
      initialItemJson: "",
      item: null,
      lastLoadedAt: null,
      lastPage: 1,
      loaded: false,
      loading: false,
      search: [],
      lastSearchQuery: "",
      slug,
      total: undefined,
      ...initialState,
    },
    getters: {
      initialItem(state) {
        return JSON.parse(state.initialItemJson);
      },
      initialItemJson(state) {
        return state.initialItemJson;
      },
    },
    mutations: {
      addData(state, data) {
        state.data.push(...data);
      },
      cancelRequests(state) {
        if (state.cancelToken) {
          state.cancelToken.cancel(`${state.slug} canceled`);
          state.cancelToken = null;
          state.loading = false;
        }
      },
      cancelToken(state, cancelToken) {
        if (cancelToken && state.cancelToken) {
          state.cancelToken.cancel(`${state.slug} canceled`);
        }
        state.cancelToken = cancelToken;
      },
      data(state, data) {
        state.data = data;
      },
      deleted(state, deleted) {
        state.deleted = deleted;
      },
      empty(state, empty) {
        state.empty = empty;
      },
      error(state, error) {
        state.error = error;
      },
      form(state, form) {
        state.form = form;
      },
      // SETTER : Sets the item as a whole
      item(state, item) {
        state.item = item;
      },
      initialItem(state, item) {
        state.initialItemJson = JSON.stringify(item);
      },
      lastLoadedAt(state, lastLoadedAt) {
        state.lastLoadedAt = lastLoadedAt;
      },
      lastPage(state, lastPage) {
        state.lastPage = lastPage;
      },
      loaded(state, loaded) {
        state.loaded = loaded;
      },
      loading(state, loading) {
        state.loading = loading;
      },
      // SETTER : Deep merges the partial in the item
      mergeItem(state, partial) {
        state.item = merge(state.item, partial);
      },
      // SETTER : Shallow merges the partial in the item
      patchItem(state, partial) {
        state.item = {
          ...state.item,
          ...partial,
        };
      },
      patchInitialItem(state, partial) {
        state.initialItemJson = JSON.stringify({
          ...JSON.parse(state.initialItemJson),
          ...partial,
        });
      },
      resetItem(state) {
        state.item = JSON.parse(state.initialItemJson);
      },
      search(state, search) {
        state.search = search;
      },
      total(state, total) {
        state.total = total;
      },
      ...mutations,
    },
    actions: {
      async loadEmpty({ commit, dispatch, state }) {
        commit("loaded", false);

        await dispatch("options");

        commit("item", JSON.parse(JSON.stringify(state.empty)));
        commit("initialItem", state.item);

        commit("loaded", true);
      },
      async search({ commit, state }, { q, params }) {
        const {
          data: { data },
        } = await get(`/${state.slug}`, {
          axiosRequestConfig: {
            params: {
              ...params,
              q,
            },
          },
          notifications: { action: "recherche" },
        });
        commit("search", data);
      },
      async createItem({ state, commit }, { params, fieldsAllowlist }) {
        let data = {};
        if (!fieldsAllowlist) {
          data = { ...state.item };
        } else {
          for (const field of fieldsAllowlist) {
            data[field] = state.item[field];
          }
        }
        commit("loaded", false);
        commit("loading", true);

        const { data: item } = await post(`/${state.slug}`, data, {
          axiosRequestConfig: { params },
          notifications: { action: "Création " + i18n.tc(`slugs.${state.slug}`, 0) },
          cleanupCallback: () => commit("loading", false),
        });

        commit("item", item);
        commit("initialItem", item);
      },
      async options({ state, commit }) {
        if (state.form === null || state.empty === null) {
          const {
            data: { item: empty, form },
          } = await options(`/${state.slug}`);

          commit("empty", empty);
          commit("form", form);
        }
      },
      async retrieveOne({ dispatch, commit, state }, { params, id }) {
        commit("loaded", false);
        commit("loading", true);

        await dispatch("options");

        const { data } = await get(`/${state.slug}/${id}`, {
          axiosRequestConfig: { params },
          notifications: { action: "chargement" },
          cleanupCallback: () => commit("loading", false),
        });

        commit("item", data);
        commit("initialItem", data);

        commit("loaded", true);
        commit("loading", false);
      },
      async retrieve({ dispatch, state, commit }, params) {
        commit("loaded", false);

        await dispatch("options");
        const {
          data: { data, total, last_page },
        } = await get(`/${state.slug}`, {
          axiosRequestConfig: { params },
          notifications: { action: "chargement" },
        });

        commit("data", data);
        commit("total", total);
        commit("lastPage", last_page);
        commit("lastLoadedAt", Date.now());

        commit("loaded", true);
      },
      async updateItem(
        { dispatch, state },
        { params, fieldsBlocklist, fieldsAllowlist, showToastOnSuccess = true }
      ) {
        let toUpdate = {};
        if (!fieldsAllowlist) {
          toUpdate = { ...state.item };
        } else {
          for (const field of fieldsAllowlist) {
            let currentToUpdate = toUpdate;
            let currentState = state.item;
            const fieldPath = field.split(".");
            for (let i = 0; i < fieldPath.length - 1; i++) {
              if (typeof currentState === "object" && currentState !== null) {
                currentToUpdate[fieldPath[i]] = {};
                currentToUpdate = currentToUpdate[fieldPath[i]];
              }
            }
            currentToUpdate[fieldPath[fieldPath.length - 1]] =
              currentState[fieldPath[fieldPath.length - 1]];
          }
        }

        if (fieldsBlocklist) {
          for (let field of fieldsBlocklist) {
            let object = toUpdate;
            const fieldPath = field.split(".");
            for (let i = 0; i < fieldPath.length - 1; i++) {
              if (typeof object === "object" && object !== null) {
                object = object[fieldPath[i]];
              }
            }
            if (object) {
              delete object[fieldPath[fieldPath.length - 1]];
            }
          }
        }

        await dispatch("update", { id: state.item.id, data: toUpdate, params, showToastOnSuccess });
      },
      async update({ commit, state }, { id, data, params, showToastOnSuccess = true }) {
        commit("loaded", false);
        commit("loading", true);

        const response = await put(`/${state.slug}/${id}`, data, {
          axiosRequestConfig: {
            params,
          },
          notifications: {
            action: "changement",
            onSuccess: showToastOnSuccess ? "changements sauvegardés" : false,
          },
          cleanupCallback: () => commit("loading", false),
        });

        commit("item", response.data);
        commit("initialItem", response.data);

        commit("loaded", true);
      },
      async destroy({ commit, state }, { id, data }) {
        const response = await del(`/${state.slug}/${id}`, {
          axiosRequestConfig: { data },
          notifications: { action: "archivage", onSuccess: "archivage réussi!" },
        });

        commit("deleted", response.data);
      },
      async restore({ state }, { id, params }) {
        await put(`/${state.slug}/${id}/restore`, params, {
          notifications: { action: "Réactivation", onSuccess: "réactivation réussie!" },
        });
      },
      ...actions,
    },
  };
}
