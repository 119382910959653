/*
  drillParams recursively maps object properties onto a new object of the same
  structures.

  Function properties:
    Functions will be called with params (user, route), and the return value
    will be stored in the new object.
*/
function drillParams(object, vm) {
  return Object.keys(object).reduce((p, k) => {
    const newAcc = { ...p };

    // Conditional and mapResults are called before and after action dispatch
    // and are not part of params.
    if (["conditional", "mapResults"].indexOf(k) > -1) {
      return newAcc;
    }

    if (typeof object[k] === "function") {
      // Call function and set result
      newAcc[k] = object[k]({
        user: vm.$store.state.user,
        route: vm.$route,
      });
    } else if (typeof object[k] === "object") {
      // Recursively drill down object properties.
      newAcc[k] = drillParams(object[k], vm);
    } else {
      // Keep arrays strings or singletons as they are.
      newAcc[k] = object[k];
    }

    return newAcc;
  }, {});
}

export default {
  beforeRouteEnter(to, from, next) {
    if (to.meta && to.meta.data) {
      next((vm) => {
        vm.loadDataRoutesData(vm, to);
      });
    } else {
      next((vm) => {
        // There's nothing to load
        vm.routeDataLoaded = true;
      });
    }
  },
  data() {
    return {
      routeDataLoaded: false,
      routeDataLoading: false,
    };
  },
  methods: {
    reloadDataRoutesData(hardReload = false) {
      if (hardReload) {
        this.routeDataLoaded = false;
      }
      return this.loadDataRoutesData(this, this.$route);
    },
    loadDataRoutesData(vm, to) {
      this.routeDataLoading = true;
      return Promise.all(
        Object.keys(to.meta.data).reduce((acc, collection) => {
          // Each element of the collection is an action.
          const actions = Object.keys(to.meta.data[collection]);

          acc.push(
            ...actions.map((action) => {
              const routeParams = to.meta.data[collection][action];

              const params = {
                ...drillParams(routeParams, vm),
                ...vm.contextParams,
                ...vm.$route.query,
              };

              // Don't fetch data for conditional routes with false condition.
              if (
                routeParams.conditional &&
                !routeParams.conditional({
                  user: vm.user,
                  route: vm.$route,
                })
              ) {
                return null;
              }

              return vm.$store
                .dispatch(
                  // Dispatching this action fetches the data. See RestModule.
                  `${collection}/${action}`,
                  params
                )
                .then(() => {
                  // Transform the item if necessary.
                  if (routeParams.mapResults) {
                    vm.$store.commit(
                      `${collection}/data`,
                      vm.$store.state[collection].data.map(
                        routeParams.mapResults.bind({
                          user: vm.user,
                          route: vm.$route,
                        })
                      )
                    );
                  }
                });
            })
          );

          return acc;
        }, [])
      )
        .then(() => {
          vm.routeDataLoaded = true;
          vm.routeDataLoading = false;
        })
        .then(vm.dataRouteGuardsCallback)
        .catch(() => {
          vm.routeDataLoading = false;
          vm.$router.push("/app");
        });
    },
  },
};
