import LoanPage from "@/views/Loan.vue";

export default [
  {
    path: "/loans/:id",
    component: LoanPage,
    props: true,
    meta: {
      auth: true,
      slug: "loans",
      title: "titles.loan",
    },
  },
];
