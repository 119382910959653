import { isGlobalAdmin } from "@/helpers/permissions/users";

export function isAdminOfCommunity(user, community) {
  if (!community) {
    return false;
  }

  const communityUser = (user?.communities || []).find((c) => c.id === community.id);
  return communityUser && communityUser.role === "admin";
}

export function isAdminOfUserCommunity(accessingUser, accessedUser) {
  const adminCommunityIds = getAdminCommunities(accessingUser);
  const userCommunityIds = (accessedUser?.communities || []).map((c) => c.id);

  return adminCommunityIds.filter((id) => userCommunityIds.includes(id)).length > 0;
}

export function getAdminCommunities(user) {
  return (user?.communities || []).filter((c) => c.role === "admin").map((c) => c.id);
}

export function isAdminOfSomeCommunity(user) {
  return getAdminCommunities(user).length > 0;
}

export function canSeeDeletedCommunity(user) {
  return isGlobalAdmin(user);
}
