export default {
  fr: {
    conditions_updated_title: "Mise à jour des conditions d'utilisation",
    conditions_updated_text:
      "Pour améliorer votre expérience, voici les modifications apportées aux conditions d'utilisation :",
    read_all_conditions: "Veuillez consulter les {0} et les réapprouver.",
    conditions: "conditions d'utilisation",
    accept_updates_button: "J'accepte!",
    unregister_link: "Je veux me désinscrire",
    unregister_instructions: "Envoyez un courriel à {0} pour vous désinscrire.",
    link: "(lien)",
    updates: {
      "2022-10-06": {
        text: "Changement du calcul de la franchise de l'assurance de Desjardins Assurances en cas de dommages à une auto.",
        link: "/conditions-utilisation-desjardins-assurances.html#conditions-item__1-3",
      },
      "2023-09-01": {
        text: "Changement de la tarification de l'assurance de Desjardins Assurances en cas de dommages à une auto.",
        link: "/conditions-utilisation-desjardins-assurances.html#conditions-item__1-5",
      },
    },
  },
};
