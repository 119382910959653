<template>
  <paginated-table
    id="admin-invitations-table"
    ref="table"
    endpoint="invitations"
    :columns="columns"
    label="invitations"
    :fetch-params="params"
    :initial-filters="{
      status: 'active',
    }"
    sync-filters-with-url
    :show-generate-csv="false"
  >
    <template #head-buttons>
      <icon-button role="add" :to="createInvitationLink"> Inviter des membres </icon-button>
    </template>
    <template #cell(actions)="{ item }">
      <b-dropdown
        v-if="item.status === 'active'"
        size="sm"
        variant="white-primary"
        no-caret
        toggle-class="mr-0"
      >
        <template #button-content>
          <b-icon icon="three-dots-vertical" /><span class="sr-only">actions</span>
        </template>
        <b-dropdown-item
          variant="danger"
          size="sm"
          :disabled="deletingIds.includes(item.id)"
          @click="() => cancelInvitation(item)"
        >
          <b-spinner v-if="deletingIds.includes(item.id)" small />
          <b-icon v-else icon="trash" />
          Annuler
        </b-dropdown-item>
      </b-dropdown>

      <div v-else>
        <!-- default to nothing -->
      </div>
    </template>
  </paginated-table>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { extractErrors } from "@/helpers";
import { communityLink } from "@/helpers/adminLinks";
import { date } from "@/helpers/filters";
import { getAdminCommunities } from "@/helpers/permissions/communities";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "AdminInvitations",
  components: {
    IconButton,
    PaginatedTable,
  },
  data() {
    const columns = [
      new Column("id", "ID", "id", { showByDefault: false }),
      new Column("email", "Email"),
      Column.withRelation(
        "inviter.full_name",
        "Invité par",
        {
          relation: "users",
          label: "full_name",
          field: "id",
          params: { for: "admin" },
        },
        "inviter_user_id",
        {
          urlFct: (item) => `/admin/users/${item.inviter.id}`,
        }
      ),
      new Column("reason", "Raison"),
      Column.withSelect("auto_approve", "Approbation automatique", [
        { value: null, label: "Tous" },
        { value: true, label: "Oui", variant: "success" },
        { value: false, label: "Non", variant: "primary" },
      ]),
      Column.withSelect(
        "status",
        "Statut",
        [
          { value: null, label: "Tous" },
          { value: "active", label: "actif", variant: "primary" },
          { value: "accepted", label: "accepté", variant: "success" },
          { value: "refused", label: "refusé", variant: "danger" },
          { value: "canceled", label: "annulé", variant: "danger" },
          { value: "expired", label: "expiré", variant: "warning" },
        ],
        { sortable: false }
      ),
      new Column("expires_at", "Date d'expiration", "date", {
        showByDefault: false,
        formatter: date,
      }),
      new Column("created_at", "Date d'invitation", "date", { showByDefault: false }),
      new Column("accepted_at", "Date de l'acceptation", "date", { showByDefault: false }),
      new Column("refused_at", "Date du refus", "date", { showByDefault: false }),
    ];

    if (isGlobalAdmin(this.$store.state.user)) {
      columns.splice(
        3,
        0,
        Column.withRelation(
          "community.name",
          this.$t("communities.fields.community.name"),
          {
            relation: "communities",
            label: "name",
            field: "id",
            params: { for: "admin" },
          },
          "community_id",
          {
            urlFct: (item) => communityLink(item.community),
          }
        )
      );
    }

    return {
      deletingIds: [],
      columns,
    };
  },
  computed: {
    communityId() {
      // Returns the community of the admin if they're admin of a single community
      if (isGlobalAdmin(this.$store.state.user)) {
        return null;
      }
      const adminCommunities = getAdminCommunities(this.$store.state.user);
      if (adminCommunities.length === 1) {
        return adminCommunities[0];
      }
      return null;
    },
    params() {
      if (this.communityId) {
        return { community_id: this.communityId };
      }
      return {};
    },
    createInvitationLink() {
      if (this.communityId) {
        return `/admin/invitations/new?community_id=${this.communityId}`;
      }
      return "/admin/invitations/new";
    },
  },
  methods: {
    dayjs,
    async cancelInvitation(item) {
      try {
        this.deletingIds.push(item.id);
        await axios.delete(`/invitations/${item.id}`);
        this.$refs.table.refresh();
      } catch (e) {
        const content = extractErrors(e.response?.data).join(", ") ?? "";

        this.$store.commit("addNotification", {
          content,
          title: "Erreur lors de l'annulation de l'invitation",
          variant: "danger",
        });
      } finally {
        this.deletingIds = this.deletingIds.filter((id) => id !== item.id);
      }
    },
  },
};
</script>

<style lang="scss"></style>
