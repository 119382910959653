<template>
  <div id="locomotion" class="overflow-clip" :class="{ fullscreen: fullScreen }">
    <div v-if="someUserMandated" class="banner impersonation-banner">
      <span
        ><strong>Impersonation&nbsp;:</strong> Vous êtes connectés en tant que
        {{ $store.state.user.email }}.</span
      >
      <icon-button
        size="sm"
        variant="ghost-secondary"
        icon="arrow-counterclockwise"
        @click="refreshAsIs"
      />
      <icon-button icon="person-x" variant="ghost-secondary" size="sm" @click="demandate" />
    </div>
    <b-link v-if="clientUpdateNeeded" class="banner update-banner" @click.prevent.stop="reload">
      Une mise à jour de locomotion.app est disponible! Cliquez ici pour rafraîchir la page.
    </b-link>

    <layout-header v-if="!fullScreen" class="page__header" :title="pageTitle" />
    <div class="page__content">
      <keep-alive v-if="showContent" :include="$store.state.user ? ['UserDashboard'] : []">
        <router-view />
      </keep-alive>
    </div>
    <layout-footer v-if="!fullScreen" class="page__footer" />
    <conditions-updated-toast />
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import { capitalize } from "@/helpers/filters";
import Notification from "@/mixins/Notification";
import { put } from "@/requests/server";
import ConditionsUpdatedToast from "@/views/ConditionsUpdatedToast.vue";

export default {
  name: "LocoMotion",
  components: { IconButton, ConditionsUpdatedToast },
  mixins: [Notification],
  data() {
    return {
      showContent: true,
    };
  },
  computed: {
    clientUpdateNeeded() {
      if (!this.$store.state.clientVersionFromBackend) {
        return false;
      }

      const latestVersion = this.$store.state.clientVersionFromBackend.split(".");
      const appVersion = process.env.VUE_APP_VERSION.split(".");

      if (latestVersion.length < 3 || appVersion.length < 3) {
        console.error(
          `Semver for app is not as expected: from backend ${latestVersion}, from frontend ${appVersion}`
        );
        return false;
      }

      const majorLatestVersion = latestVersion[0];
      const minorLatestVersion = latestVersion[1];

      const majorAppVersion = appVersion[0];
      const minorAppVersion = appVersion[1];

      return (
        majorLatestVersion > majorAppVersion ||
        (majorLatestVersion === majorAppVersion && minorLatestVersion > minorAppVersion)
      );
    },
    pageTitle() {
      if (this.$route.meta && this.$route.meta.title) {
        return capitalize(this.$i18n.t(this.$route.meta.title));
      }

      return "";
    },
    fullScreen() {
      // We user window.location.search rather than $route.query since $route
      // is not available immediately upon first rendering, which causes the header to flash
      // in full screen.
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get("full_screen");
    },
    someUserMandated() {
      return !!this.$store.state.mandated && !this.$store.state.loading;
    },
    userId() {
      return this.$store.state.user?.id;
    },
  },
  watch: {
    userId() {
      // Newly mandated user loaded
      if (this.someUserMandated) {
        this.forceNavigate("/app");
      }
    },
  },
  mounted() {
    if (this.$store.state.token && !this.$store.state.loading && !this.$store.state.loaded) {
      this.$store.dispatch("loadUser");
    }
  },
  methods: {
    reload() {
      location.reload();
    },
    refreshAsIs() {
      this.forceNavigate(this.$route);
    },
    async forceNavigate(route) {
      this.showContent = false;
      await this.$nextTick();
      try {
        await this.$router.replace("/404");
        await this.$router.replace(route);
      } finally {
        this.showContent = true;
      }
    },
    async demandate() {
      this.showContent = false;
      try {
        await put(
          "/auth/logout",
          {},
          {
            requestOptions: { expects: [401] },
          }
        );
      } finally {
        if (!localStorage?.locomotion) {
          await this.$router.push("/login");
        } else {
          const localStorageState = JSON.parse(localStorage.locomotion);
          await this.$store.commit("setTokens", {
            token: localStorageState.token,
            refreshToken: localStorageState.refreshToken,
            tokenExpiresOn: localStorageState.tokenExpiresOn,
          });

          await this.$store.dispatch("loadUser", {});
          this.forceNavigate("/admin/users");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  display: block;
  position: sticky;
  padding: 0.5rem;
  text-align: center;
  top: 0;
  z-index: 10;
  box-shadow: $small-shadow;
  &.update-banner {
    background: $background-alert-positive;
    color: $content-alert-positive;
  }
  &.impersonation-banner {
    background: $background-alert-warning;
    color: $content-alert-warning;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.overflow-clip {
  overflow: clip;
}
</style>
