<script>
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "UserCommentInput",
  components: { IconButton },
  props: {
    save: {
      type: Function,
      required: true,
    },
    initialText: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      saving: false,
      text: this.initialText,
    };
  },
  mounted() {
    if (this.initialText) {
      this.updateInputHeight();
    }
  },
  methods: {
    async onSave() {
      this.saving = true;
      try {
        await this.save(this.text);
        this.text = null;
        if (this.$refs.textarea) {
          this.$refs.textarea.style.height = "";
        }
      } finally {
        this.saving = false;
      }
    },
    updateInputHeight() {
      if (this.$refs.textarea) {
        this.$refs.textarea.style.height = "";
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
      }
    },
  },
};
</script>

<template>
  <div class="send-comment-input form-control">
    <textarea
      ref="textarea"
      v-model="text"
      :disabled="saving"
      rows="1"
      :placeholder="placeholder"
      @input="updateInputHeight"
    />
    <icon-button
      variant="ghost-primary"
      :disabled="!text"
      role="send"
      :onclick="onSave"
    ></icon-button>
  </div>
</template>

<style scoped lang="scss">
.send-comment-input {
  height: auto;
  display: flex;
  align-items: flex-end;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(22, 165, 158, 0.25);
  }
  textarea {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    box-shadow: none;
    background: transparent;
    resize: none;
    min-height: 34px;
    &:focus,
    &.focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}
</style>
