// Converts a GeoJson MultiPolygon to a list of paths consumable by google.maps
function extractMultipolygonPaths(multipolygon) {
  return multipolygon.coordinates.map((polygon) =>
    polygon.flatMap((linearRing) =>
      // linearRing has the same first and last point
      linearRing.slice(0, -1).map(([lng, lat]) => ({ lat, lng }))
    )
  );
}

function extractMultipolygonPoints(multipolygon) {
  return extractMultipolygonPaths(multipolygon).flatMap((path) => path);
}

export { extractMultipolygonPaths, extractMultipolygonPoints };
