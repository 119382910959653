<template>
  <div class="user-add-credit-box">
    <b-form-group
      v-if="amounts && amounts.length > 1"
      label-cols="12"
      label="Montant à ajouter à votre solde"
      description="* Approvisionnez davantage votre compte pour économiser sur les frais de transaction."
    >
      <b-form-radio-group
        v-model="selectedValue"
        class="d-none d-md-block"
        button-variant="outline-primary"
        :options="amounts"
        :disabled="loading || purchasing || disabled"
        buttons
      />
      <b-form-select
        v-model="selectedValue"
        class="d-md-none"
        :options="amounts"
        :disabled="loading || purchasing || disabled"
      />
    </b-form-group>
    <b-row v-if="!noCustomAmount">
      <b-col md="8" xl="6">
        <b-form-group v-if="selectedValue == 'other'" label="Montant personnalisé">
          <currency-input
            v-model="customAmount"
            locale="fr"
            :disabled="loading || purchasing || disabled"
            class="form-control"
            :value-range="{ min: normalizedMinimumRequired }"
            :currency="{ suffix: ' $' }"
            :allow-negative="false"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="amount > 0">
      <b-col sm="6">
        <strong>Montant total prélevé</strong>
        <p class="total">{{ amountWithFee | currency }}</p>
        <b-form-text
          >Incluant les frais de transaction&nbsp;:
          <pre>{{ feeRatio | percent }} + {{ feeConstant | currency }}</pre>
        </b-form-text>
      </b-col>
      <b-col sm="6">
        <strong>Choisir votre mode de paiement</strong>
        <b-form-select
          v-if="hasPaymentMethod"
          id="payment_method_id"
          v-model="paymentMethodId"
          name="payment_method_id"
          :disabled="loading || purchasing || disabled"
          :options="paymentOptions"
        >
        </b-form-select>
        <div class="mt-1">
          <b-link
            v-b-modal:payment-method-form
            @click="() => $store.dispatch('paymentMethods/loadEmpty')"
          >
            {{ "ajouter un mode de paiement" | capitalize }}
          </b-link>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="payment-method-form"
      ref="modal"
      hide-header
      hide-footer
      @shown="paymentFormShown = true"
      @hidden="paymentFormShown = false"
    >
      <payment-method-form
        :shown="paymentFormShown"
        show-cancel
        @created="() => $bvModal.hide('payment-method-form')"
        @cancel="() => $bvModal.hide('payment-method-form')"
      />
    </b-modal>

    <b-row class="mt-3">
      <b-col class="text-center">
        <icon-button
          type="submit"
          variant="success"
          :disabled="
            !hasPaymentMethod || amount <= 0 || amount < normalizedMinimumRequired || disabled
          "
          :loading="loading || purchasing"
          @click="buyCredit"
        >
          {{ label }}
        </icon-button>
        <icon-button v-if="!noCancel" class="ml-2" role="cancel" @click="emitCancel">
          Annuler
        </icon-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import { currency, normalizeCurrency } from "@/helpers/filters";
import { addFeeToAmount, feeSpec } from "@/helpers/transactionFees";
import { CurrencyInput } from "vue-currency-input";

export default {
  name: "UserAddCreditBox",
  components: {
    IconButton,
    PaymentMethodForm: () => import("@/components/PaymentMethod/PaymentMethodForm.vue"),
    CurrencyInput,
  },
  props: {
    // Minimum that needs to be paid.
    minimumRequired: {
      type: Number,
      required: false,
      default: 0,
    },
    actualCost: {
      type: Number,
      required: false,
      default: 0,
    },
    actualCostLabel: {
      type: String,
      default: "Ce trajet",
    },
    paymentMethods: {
      type: Array,
      required: false,
      default: undefined,
    },
    // This prop is a patch to remove cancel button from the pre-payment step.
    noCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    addStandardOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      default: "Ajouter les fonds",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noCustomAmount: {
      type: Boolean,
      default: false,
    },
    minimumDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customAmount:
        this.minimumRequired && this.minimumRequired > 0
          ? normalizeCurrency(this.minimumRequired)
          : 20,
      purchasing: false,
      paymentMethodId: this.paymentMethods
        ? this.paymentMethods.find((p) => p.is_default)?.id
        : null,
      selectedValue: this.initialSelectedValue(),
      paymentFormShown: false,
    };
  },
  computed: {
    paymentMethod() {
      return this.paymentMethods?.find((p) => p.id === this.paymentMethodId);
    },
    fee() {
      return feeSpec(this.paymentMethod);
    },
    feeRatio() {
      return this.fee.ratio;
    },
    feeConstant() {
      return this.fee.constant;
    },
    amount() {
      if (this.selectedValue === "other") {
        return normalizeCurrency(this.customAmount);
      }

      return this.selectedValue;
    },
    amountWithFee() {
      return addFeeToAmount(this.amount, this.fee);
    },
    amounts() {
      const options = [];

      if (
        this.normalizedMinimumRequired > 0 &&
        this.normalizedMinimumRequired != this.normalizedActualCost
      ) {
        options.push({
          text: `Minimum (${currency(this.normalizedMinimumRequired)})`,
          value: this.normalizedMinimumRequired,
        });
      }

      if (this.normalizedActualCost > 0) {
        options.push({
          text: `${this.actualCostLabel} (${currency(this.normalizedActualCost)})`,
          value: this.normalizedActualCost,
        });
      }

      if (this.addStandardOptions) {
        const standardOptions = [
          {
            text: this.$i18n.n(10, "dollars_cad"),
            value: 10,
          },
          {
            text: this.$i18n.n(20, "dollars_cad"),
            value: 20,
          },
          {
            text: this.$i18n.n(50, "dollars_cad"),
            value: 50,
          },
          {
            text: this.$i18n.n(100, "dollars_cad"),
            value: 100,
          },
        ];

        for (let i = 0, len = standardOptions.length; i < len; i += 1) {
          if (
            !this.normalizedMinimumRequired ||
            standardOptions[i].value > this.normalizedMinimumRequired
          ) {
            options.push(standardOptions[i]);
          }
        }
      }
      if (!this.noCustomAmount) {
        options.push({
          text: "Autre *",
          value: "other",
        });
      }

      return options;
    },
    normalizedMinimumRequired() {
      return normalizeCurrency(this.minimumRequired);
    },
    normalizedActualCost() {
      return normalizeCurrency(this.actualCost);
    },
    paymentOptions() {
      if (!this.hasPaymentMethod) {
        return [];
      }
      return this.paymentMethods.map((pm) => {
        const { name: text, id: value, is_default: selected } = pm;
        return {
          text,
          value,
          selected,
        };
      });
    },
    hasPaymentMethod() {
      return this.paymentMethods && this.paymentMethods.length > 0;
    },
  },
  watch: {
    paymentMethods(newPaymentMethods) {
      if (!this.paymentMethodId) {
        this.paymentMethodId = newPaymentMethods
          ? newPaymentMethods.find((p) => p.is_default)?.id
          : null;
      }
    },
    minimumRequired(newValue, oldValue) {
      if (this.selectedValue === normalizeCurrency(oldValue)) {
        this.selectedValue = normalizeCurrency(newValue);
      }
      if (this.customAmount <= normalizeCurrency(oldValue)) {
        this.customAmount = normalizeCurrency(newValue);
      }
    },
    actualCost(newValue, oldValue) {
      if (this.selectedValue === normalizeCurrency(oldValue)) {
        this.selectedValue = normalizeCurrency(newValue);
      }
    },
  },
  methods: {
    initialSelectedValue() {
      if (this.actualCost && !this.minimumDefault) {
        return normalizeCurrency(this.actualCost);
      }
      if (this.minimumRequired) {
        return normalizeCurrency(this.minimumRequired);
      }
      if (this.addStandardOptions) {
        return 10;
      }

      return "other";
    },
    emitCancel() {
      this.$emit("cancel");
    },
    async buyCredit() {
      this.purchasing = true;

      try {
        const { amount, paymentMethodId } = this;
        await this.$store.dispatch("account/buyCredit", {
          amount,
          paymentMethodId,
        });
        this.$emit("bought");
      } finally {
        this.purchasing = false;
      }
    },
  },
};
</script>

<style lang="scss">
.user-add-credit-box {
  .total {
    font-size: 3rem;
    color: $primary;
    font-weight: bold;
  }
}
</style>
