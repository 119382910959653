<script>
import FormsDateTimePicker from "@/components/Forms/DateTimePicker.vue";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { get } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "AdminReports",
  components: { FormsValidatedInput, IconButton, FormsDateTimePicker },
  data() {
    return {
      balanceUpToDate: null,
      trimester: null,
      balanceUrl: null,
      insuranceUrl: null,
      generatedInsuranceReportName: null,
      generatedBalanceReportName: null,
      balanceNonZero: false,
    };
  },
  computed: {
    trimesterOptions() {
      let options = [];
      let currentYear = dayjs().year();
      let currentQuarter = Math.ceil((dayjs().month() + 1) / 3);
      let monthsSince2020Q1 = 0;
      for (let year = 2020; year <= currentYear; year++) {
        for (let quarter = 1; quarter <= 4; quarter++) {
          if (year === currentYear && quarter > currentQuarter) {
            break;
          }
          let text = `${year}Q${quarter}`;
          if (year === currentYear && quarter === currentQuarter) {
            text += " (en cours)";
          }
          options.push({
            value: monthsSince2020Q1++,
            text,
          });
        }
      }
      return options.reverse();
    },
    trimesterStart() {
      if (this.trimester === null) {
        return null;
      }
      return dayjs("2020-01-01T00:00:00")
        .add(this.trimester * 3, "months")
        .toISOString();
    },
    trimesterEnd() {
      if (this.trimester === null) {
        return null;
      }
      return dayjs("2020-04-01T00:00:00")
        .add(this.trimester * 3, "months")
        .toISOString();
    },
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("D MMMM YYYY HH:mm z (Z)");
    },
    async generateInsuranceReport() {
      const response = await get(`/reports/insurance`, {
        axiosRequestConfig: {
          responseType: "blob",
          params: {
            from: this.trimesterStart,
            to: this.trimesterEnd,
          },
        },
        notifications: {
          action: "génération du rapport",
        },
      });
      this.insuranceUrl = URL.createObjectURL(response.data);
      this.generatedInsuranceReportName = `rapport_assurances_${
        this.trimesterOptions[this.trimesterOptions.length - this.trimester - 1].text
      }.csv`;

      if (this.insuranceUrl) {
        // Once we render the link, we click it rather than just opening the url in a new window
        // so we can use the <a>'s "download" attribute which sets the file name.
        this.$nextTick(() => {
          if (this.$refs.insuranceLink) {
            this.$refs.insuranceLink.click();
          }
        });
      }
    },
    async generateBalanceReport() {
      const response = await get(`/reports/balance`, {
        axiosRequestConfig: {
          responseType: "blob",
          params: {
            at: this.balanceUpToDate,
            non_zero_only: this.balanceNonZero,
          },
        },
        notifications: {
          action: "génération du rapport",
        },
      });
      this.balanceUrl = URL.createObjectURL(response.data);
      this.generatedBalanceReportName = this.balanceUpToDate
        ? `rapport_soldes_${this.balanceUpToDate}.csv`
        : `rapport_soldes_${dayjs().startOf("minute").toISOString()}.csv`;

      if (this.balanceUrl) {
        // Once we render the link, we click it rather than just opening the url in a new window
        // so we can use the <a>'s "download" attribute which sets the file name.
        this.$nextTick(() => {
          if (this.$refs.balanceLink) {
            this.$refs.balanceLink.click();
          }
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <h2>Rapports</h2>
    <b-card>
      <h3>Déclaration trimestrielle pour les assurances</h3>
      <b-form>
        <b-form-group
          label="Trimestre"
          :description="
            trimester ? `${formatDate(trimesterStart)} au ${formatDate(trimesterEnd)}` : null
          "
        >
          <b-select v-model="trimester" :options="trimesterOptions" />
        </b-form-group>

        <div class="mt-3 button-list align-items-center">
          <icon-button
            :onclick="generateInsuranceReport"
            variant="success"
            icon="file-earmark-ruled"
            :disabled="trimester === null"
            >Générer le rapport</icon-button
          >
          <a
            v-if="insuranceUrl"
            ref="insuranceLink"
            :download="generatedInsuranceReportName"
            :href="insuranceUrl"
            target="_blank"
          >
            <b-icon icon="download"></b-icon> {{ generatedInsuranceReportName }}
          </a>
        </div>
      </b-form>
    </b-card>

    <b-card>
      <h3>Rapport des soldes des utilisateurs</h3>
      <b-form>
        <b-form-group
          label="Soldes historiques en date du"
          description="Laisser vide pour soldes actuels"
        >
          <forms-date-time-picker v-model="balanceUpToDate" clearable show-timezone />
        </b-form-group>
        <forms-validated-input
          v-model="balanceNonZero"
          type="checkbox"
          name="non-zero"
          label="Soldes non nuls uniquement"
        />
        <div class="mt-3 button-list align-items-center">
          <icon-button variant="success" icon="file-earmark-ruled" :onclick="generateBalanceReport"
            >Générer le rapport</icon-button
          >
          <a
            v-if="balanceUrl"
            ref="balanceLink"
            :download="generatedBalanceReportName"
            :href="balanceUrl"
            target="_blank"
          >
            <b-icon icon="download"></b-icon> {{ generatedBalanceReportName }}
          </a>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.card {
  box-shadow: $small-shadow;
  + .card {
    margin-top: 1rem;
  }
}
</style>
