<script>
import IconButton from "@/components/shared/IconButton.vue";
import MiddleClassText from "@/components/shared/MiddleClassText.vue";
import UserCommentInput from "@/components/shared/UserCommentInput.vue";
import UserAvatar from "@/components/User/Avatar.vue";
import { datetime } from "@/helpers/filters";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import Vue from "vue";

let uniqueId = 0;

export default Vue.extend({
  name: "UserComment",
  components: { UserCommentInput, MiddleClassText, IconButton, UserAvatar },
  props: {
    text: {
      type: String,
      default: null,
      required: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    updatedDate: {
      type: String,
      default: null,
    },
    destroy: {
      type: Function,
      required: false,
      default: null,
    },
    update: {
      type: Function,
      required: false,
      default: null,
    },
    canDestroy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isGlobalAdmin: isGlobalAdmin(this.$store.state.user),
      updatingMessage: false,
      id: uniqueId++,
    };
  },
  computed: {
    mine() {
      return this.user && this.user.id === this.$store.state.user.id;
    },
    showText() {
      return !this.isEditing && (!this.deleted || this.isGlobalAdmin);
    },
  },
  methods: {
    datetime,
    async updateText(text) {
      this.updatingMessage = true;
      try {
        await this.update(text);
      } finally {
        this.updatingMessage = false;
      }
      this.isEditing = false;
    },
    async deleteMessage() {
      this.updatingMessage = true;
      try {
        await this.destroy();
      } finally {
        this.updatingMessage = false;
      }
    },
  },
});
</script>

<template>
  <div>
    <div
      v-b-toggle="`comment-${id}`"
      class="comment"
      :class="{ 'with-user': !!user, mine: mine, updating: updatingMessage, editing: isEditing }"
    >
      <user-avatar v-if="user" class="avatar" :user="user" />
      <div class="content">
        <user-comment-input
          v-if="isEditing"
          :save="updateText"
          :initial-text="text"
          @click.stop.prevent
        />
        <div v-if="deleted && !isEditing" class="font-italic text-muted">message supprimé</div>
        <middle-class-text v-if="showText" :text="text" />
      </div>
    </div>
    <b-collapse v-if="user || !!update || (!!destroy && canDestroy)" :id="`comment-${id}`">
      <div class="signature" :class="{ mine: mine }">
        <div class="signature-content">
          <span v-if="user" class="text-nowrap"> {{ user.full_name }}</span>
          <span v-if="date && user" class=""> &bull; </span>
          <span v-if="date" class="text-nowrap">{{ date | datetime }}</span>
          <span
            v-if="updatedDate && date !== updatedDate"
            v-b-tooltip="`Texte modifié le ${datetime(updatedDate)}`"
            >*</span
          >

          <div v-if="!deleted && (!!update || (!!destroy && canDestroy))" class="text-nowrap">
            <icon-button
              v-if="isEditing"
              role="reset"
              variant="link"
              size="inline"
              @click="
                isEditing = false;
                editingText = text;
              "
            >
              Annuler</icon-button
            >
            <icon-button
              v-else-if="!!update"
              role="edit"
              variant="link"
              size="inline"
              @click="isEditing = true"
              >Modifier</icon-button
            >
            <icon-button
              v-if="!!destroy && canDestroy"
              role="delete"
              variant="link"
              size="inline"
              :onclick="deleteMessage"
              >Supprimer</icon-button
            >
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.comment {
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 1rem;
  --comment-color: rgba(87, 97, 148, 0.1);

  &.updating {
    opacity: 0.6;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: var(--comment-color);
    border-radius: 1rem;
    padding: 0.75rem;
    text-align: start;
    max-width: 70%;
  }

  // Full width when editing
  &.editing .content {
    flex: 1;
    max-width: 100%;
  }

  &.with-user {
    .content {
      border-top-left-radius: 0;
    }
    gap: 0.5rem;
  }

  &.mine {
    --comment-color: #e5f8f6;

    &.with-user {
      flex-direction: row-reverse;

      .content {
        border-top-left-radius: 1rem;
        border-top-right-radius: 0;
      }
    }
  }

  > .user-avatar {
    flex-shrink: 0;
  }
}

.signature {
  font-size: 0.85rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  color: $locomotion-grey;
  &.mine {
    justify-content: flex-end;
    text-align: end;
  }
}
.comment .user-avatar.user-avatar-md {
  width: 2.5rem;
  height: 2.5rem;
}

@include media-breakpoint-down(xs) {
  .comment .user-avatar.user-avatar-md {
    width: 2rem;
    height: 2rem;
  }
}
</style>
