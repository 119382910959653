const DEV_PARAM = "dev";

export function isDevMode() {
  return (
    process.env.NODE_ENV === "development" &&
    new URLSearchParams(window.location.search).get(DEV_PARAM) > 0
  );
}

export function hasDevParam(route) {
  return route.query[DEV_PARAM] > 0;
}

export function persistDevParam(to, from, next) {
  if (!hasDevParam(to) && hasDevParam(from)) {
    next({ path: to.path, query: { ...to.query, [DEV_PARAM]: from.query[DEV_PARAM] } });
  } else {
    next();
  }
}
