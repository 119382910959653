import { get, put } from "@/requests/server";

export async function acceptLoan(loan, data = null) {
  try {
    return await put(`/loans/${loan.id}/accept`, data, {
      notifications: { action: "approbation de l'emprunt" },
    });
  } catch (e) {
    return false;
  }
}

export async function cancelLoan(loanId, data = null) {
  return await put(`/loans/${loanId}/cancel`, data, {
    notifications: { action: "annulation de l'emprunt", onSuccess: "emprunt annulé!" },
  });
}

export async function rejectLoan(loanId, data = null) {
  return await put(`/loans/${loanId}/reject`, data, {
    notifications: { action: "refus de l'emprunt", onSuccess: "emprunt refusé!" },
  });
}

export async function estimate(loanData) {
  const params = {
    departure_at: loanData.departure_at,
    duration_in_minutes: loanData.duration_in_minutes,
    estimated_distance: loanData.estimated_distance,
    loanable_id: loanData.loanable?.id,
    platform_tip: loanData.platform_tip,
    mileage_start: loanData.mileage_start,
    mileage_end: loanData.mileage_end,
    expenses_amount: loanData.expenses_amount,
  };

  const { data } = await get(`/loans/${loanData.id}/estimate`, {
    axiosRequestConfig: { params },
    requestOptions: {
      cancelId: `estimating-loan-${loanData.id}`,
      expects: [403],
    },
    notifications: { action: "estimation du coût de l'emprunt" },
  });

  return data;
}

export async function test(loanData) {
  const params = {
    departure_at: loanData.departure_at,
    duration_in_minutes: loanData.duration_in_minutes,
    estimated_distance: loanData.estimated_distance,
    loanable_id: loanData.loanable_id,
    borrower_user_id: loanData.borrower_user_id,
  };

  // Avoid sending invalid requests.
  if (params.estimated_distance < 0 || params.duration_in_minutes < 15) {
    return;
  }

  const { data } = await get(`/loanables/${params.loanable_id}/test`, {
    axiosRequestConfig: { params: params },
    requestOptions: {
      cancelId: `evaluating-price-${params.loanable_id}`,
      expects: [403],
    },
    notifications: { action: "évaluation du coût de l'emprunt" },
  });

  return data;
}
