<template>
  <div class="tiny-invoice" :class="{ 'styled-invoice': styled, loading: loading }">
    <slot name="title">
      <h3 v-if="invoice && invoice.title">{{ invoice.title }}</h3>
    </slot>
    <table v-if="invoice && invoice.items">
      <template v-for="(item, key) of invoice.items">
        <tiny-invoice-group v-if="item.items" :key="key" :item="item" />
        <template v-else>
          <tiny-invoice-line
            :key="`${key}-line`"
            :class="item.class"
            :label="item.label"
            :total="item.total"
            :loading="item.loading"
          />
          <tr v-if="item.subtext" :key="`${key}-subtext`">
            <td colspan="2">
              <div class="invoice-item-subtext">{{ item.subtext }}</div>
            </td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import TinyInvoiceGroup from "@/components/Invoice/TinyInvoiceGroup.vue";
import TinyInvoiceLine from "@/components/Invoice/TinyInvoiceLine.vue";

export default {
  name: "TinyInvoice",
  components: { TinyInvoiceGroup, TinyInvoiceLine },
  props: {
    invoice: {
      type: Object,
      required: false,
      default: () => null,
    },
    styled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.tiny-invoice {
  margin-bottom: 0.5rem;
  transition: opacity 0.3s;
  &.loading {
    opacity: 0.7;
  }

  &.styled-invoice {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: $main-bg;
    border: 1px dashed $shadow-color;
  }

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1;
  }

  table {
    width: 100%;
    line-height: 1.5;
  }

  th {
    font-weight: normal;
  }

  th,
  td {
    padding-bottom: 0.25rem;
    vertical-align: top;
  }

  tr:has(+ .invoice-total) {
    > td,
    > th {
      padding-bottom: 1rem;
    }
  }

  .invoice-total {
    font-weight: bold;

    th,
    td {
      padding-top: 0.75rem;
    }

    th {
      font-weight: bold;
      border-top: 1px dotted lightgray;
    }

    td {
      width: 5rem;
      border-top: 1px solid #212529;
    }
  }

  .invoice-item-subtext {
    display: block;
    font-size: 0.8rem;
    color: $content-neutral-secondary;
    padding-bottom: 0.5rem;
  }
}
</style>
