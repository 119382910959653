<template>
  <div class="admin-pagination">
    <b-pagination
      align="right"
      :value="page"
      :total-rows="total"
      :per-page="perPage"
      @input="$emit('update:page', $event)"
    />

    <b-form inline class="admin-pagination__per_page">
      <b-form-select
        id="per_page"
        name="per_page"
        :options="[10, 20, 50, 100]"
        :value="perPage"
        @input="$emit('update:perPage', $event)"
      />
      <label for="per_page" class="ml-2">par page</label>
    </b-form>
    <p v-if="total">sur un total de {{ total }}</p>
  </div>
</template>

<script>
export default {
  name: "AdminPagination",
  props: {
    page: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
};
</script>

<style lang="scss">
.admin-pagination {
  text-align: right;

  &__per_page {
    justify-content: flex-end;
  }
}
</style>
