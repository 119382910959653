import { get } from "@/requests/server";
import Vue from "vue";

/*
 * This image cache works by loading images into the browser using the `URL.createObjectUrl` method.
 * This creates a temporary URL for the loaded asset which will stop working as soon as the page
 * is closed or we manually revoke the URLs. Since some of the images we load are sensitive,
 * we revoke the URLs and remove the images from memory as soon as possible. This is implemented
 * with a 5 minutes timeout checking whether the image is still in use and unloading it if not.
 * See `cleanCache` below.
 */

async function getImageBlobUrl(id, size, onDownloadProgress) {
  try {
    const response = await get("/images/" + id, {
      axiosRequestConfig: {
        params: {
          size,
        },
        responseType: "blob",
        onDownloadProgress,
      },
      // No notifications if we fail to load an image.
    });

    return URL.createObjectURL(response.data);
  } catch (e) {
    if (e.response?.status === 404) {
      return false;
    }
    throw e;
  }
}

// 5 Minutes
const cacheTTL = 1000 * 60 * 5;

function cleanTimeout(dispatch, id, size) {
  return setTimeout(() => dispatch("cleanCache", { id, size }), cacheTTL);
}

export default {
  namespaced: true,
  state: {
    /**
     * @type {{[id in Number]: {[size in String]: {url: String|false, timeout: int, percentCompleted: int} }}}
     */
    images: {},
  },
  mutations: {
    imageLoaded(state, { id, size, url, timeout }) {
      Vue.set(state.images[id], size, { ...state.images[id][size], url, timeout });
    },
    imageLoadStart(state, { id, size }) {
      if (!state.images[id]) {
        Vue.set(state.images, id, { [size]: { percentCompleted: 0 } });
        return;
      }

      Vue.set(state.images[id], size, { percentCompleted: 0 });
    },
    imageProgress(state, { id, size, percentCompleted }) {
      Vue.set(state.images[id], size, { ...state.images[id][size], percentCompleted });
    },
    clearImage(state, { id, size }) {
      Vue.set(state.images[id], size, undefined);
    },
  },
  actions: {
    async loadImage({ dispatch, commit, state }, { id, requestedSizes }) {
      if (state.images[id]) {
        let needFetch = false;
        let hasCached = false;
        // In order of preferred size,
        for (const size of requestedSizes) {
          // if no cached value, we need to fetch
          if (state.images[id][size] === undefined) {
            needFetch = true;
            break;
          }
          // if cached value is present or loading, no need to fetch
          if (state.images[id][size].url || state.images[id][size].percentCompleted !== undefined) {
            hasCached = true;
            break;
          }
          // if cached value is false (no data), we check the next sizes.
        }

        if (!needFetch) {
          // Successfully retrieved cached image
          return hasCached;
        }
      }

      for (const size of requestedSizes) {
        commit("imageLoadStart", { id, size });
        try {
          const url = await getImageBlobUrl(id, size, (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            commit("imageProgress", { id, size, percentCompleted });
          });
          // If the fetch is successful, url is valid, otherwise it is false
          commit("imageLoaded", {
            id,
            size,
            url,
            timeout: cleanTimeout(dispatch, id, size),
          });
          if (url) {
            // Successfully fetched an image
            return true;
          }
        } catch (e) {
          // if we fail, cleanup
          commit("clearImage", { id, size });
        }
      }
      // Failed fetching any image
      return false;
    },
    cleanCache({ state, dispatch, commit }, { id, size }) {
      if (!state.images[id]) {
        return;
      }

      // Is image still in use? We use vue internal ref counting, so we don't need
      // to come up with our own.
      if (state.images[id][size]?.__ob__?.dep?.subs?.length > 0) {
        // Extend timeout
        commit("imageLoaded", {
          id,
          size,
          url: state.images[id][size].url,
          timeout: cleanTimeout(dispatch, id, size),
        });
        return;
      }

      if (state.images[id][size]?.url) {
        URL.revokeObjectURL(state.images[id][size].url);
      }

      commit("clearImage", { id, size });
    },
  },
};
