var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('paginated-table',{attrs:{"id":"admin-loans-table","columns":_vm.columns,"extra-filters":_vm.extraFilters,"extra-data":[
      'loanable.active_incidents.status',
      'loanable.active_incidents.is_blocking',
      'community.deleted_at',
      'loanable.timezone',
      'extension_duration_in_minutes',
      'borrower_must_pay_compensation',
      'borrower_must_pay_insurance',
      'borrower_may_contribute',
      'borrower_validated_at',
      'owner_validated_at',
    ],"endpoint":"loans","fetch-params":{ for: 'admin' },"label":_vm.$tc('model_name', 2),"sync-filters-with-url":"","show-action-column":false,"row-link":""},scopedSlots:_vm._u([{key:"cell(status)",fn:function({ item }){return [_c('loan-status',{attrs:{"item":item,"small":""}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }