var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'layout-loading': true,
    'inline-with-buttons': _vm.withButton,
    'inline-with-text': _vm.withText,
    'full-height': _vm.fullHeight,
    overlay: _vm.overlay,
  }},[_c('img',{attrs:{"src":"/loading.svg"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }