import users from "./users";

export default {
  fr: {
    create_invoice: "créer une facture",
    selected_invoices:
      "aucune facture sélectionnée | {count} facture sélectionnée |{count} factures sélectionnées",
    invoice: "facture | factures",
    fields: {
      created_at: "créée",
      paid_at: "payée",
      user: users.fr.fields,
    },
  },
};
