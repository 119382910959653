<template>
  <b-icon v-if="actionRequired" icon="exclamation-circle-fill" class="loan-status-icon" />
  <svg
    v-else-if="stepIndex"
    class="circular-progress loan-status-icon"
    :class="`loan-step-${stepIndex}`"
  >
    <circle class="bg"></circle>
    <circle class="fg"></circle>
  </svg>
  <b-icon v-else-if="icon" :icon="icon" class="loan-status-icon" />
</template>

<script>
export default {
  name: "LoanStatusIcon",
  props: {
    status: {
      type: String,
      required: true,
    },
    actionRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stepIndex() {
      switch (this.status) {
        case "requested":
          return 1;
        case "accepted":
          return 2;
        case "confirmed":
          return 3;
        case "ongoing":
          return 4;
        case "ended":
          return 5;
        case "validated":
          return 6;
      }
      return null;
    },
    icon() {
      switch (this.status) {
        case "canceled":
          return "x-circle-fill";
        case "rejected":
          return "person-x";
        case "completed":
          return "check-circle";
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.circular-progress {
  --size: 1em;
  --half-size: calc(var(--size) / 2);
  --stroke-width: 3px;
  --radius: calc((var(--size) - var(--stroke-width)) / 2);
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100 - var(--stroke-width));
  width: 1.05em;
  height: 1.05em;
  vertical-align: -0.2em;
}

.circular-progress circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress circle.bg {
  stroke: $light-grey;
}

.circular-progress circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  stroke: currentColor;
}

@for $i from 1 through 7 {
  .loan-step-#{$i} {
    --progress: #{round($i / 7 * 100)};
  }
}
</style>
