<script>
import FormsMapInput from "@/components/Forms/MapInput.vue";
import DescriptionList from "@/components/Loanable/DescriptionList.vue";
import UserRoleList from "@/components/Loanable/UserRoleList.vue";
import ImageCarousel from "@/components/shared/ImageCarousel.vue";
import ToggleLink from "@/components/shared/ToggleLink.vue";
import locales from "@/locales";
import Vue from "vue";

export default Vue.extend({
  name: "LoanableSidebar",
  components: { UserRoleList, FormsMapInput, ImageCarousel, DescriptionList, ToggleLink },
  props: {
    loanable: {
      type: Object,
      required: true,
    },
    showAdminLinks: {
      type: Boolean,
      default: false,
    },
    showContactsOnly: {
      type: Boolean,
      default: false,
    },
    showLoanableLink: {
      type: Boolean,
      default: true,
    },
    showAllInstructions: {
      type: Boolean,
      default: false,
    },
    showInstructions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prettyType() {
      switch (this.loanable.type) {
        case "car":
          return "de l'auto";
        case "bike":
          return "du vélo";
        case "trailer":
          return "de la remorque";
        default:
          return "de l'objet";
      }
    },
    mainInstructions() {
      if (!this.showInstructions) {
        return null;
      }

      if (this.showAllInstructions) {
        return this.loanable.instructions;
      }

      return this.loanable.trusted_borrower_instructions ?? this.loanable.instructions;
    },
  },
  i18n: {
    messages: {
      en: {
        loanable: locales.en.loanables,
      },
      fr: {
        loanable: locales.fr.loanables,
      },
    },
  },
});
</script>

<template>
  <div class="loanable-details-box">
    <b-card no-body>
      <image-carousel
        v-if="loanable && loanable.images && loanable.images.length > 0"
        class="card-img-top"
        :images="loanable.images"
        aspect-ratio="16 / 10"
        :preferred-sizes="['loan', 'thumbnail', 'original']"
      />
      <b-card-body>
        <b-card-title :class="{ archived: !!loanable.deleted_at, 'mb-2': !loanable.deleted_at }">
          <router-link
            v-if="showAdminLinks && showLoanableLink"
            :to="`/loanables/${loanable.id}`"
            >{{ loanable.name }}</router-link
          >
          <span v-else>{{ loanable.name }}</span>
        </b-card-title>

        <slot name="subtitle" />
        <toggle-link
          v-if="loanable.details"
          class="d-block mb-3 text-muted"
          collapse-id="loanable-details"
        >
          {{ $t("loanable.sidebar.details") | capitalize }} {{ prettyType }}</toggle-link
        >
        <b-card-text>
          <b-collapse
            v-if="loanable.details"
            id="loanable-details"
            role="tabpanel"
            accordion="loanable-details"
          >
            <description-list :loanable="loanable" details-only />
          </b-collapse>
          <hr />
          <dl>
            <dt>{{ $t("loanable.resource_people") | capitalize }}</dt>
            <dd>
              <user-role-list
                :loanable="loanable"
                :show-admin-links="showAdminLinks"
                :show-contacts-only="showContactsOnly"
              />
            </dd>
          </dl>
          <slot name="center" />
          <template
            v-if="
              loanable.comments ||
              loanable.instructions ||
              loanable.location_description ||
              loanable.position
            "
          >
            <hr />

            <dl class="mb-0">
              <template v-if="loanable.comments">
                <dt>{{ $t("loanable.fields.comments") | capitalize }}</dt>
                <dd class="user_text">{{ loanable.comments }}</dd>
              </template>

              <template
                v-if="
                  showAllInstructions && showInstructions && loanable.trusted_borrower_instructions
                "
              >
                <dt>{{ $t("loanable.fields.trusted_borrower_instructions") | capitalize }}</dt>

                <dd class="user_text">{{ loanable.trusted_borrower_instructions }}</dd>
              </template>

              <template v-if="mainInstructions">
                <dt>{{ $t("loanable.fields.instructions") | capitalize }}</dt>

                <dd class="user_text">{{ mainInstructions }}</dd>
              </template>

              <dt v-if="loanable.location_description || loanable.position">
                {{ $t("loanable.fields.position") | capitalize }}
              </dt>
              <dd>
                <div class="mb-2">{{ loanable.location_description }}</div>
                <forms-map-input
                  v-if="loanable.position"
                  :value="loanable.position"
                  :loanable="loanable"
                  disabled
                  bounded
                  allow-fullscreen
                />
              </dd>
            </dl>
          </template>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
dt {
  font-weight: 400;
  font-size: 0.8rem;
  color: $content-neutral-secondary;
  margin-bottom: 0.25rem;
}
dd {
  margin-bottom: 1rem;
}
dl {
  margin-bottom: 0;
}
</style>
