import LoginPage from "@/views/Login.vue";
import PasswordRequest from "@/views/password/Request.vue";
import PasswordReset from "@/views/password/Reset.vue";
import PasswordPage from "@/views/PasswordPage.vue";
import AcceptInvitation from "../views/AcceptInvitation.vue";

export default [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      title: "titles.login",
    },
  },
  {
    path: "/login/callback",
    name: "login-callback",
    component: LoginPage,
    meta: {
      title: "titles.login",
    },
  },
  {
    path: "/invitation/accept",
    name: "invitation-accept",
    component: AcceptInvitation,
    meta: {
      title: "Accepter l'invitation",
    },
  },
  {
    path: "/password",
    component: PasswordPage,
    meta: {
      title: "titles.password",
    },
    children: [
      {
        path: "request",
        name: "password-request",
        component: PasswordRequest,
        meta: {
          title: "titles.password_request",
        },
      },
      {
        path: "reset",
        name: "password-reset",
        component: PasswordReset,
        meta: {
          title: "titles.password_reset",
        },
      },
    ],
  },
];
