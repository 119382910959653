import { get } from "@/requests/server";
import { extractErrors } from "@/helpers";

export async function validateInvitation(id, code, email) {
  let validationResult = {};
  try {
    const { data } = await get(
      `/invitations/validate/${id}?code=${code}&email=${encodeURIComponent(email)}`,
      {
        requestOptions: { expects: [404] },
        notifications: { action: "validation d'invitation", resourceName: "l'invitation" },
      }
    );

    validationResult = {
      codeValid: data.code_valid,
      isActive: data.is_active,
      accountExists: data.account_exists,
      communityName: data.community_name,
      inviterName: data.inviter_name,
      valid: data.code_valid && data.is_active,
    };

    // if no email was validated, do not set the validation state
    if (email) {
      validationResult.emailValid = data.email_valid;
      validationResult.valid = validationResult.valid && data.email_valid;
    }
  } catch (e) {
    validationResult = {
      notFound: e.request?.status === 404,
      valid: false,
      message: e.response?.data ? extractErrors(e.response.data).join(", ") : "",
    };
  }

  validationResult.message = getValidationMessage(validationResult, !!email);
  return validationResult;
}

function getValidationMessage(validationResult, hasEmail) {
  if (validationResult.notFound) {
    return "Cette invitation n'a pas été trouvée - il se peut qu'elle ait été annulée.";
  }

  if (validationResult.isActive === false) {
    return "Cette invitation est expirée ou a déjà été utilisée!";
  }

  if (validationResult.codeValid === false) {
    return (
      "Avez-vous utilisé le lien qui a été envoyé à votre courriel? " +
      "Si ça ne fonctionne toujours pas après avoir réessayé, veuillez demander un nouveau lien d'invitation."
    );
  }

  if (validationResult.emailValid === false && hasEmail) {
    return "Cette invitation est liée à une autre adresse courriel.";
  }

  return validationResult.message;
}
