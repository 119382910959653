import { get } from "@/requests/server";

export default {
  namespaced: true,
  state: {
    data: {},
    error: null,
    lastLoadedAt: null,
    loaded: false,
  },
  mutations: {
    data(state, data) {
      state.data = data;
    },
    error(state, error) {
      state.error = error;
    },
    lastLoadedAt(state, lastLoadedAt) {
      state.lastLoadedAt = lastLoadedAt;
    },
    loaded(state, loaded) {
      state.loaded = loaded;
    },
    cancelToken(state, cancelToken) {
      state.cancelToken = cancelToken;
    },
  },
  actions: {
    async retrieve({ commit, state }) {
      if (state.lastLoadedAt > Date.now() - 3600) {
        return;
      }

      commit("loaded", false);
      const { data } = await get("/stats");
      commit("data", data);
      commit("lastLoadedAt", Date.now());
      commit("loaded", true);
      commit("cancelToken", null);
    },
  },
};
