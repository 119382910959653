<script>
import UserBlurb from "@/components/User/UserBlurb.vue";

export default {
  name: "UserRoleList",
  components: { UserBlurb },
  props: {
    showContactsOnly: {
      type: Boolean,
      default: true,
    },
    loanable: {
      type: Object,
      required: true,
    },
    showAdminLinks: {
      type: Boolean,
      default: false,
    },
    rolesToShow: {
      type: Array,
      default: () => ["owner", "coowner"],
    },
  },
  computed: {
    roles() {
      let roles = this.loanable.user_roles.filter((r) => this.rolesToShow.includes(r.role));
      if (this.showContactsOnly) {
        roles = roles.filter((c) => c.show_as_contact);
      }
      return roles;
    },
  },
};
</script>

<template>
  <div>
    <user-blurb
      v-for="role of roles"
      :key="role.id"
      class="mt-2"
      :show-admin-link="showAdminLinks"
      :user="role.user"
      :description="role.title ? role.title : role.role === 'owner' ? 'Propriétaire' : null"
      show-phone
    >
      <template #nameicon>
        <b-icon
          v-if="role.show_as_contact && !showContactsOnly"
          v-b-tooltip.hover
          title="Visible aux emprunteurs"
          variant="primary"
          icon="eye-fill"
        />
      </template>
    </user-blurb>
  </div>
</template>

<style scoped lang="scss"></style>
