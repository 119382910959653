<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { extractErrors } from "@/helpers";
import router from "@/router";
import axios from "axios";
import dayjs from "dayjs";
import Vue from "vue";

export default Vue.extend({
  name: "CommunityInvitation",
  components: { IconButton, FormsValidatedInput },
  data() {
    const queryParams = router.currentRoute.query;
    const communityId = queryParams.community_id ? parseInt(queryParams.community_id) : null;

    if (Object.keys(queryParams).length > 0) {
      router.replace({ query: {} });
    }

    return {
      saving: false,
      communityId,
      communityName: "",
      reason: null,
      message: null,
      autoApprove: false,
      emails: queryParams.emails ?? "",
      // By default, show community selection only if not set initially.
      showCommunityId: !communityId,
    };
  },
  computed: {
    invitationCount() {
      return this.emails.split(",").length;
    },
  },
  methods: {
    dayjs,
    resetData() {
      this.saving = false;
      this.reason = null;
      this.autoApprove = false;
      this.emails = "";
      this.message = null;
    },
    async sendInvitations(resetValidationCallback) {
      try {
        this.saving = true;
        await axios.put("/invitations/batch", {
          emails: this.emails,
          reason: this.reason,
          message: this.message,
          community_id: this.communityId,
          auto_approve: this.autoApprove,
        });

        // Reset to default after creating invitations
        this.resetData();

        this.$store.commit("addNotification", {
          content: "Les invitations ont été envoyées avec succès.",
          title: "Invitations envoyées",
          variant: "success",
        });

        resetValidationCallback();
      } catch (e) {
        const content = extractErrors(e.response?.data).join(", ") ?? "";

        this.$store.commit("addNotification", {
          content,
          title: "Erreur lors de la création des invitations",
          variant: "danger",
        });
        throw e;
      } finally {
        this.saving = false;
      }
    },
    setCommunityId(community) {
      this.communityId = community?.id ?? null;
    },
    setCommunityName(community) {
      this.communityName = community?.name ?? "";
    },
  },
});
</script>

<template>
  <validation-observer v-slot="{ passes, reset }">
    <b-form class="form" @submit.stop.prevent="passes(() => sendInvitations(reset))">
      <h2 class="mb-4">Inviter à rejoindre LocoMotion {{ communityName }}</h2>
      <forms-validated-input
        v-show="showCommunityId"
        :value="communityId"
        type="relation"
        name="community_id"
        label="Communauté"
        :rules="{
          required: true,
        }"
        :query="{
          slug: 'communities',
          value: 'id',
          text: 'name',
          params: {
            for: 'admin',
          },
        }"
        @relation="setCommunityId"
        @search-object-updated="setCommunityName"
      />

      <forms-validated-input
        v-model="emails"
        label="courriels"
        type="multiple-emails"
        placeholder="la.voisine@example.com"
        name="emails"
        :rules="{
          required: true,
        }"
      >
        <template #label>
          Faites découvrir les options de transport actif et partagé de votre communauté à plus de
          personnes!
        </template>
      </forms-validated-input>

      <forms-validated-input
        v-model="message"
        label="Message d'invitation personnalisé (optionnel)"
        placeholder="Salut Mathieu!

On s’est vus à la distribution de fleurs samedi passé.
Fais-moi signe si tu as besoin d’aide pour ton inscription.

Nicole au (438) 555-0123"
        type="textarea"
        :rules="{
          required: false,
        }"
        name="message"
        :rows="3"
        :max-rows="10"
      />
      <forms-validated-input
        v-model="autoApprove"
        type="checkbox"
        name="auto_approve"
        :label="
          invitationCount > 1
            ? 'J’ai vu les preuves d’adresse et/ou d’identité de ces personnes, je pré-approuve leurs profils.'
            : 'J’ai vu les preuves d’adresse et/ou d’identité de cette personne, je pré-approuve leur profil.'
        "
      >
      </forms-validated-input>

      <forms-validated-input
        v-if="autoApprove"
        v-model="reason"
        label="Note pour le comité"
        placeholder="ex.: preuve d’adresse vue au kiosque du 21 mai 2024"
        type="textarea"
        :rules="{
          required: true,
        }"
        name="message"
        :rows="2"
        :max-rows="4"
        description="Cette note sera utilisée comme raison d'approbation de ces personnes dans la communauté."
      />

      <icon-button role="send" type="submit" :loading="saving"> Envoyer </icon-button>
      <div>
        <small class="text-muted"
          >À noter: les invitations ne sont valides que pour 30 jours.</small
        >
      </div>
    </b-form>
  </validation-observer>
</template>

<style lang="scss"></style>
