import { UPLOAD_MAX_FILESIZE } from "../../constants";
import { post } from "./server";

/**
 * @async
 *
 * @param {string} fieldName
 * @param {FileList} fileList
 * @param {{}} extraFormData
 * @param {string} endpoint
 *
 * @return {Promise<{file?: {}, error?: string}>}
 *
 * */
async function uploadFile(fieldName, fileList, extraFormData = {}, endpoint = "files") {
  const formData = new FormData();

  if (!fileList.length) {
    return {};
  }

  let totalSize = 0;
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    totalSize += file.size;

    if (totalSize > UPLOAD_MAX_FILESIZE) {
      return {
        error: "La taille du fichier ne doit pas dépasser 20 Mo.",
      };
    }

    formData.append(fieldName, file, file.name);
  }

  for (const extraFormDataKey in extraFormData) {
    formData.append(extraFormDataKey, extraFormData[extraFormDataKey]);
  }

  formData.append("field", fieldName);

  try {
    const { data: file } = await post(`/${endpoint}`, formData, {
      notifications: { action: "téléversement du fichier" },
    });
    return { file };
  } catch (e) {
    // In the case of files that are too large, the connection is reset by the server, which
    // prevents us from getting a status code, even directly from the underlying XHR.
    // In that case, a somewhat generic error message.
    return {
      error:
        e.response?.data?.message ??
        "Une erreur indéterminée est survenue. Assurez-vous que la taille de votre fichier est en dessous de 20 Mo et que votre connection internet est active.",
    };
  }
}

/**
 * @async
 *
 * @param {string} fieldName
 * @param {FileList} fileList
 * @param {{}} extraFormData
 *
 * @return {Promise<{file?: {}, error?: string}>}
 */
async function uploadImage(fieldName, fileList, extraFormData) {
  return uploadFile(fieldName, fileList, extraFormData, "images");
}

export { uploadFile, uploadImage };
