<template>
  <div class="date-range-picker">
    <b-form-input v-model="from" type="number" size="sm" debounce="300" @update="valueUpdated" />
    <span>:</span>
    <b-form-input v-model="to" type="number" size="sm" debounce="300" @update="valueUpdated" />
  </div>
</template>

<script>
export default {
  name: "FormsNumberRangePicker",
  props: {
    value: {
      required: false,
      type: String,
      default: ":",
    },
  },
  data() {
    if (!this.value) {
      return {
        from: null,
        to: null,
      };
    }

    const fromMatch = this.value.match(/([0-9.]+):/);
    const toMatch = this.value.match(/.*:([0-9.]+)/);
    return {
      from: fromMatch ? fromMatch[1] : null,
      to: toMatch ? toMatch[1] : null,
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.from = null;
        this.to = null;
        return;
      }

      const fromMatch = this.value.match(/([0-9.]+):/);
      const toMatch = this.value.match(/.*:([0-9.]+)/);

      this.from = fromMatch ? fromMatch[1] : null;
      this.to = toMatch ? toMatch[1] : null;
    },
  },
  methods: {
    valueUpdated() {
      if (!this.from && !this.to) {
        return this.$emit("input", null);
      }
      this.$emit("input", `${this.from ? this.from : ""}:${this.to ? this.to : ""}`);
    },
  },
};
</script>

<style lang="scss">
.date-range-picker {
  display: flex;

  > span {
    flex-shrink: 1;
    margin: auto 3px;
  }
}
</style>
