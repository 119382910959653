<template>
  <layout-page name="not-found">
    <div class="pt-5 text-center">
      <h2 class="mb-3">Page introuvable!</h2>
      <icon-button variant="primary" to="/" icon="house-door-fill">Retour à l'accueil</icon-button>
    </div>
  </layout-page>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "NotFound",
  components: { IconButton },
};
</script>

<style lang="scss"></style>
