<template>
  <layout-page fluid="lg" name="loan">
    <div>
      <vue-headful :title="fullTitle" />
      <full-loan :loan-id="id" />
    </div>
  </layout-page>
</template>

<script>
import FullLoan from "@/components/Loan/FullLoan.vue";

import { capitalize } from "@/helpers/filters";

import locales from "@/locales";

export default {
  name: "LoanPage",
  components: {
    FullLoan,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    fullTitle() {
      const parts = ["LocoMotion", capitalize(this.$i18n.t("titles.loans"))];

      if (this.pageTitle) {
        parts.push(capitalize(this.$i18n.tc("titles.loanable", 1)));
      }

      return parts.reverse().join(" | ");
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
        titles: locales.en.titles,
      },
      fr: {
        ...locales.fr.loans,
        titles: locales.fr.titles,
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan.page {
  @include media-breakpoint-up(lg) {
    .page__content {
      padding-top: 2rem;
    }
  }
}
</style>
