<template>
  <div class="status-container">
    <b-badge
      v-b-tooltip.hover="{ disabled: noHover }"
      :title="!noHover ? $t(`statuses.${item.status}.tooltip`) : null"
      class="loan-status-pill loan-status"
      :class="actionRequired ? 'loan-status-action-required' : `loan-status-${item.status}`"
    >
      <loan-status-icon :status="item.status" :action-required="actionRequired" />
      <div><slot name="prefix"></slot> {{ label }}</div>
    </b-badge>
    <b-badge v-if="item.extension_duration_in_minutes" variant="warning">
      <b-icon icon="clock-history" />
      <template v-if="!small">&nbsp;Prolongation demandée</template>
    </b-badge>
    <b-badge v-if="hasActiveIncidents && showIncidents" variant="danger">
      <b-icon icon="exclamation-triangle-fill" />
      <template v-if="!small">&nbsp;Incident en cours</template>
    </b-badge>
    <div v-if="item.is_self_service && showSelfService">
      <b-badge v-b-tooltip="$t('statuses.self_service')" variant="primary">
        <b-icon icon="unlock-fill" />
        <template v-if="!small">&nbsp;{{ $t("statuses.self_service") }}</template></b-badge
      >
    </div>
  </div>
</template>

<script>
import LoanStatusIcon from "@/components/Loan/Status/LoanStatusIcon.vue";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower, loanBlockedByIncident } from "@/helpers/permissions/loans";
import locales from "@/locales";

export default {
  name: "LoanStatus",
  components: { LoanStatusIcon },
  props: {
    // Item is a loan.
    item: {
      type: Object,
      required: true,
    },
    showSelfService: {
      type: Boolean,
      default: true,
    },
    showIncidents: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBorrower() {
      return isBorrower(this.$store.state.user, this.item);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.$store.state.user, this.item.loanable);
    },
    actionRequired() {
      return (
        (this.isBorrower && this.item.borrower_action_required) ||
        (this.isCoownerOrOwner && this.item.owner_action_required)
      );
    },

    variant() {
      if (this.actionRequired) {
        return "warning";
      }
      return null;
    },
    label() {
      if (this.item.status === "validated") {
        if (this.item.borrower_must_pay_compensation || this.item.borrower_must_pay_insurance) {
          return this.$t(`statuses.validated.label`);
        }
        if (this.item.borrower_may_contribute) {
          return this.$t(`statuses.validated.label_contribution`);
        }
        return this.$t(`statuses.validated.label_grace_period`);
      }

      return this.$t(`statuses.${this.item.status}.label`);
    },
    hasActiveIncidents() {
      return !!this.activeIncident;
    },
    meaningfulIncidents() {
      // If loan is in process all blocking incidents are meaningful.
      // All incidents having this loan as source are meaningful (blocking or not).
      return (
        this.item.loanable.active_incidents?.filter(
          (incident) =>
            (incident.loan_id && incident.loan_id === this.item.id) ||
            (this.item.status !== "completed" && loanBlockedByIncident(this.item, incident))
        ) ?? []
      );
    },
    activeIncident() {
      return this.meaningfulIncidents.length > 0 ? this.meaningfulIncidents[0] : null;
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
      },
      fr: {
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss">
.status-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.loan-status-pill.badge {
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
</style>
