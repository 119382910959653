<script>
import TinyInvoice from "@/components/Invoice/TinyInvoice.vue";
import LoanPageEditableBox from "@/components/Loan/LoanPageEditableBox.vue";
import DonationSlider from "@/components/shared/DonationSlider.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ProvisionModal from "@/components/User/ProvisionModal.vue";
import { debounce } from "@/helpers/debounce";
import { normalizeCurrency } from "@/helpers/filters";
import { summarizeBorrowerInvoice, summarizeOwnerInvoice } from "@/helpers/invoices";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower, isLoanAdmin } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";

export default {
  name: "LoanPaymentBox",
  components: { LoanPageEditableBox, IconButton, DonationSlider, ProvisionModal, TinyInvoice },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    borrowerInvoice: {
      type: Object,
      default: () => null,
    },
    desiredContribution: {
      type: [Number, String],
      default: 0,
    },
    ownerInvoice: {
      type: Object,
      default: () => null,
    },
    estimating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isChangingDistance: {
      type: Boolean,
      default: false,
    },
    isChangingDuration: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platformTip: normalizeCurrency(this.loan.platform_tip),
      loading: false,
    };
  },
  computed: {
    initiallyEditing() {
      if (!this.isBorrower) {
        return false;
      }

      if (!this.loanIsInProcess) {
        return false;
      }

      if (!this.loan.borrower_may_contribute) {
        return false;
      }

      if (this.loan.status === "validated" || this.loan.status === "accepted") {
        return true;
      }

      if (this.loan.platform_tip === null) {
        return true;
      }

      // Only contribution to show, might as well show the slider
      if (!this.loan.borrower_must_pay_insurance && !this.loan.borrower_must_pay_compensation) {
        return true;
      }

      // If user cannot pay initially, show slider
      return this.user.balance < -(this.loan.borrower_invoice?.user_balance_change ?? 0);
    },
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    isLoanAdmin() {
      return isLoanAdmin(this.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.user, this.loan.loanable);
    },
    actualPrice() {
      return -(this.borrowerInvoice?.user_balance_change ?? 0);
    },
    userHasSufficientBalance() {
      return this.user.balance >= this.actualPrice;
    },
    hasNoInvoiceItems() {
      return this.borrowerInvoice?.items.length === 0;
    },
    actionLabel() {
      if (this.loan.status === "accepted") {
        return "prépayer";
      }
      if (this.loan.status === "validated") {
        if (this.hasNoInvoiceItems && this.platformTip === 0) {
          if (!this.isExemptFromContributions) {
            return "terminer sans contribuer";
          } else {
            return "terminer l'emprunt";
          }
        }
        if (!this.loan.borrower_must_pay_insurance && !this.loan.borrower_must_pay_compensation) {
          return "contribuer";
        }
        return "payer";
      }
      if (this.isBorrower && !this.isExemptFromContributions && !this.wouldSaveUnchangedTip) {
        if (this.platformTip === 0) {
          return "continuer sans contribution";
        }
        return "enregistrer ma contribution";
      }
      return null;
    },
    paymentButtonLabel() {
      if (this.userHasSufficientBalance) {
        return this.actionLabel;
      }

      if (this.actionLabel) {
        return "ajouter au solde et " + this.actionLabel;
      }

      return "ajouter au solde";
    },
    summarizedBorrowerInvoice() {
      if (!this.borrowerInvoice || (!this.isBorrower && !this.isLoanAdmin)) {
        return null;
      }

      return summarizeBorrowerInvoice(this.borrowerInvoice, {
        ownerName: this.loan.loanable.owner.user.name,
        communityName: this.loan.community.name,
        groupCompensations: true,
        contributionsDetails: true,
      });
    },
    summarizedOwnerInvoice() {
      if (this.isBorrower || this.isLoanAdmin || !this.isCoownerOrOwner) {
        return null;
      }
      return summarizeOwnerInvoice(this.ownerInvoice, this.loan.borrower_user.full_name);
    },
    contributionTotal() {
      let total = 0;

      for (const item of this.borrowerInvoice?.items ?? []) {
        if (item.item_type === "donation.loan" || item.item_type === "loan.contribution") {
          total += item.total;
        }
      }

      return total;
    },
    isInvoiceUpdating() {
      return (
        this.estimating ||
        this.contributionTotal !== -this.platformTip ||
        this.$refs.donationSlider?.isChanging
      );
    },
    isFree() {
      return this.loan.is_free;
    },
    loanIsInProcess() {
      return (
        this.loan.status !== "canceled" &&
        this.loan.status !== "rejected" &&
        this.loan.status !== "completed"
      );
    },
    isExemptFromContributions() {
      return this.loan.applicable_amount_types.contributions === "not_applicable";
    },
    wouldSaveUnchangedTip() {
      return (
        this.loan.status !== "accepted" &&
        this.loan.status !== "validated" &&
        this.platformTip === this.loan.platform_tip
      );
    },
    wouldPayOrPrepayWithChangedInfo() {
      return (
        (this.loan.status === "accepted" || this.loan.status === "validated") &&
        (this.isChangingDuration || this.isChangingDistance)
      );
    },
  },
  watch: {
    initiallyEditing() {
      this.maybeSetPlatformTipToDesiredValue();
    },
    desiredContribution() {
      this.maybeSetPlatformTipToDesiredValue();
    },
  },
  mounted() {
    this.maybeSetPlatformTipToDesiredValue();
  },
  created() {
    this.debouncedEstimatePrice = debounce(this.estimatePrice, 150);
  },
  methods: {
    async onPlatformTipSaved(tip) {
      let endpoint = `/loans/${this.loan.id}/tip`;
      let action = "modification de la contribution";
      let onSuccess = "contribution enregistrée!";

      if (this.loan.status === "accepted") {
        endpoint = `/loans/${this.loan.id}/prepay`;
        action = "prépaiement";
        onSuccess = "emprunt prépayé!";
      } else if (this.loan.status === "validated") {
        endpoint = `/loans/${this.loan.id}/pay`;
        action = "paiement";
        onSuccess = "emprunt payé!";
      }

      this.loading = true;
      const { data } = await put(
        endpoint,
        {
          platform_tip: tip,
        },
        { cleanupCallback: () => (this.loading = false), notifications: { onSuccess, action } }
      );

      // Load user if needed
      if (!this.userHasSufficientBalance || this.loan.status === "validated") {
        this.$store.dispatch("loadUser");
      }
      this.$emit("input", data);
      this.$emit("estimate"); // reset estimation, since updated loan has fresh invoices
      this.$emit("jump", "loan");
    },
    tipAmountChanged(newAmount) {
      if (this.platformTip === newAmount) {
        return;
      }

      this.platformTip = normalizeCurrency(newAmount ?? 0);
      this.debouncedEstimatePrice();
    },
    async estimatePrice() {
      this.$emit("estimate", this.platformTip);
    },
    async handlePaid() {
      await this.onPlatformTipSaved(this.platformTip);
    },
    async handlePaymentButtonClick(closeEdit = null) {
      // await tick, since currency input only changes value on focus lost.
      await this.$nextTick();
      if (this.estimating) {
        return;
      }
      if (!this.userHasSufficientBalance) {
        this.$refs["provision-modal"].show();
      } else {
        await this.onPlatformTipSaved(this.platformTip);
        if (closeEdit && !this.initiallyEditing) {
          closeEdit();
        }
      }
    },
    reset() {
      this.platformTip = normalizeCurrency(this.loan.platform_tip);
      this.$emit("estimate");
    },
    maybeSetPlatformTipToDesiredValue() {
      if (
        this.loan.platform_tip === null &&
        this.initiallyEditing &&
        this.platformTip < this.desiredContribution
      ) {
        this.platformTip = normalizeCurrency(this.desiredContribution);
        this.estimatePrice();
      }
    },
  },
};
</script>

<template>
  <loan-page-editable-box class="loan-donations" :initially-editing="initiallyEditing">
    <template #default="{ edit }">
      <div v-if="isBorrower || isLoanAdmin">
        <tiny-invoice
          v-if="summarizedBorrowerInvoice && summarizedBorrowerInvoice.items.length > 0"
          :invoice="summarizedBorrowerInvoice"
          :loading="isInvoiceUpdating && isBorrower"
          :styled="false"
        />
        <div v-else>Emprunt gratuit sans contribution</div>
      </div>
      <div v-else-if="isCoownerOrOwner">
        <tiny-invoice
          v-if="summarizedOwnerInvoice"
          :invoice="summarizedOwnerInvoice"
          :styled="false"
        />
      </div>

      <provision-modal
        v-if="isBorrower && !userHasSufficientBalance && loanIsInProcess"
        ref="provision-modal"
        :price="actualPrice"
        :loading="loading || estimating"
        :action-name="actionLabel"
        reload-user
        minimum-default
        @complete="
          () => {
            $bvModal.hide('payment-provision-modal');
            handlePaid();
          }
        "
      />

      <template v-if="isBorrower && loanIsInProcess">
        <icon-button v-if="!isExemptFromContributions" block role="edit" :onclick="edit"
          >Modifier ma contribution</icon-button
        >
        <template
          v-else-if="
            !userHasSufficientBalance || loan.status === 'accepted' || loan.status === 'validated'
          "
        >
          <icon-button
            variant="primary"
            block
            :disabled="disabled || isInvoiceUpdating || isChangingDistance || isChangingDuration"
            :loading="loading"
            :onclick="handlePaymentButtonClick"
          >
            {{ paymentButtonLabel | capitalize }}
          </icon-button>
          <b-alert v-if="isChangingDistance || isChangingDuration" shot variant="warning">
            <strong>Attention :&nbsp;</strong>cette estimation inclut vos modifications en cours
            (<icon-button
              v-if="isChangingDuration"
              size="inline"
              variant="ghost-secondary"
              @click="$emit('jump', 'dates')"
            >
              dates
            </icon-button>
            <span v-if="isChangingDuration && isChangingDistance"> et </span>
            <icon-button
              v-if="isChangingDistance"
              size="inline"
              variant="ghost-secondary"
              @click="$emit('jump', 'info')"
            >
              distance parcourue </icon-button
            >). Veuillez enregistrer les changements ou les annuler avant de payer.
          </b-alert>
        </template>
      </template>
    </template>
    <template #form="{ close }">
      <donation-slider
        ref="donationSlider"
        title="Par et pour la communauté"
        image="/Foire_des_Possibles2023.jpg"
        :disabled="loading"
        :amount="platformTip"
        :target="desiredContribution"
        :subscription-available="loan.subscription_available"
        :contribution-required="loan.applicable_amount_types.contributions === 'required'"
        :pricing-loanable-type="loan.loanable.pricing_loanable_type"
        :community-id="loan.community.id"
        @amountChanged="tipAmountChanged"
      >
        <p>Le projet vit dans votre communauté grâce à des bénévoles qui donnent de leur temps.</p>
        <p class="mb-1">Contribuez vous aussi à ce que tout roule!</p>
      </donation-slider>

      <div class="my-3">
        <tiny-invoice
          :invoice="summarizedBorrowerInvoice"
          :loading="isInvoiceUpdating && isBorrower"
          :styled="false"
        />
      </div>

      <provision-modal
        v-if="isBorrower && !userHasSufficientBalance && loanIsInProcess"
        ref="provision-modal"
        :price="actualPrice"
        :loading="loading"
        :action-name="actionLabel"
        :reload-user="false"
        minimum-default
        @complete="
          () => {
            $bvModal.hide('payment-provision-modal');
            handlePaid();
          }
        "
      />

      <b-alert v-if="isChangingDuration || isChangingDistance" show variant="warning" class="mb-3">
        <strong>Attention&nbsp;:</strong> cette estimation inclut vos modifications en cours
        (<icon-button
          v-if="isChangingDuration"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'dates')"
        >
          dates
        </icon-button>
        <span v-if="isChangingDuration && isChangingDistance"> et </span>
        <icon-button
          v-if="isChangingDistance"
          size="inline"
          variant="ghost-secondary"
          @click="$emit('jump', 'info')"
        >
          distance parcourue </icon-button
        >).
        <span v-if="wouldPayOrPrepayWithChangedInfo">
          Veuillez enregistrer les changements ou les annuler avant de payer.
        </span>
      </b-alert>
      <div>
        <slot name="complete-button">
          <icon-button
            v-if="!wouldSaveUnchangedTip || !userHasSufficientBalance"
            variant="primary"
            block
            :disabled="disabled || isInvoiceUpdating || wouldPayOrPrepayWithChangedInfo"
            :loading="loading"
            :onclick="async () => handlePaymentButtonClick(close)"
          >
            {{ paymentButtonLabel | capitalize }}
          </icon-button>
        </slot>
        <icon-button
          v-if="!initiallyEditing"
          class="mt-2"
          role="cancel"
          :disabled="loading"
          block
          :onclick="
            () => {
              close();
              reset();
            }
          "
          >Annuler</icon-button
        >
      </div>
    </template>
  </loan-page-editable-box>
</template>

<style lang="scss">
.loan-donations {
  .tiny-invoice {
    margin-bottom: 0;

    .invoice-contribution th,
    .invoice-contribution td {
      color: $locomotion-light-green;
    }
  }

  .invoice-total {
    color: $primary;
    font-size: 1.25rem;
    th,
    td {
      border-top: 1px solid $grey;
    }
  }
}
</style>
