<template>
  <user-add-credit-box
    v-if="user.balance < price"
    :payment-methods="user.payment_methods"
    :minimum-required="price - user.balance"
    :actual-cost="minimumOnly ? 0 : price"
    :no-custom-amount="minimumOnly"
    :no-cancel="!showCancel"
    :label="addCreditLabel"
    :loading="loading"
    :disabled="disabled"
    :minimum-default="minimumDefault"
    @bought="onBought"
    @cancel="$emit('cancel')"
  >
  </user-add-credit-box>
  <div v-else class="text-center">
    <icon-button
      :loading="loading"
      variant="success"
      :disabled="disabled"
      @click="$emit('complete')"
    >
      {{ actionName | capitalize }}
    </icon-button>
    <icon-button v-if="showCancel" class="ml-2" role="cancel" @click="$emit('cancel')">
      Annuler
    </icon-button>
  </div>
</template>
<script>
import IconButton from "@/components/shared/IconButton.vue";
import UserAddCreditBox from "@/components/User/AddCreditBox.vue";

export default {
  name: "PaymentDialog",
  components: {
    IconButton,
    UserAddCreditBox,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    actionName: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    // Whether to reload the current user after purchase.
    reloadUser: {
      type: Boolean,
      default: true,
    },
    // Whether not to give the option to select different amounts to pay. If true, only the minimum
    // (price - user.balance) will be shown.
    minimumOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    minimumDefault: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addCreditLabel() {
      if (this.actionName) {
        return "Ajouter au solde et " + this.actionName;
      }
      return "Ajouter au solde";
    },
  },
  methods: {
    onBought() {
      if (this.reloadUser) {
        this.$store.dispatch("loadUser");
      }
      this.$emit("complete");
    },
  },
};
</script>
