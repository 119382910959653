<script>
import LoanAccepted from "@/components/Loan/Status/Alerts/Accepted.vue";
import LoanCanceled from "@/components/Loan/Status/Alerts/Canceled.vue";
import LoanCompleted from "@/components/Loan/Status/Alerts/Completed.vue";
import LoanConfirmed from "@/components/Loan/Status/Alerts/Confirmed.vue";
import LoanEnded from "@/components/Loan/Status/Alerts/Ended.vue";
import NewLoan from "@/components/Loan/Status/Alerts/NewLoan.vue";
import LoanOngoing from "@/components/Loan/Status/Alerts/Ongoing.vue";
import LoanRejected from "@/components/Loan/Status/Alerts/Rejected.vue";
import LoanRequested from "@/components/Loan/Status/Alerts/Requested.vue";
import LoanValidated from "@/components/Loan/Status/Alerts/Validated.vue";

export default {
  name: "LoanStatusAlert",
  components: {
    LoanRejected,
    LoanValidated,
    NewLoan,
    LoanOngoing,
    LoanCompleted,
    LoanConfirmed,
    LoanCanceled,
    LoanAccepted,
    LoanRequested,
    LoanEnded,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isNewLoan() {
      return !this.loan.id || this.loan.id === "new";
    },
  },
};
</script>

<template>
  <div class="alerts">
    <transition name="fade">
      <new-loan
        v-if="isNewLoan"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-requested
        v-else-if="loan.status === 'requested'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-accepted
        v-else-if="loan.status === 'accepted'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-confirmed
        v-else-if="loan.status === 'confirmed'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-ongoing
        v-else-if="loan.status === 'ongoing'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-ended
        v-else-if="loan.status === 'ended'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-validated
        v-else-if="loan.status === 'validated'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-rejected
        v-else-if="loan.status === 'rejected'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-canceled
        v-else-if="loan.status === 'canceled'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
      <loan-completed
        v-else-if="loan.status === 'completed'"
        :loan="loan"
        @input="$emit('input', $event)"
        @jump="$emit('jump', $event)"
      />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter {
  transform: translateY(2rem);
}

.fade-leave-to {
  transform: translateY(-2rem);
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}
.fade-leave-active {
  position: absolute;
  width: calc(100% - 2rem); // Take into account padding of alert
}
</style>
