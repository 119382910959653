<template>
  <div>
    <paginated-table
      id="admin-invoices-table"
      endpoint="invoices"
      :columns="columns"
      :label="$tc('invoice', 2)"
      row-link
      :show-action-column="false"
      @filters-changed="setInvoiceFilters"
      @updating="$refs.export.reset()"
    >
      <template #cell(paid_at)="{ item }">
        {{ item.paid_at ? "✓" : "✗" }}
      </template>
      <template v-if="showExportInvoiceItemsButton" #moreExports>
        <export-button
          ref="export"
          class="d-block mt-2"
          store="invoiceItems"
          :params="filters"
          label="items des factures"
        />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import locales from "@/locales";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import ExportButton from "@/components/shared/ExportButton.vue";

export default {
  name: "AdminInvoices",
  components: {
    ExportButton,
    PaginatedTable,
  },
  data() {
    return {
      filters: {},
      columns: [
        Column.withoutFilter("id", "ID", "id", { showByDefault: false }),
        new Column("user.full_name", "Membre"),
        new Column("created_at", "Date", "date"),
        Column.withoutFilter("paid_at", "Payée", "date"),
        new Column("total", "Total", "currency"),
        new Column("total_with_taxes", "Total avec taxes", "currency"),
      ],
    };
  },
  computed: {
    showExportInvoiceItemsButton() {
      return this.$store.state.user.role === "admin";
    },
  },
  methods: {
    setInvoiceFilters(invoiceFilters) {
      const newFilters = {};
      for (const invoiceFilter in invoiceFilters) {
        newFilters["invoice." + invoiceFilter] = invoiceFilters[invoiceFilter];
      }

      this.filters = newFilters;
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.invoices,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.invoices,
        ...locales.fr.forms,
      },
    },
  },
};
</script>

<style lang="scss"></style>
