<template>
  <div :class="`user-avatar user-avatar-${size}`">
    <div v-if="variant == 'cut-out'" class="user-avatar__background"></div>
    <safe-image
      v-if="user.avatar && !failedFetchingImage"
      no-zoom
      class="user-avatar__image"
      :image="user.avatar"
      aspect-ratio="1 / 1"
      :preferred-sizes="['avatar', 'thumbnail', 'original']"
      @failed="failedFetchingImage = true"
    />
    <div v-else-if="userInitials" class="user-avatar__initials">
      {{ userInitials }}
    </div>
    <div v-else class="user-avatar__icon">
      <svg-profile />
    </div>
  </div>
</template>

<script>
import Profile from "@/assets/svg/profile.svg";
import SafeImage from "@/components/shared/SafeImage.vue";

export default {
  name: "UserAvatar",
  components: {
    SafeImage,
    "svg-profile": Profile,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      failedFetchingImage: false,
    };
  },
  computed: {
    userInitials() {
      if (typeof this.user.name === "string" && this.user.name.length > 0) {
        return `${this.user.name[0]}${this.user?.last_name.slice(0, 1)}`.toUpperCase();
      } else if (this.user.full_name) {
        return this.user.full_name[0].toUpperCase();
      } else if (typeof this.user.email === "string" && this.user.email.length > 0) {
        return this.user?.email[0].toUpperCase();
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
.user-avatar {
  position: relative;

  &.user-avatar-md {
    // Default size.
    width: 3rem;
    height: 3rem;

    .user-avatar__background {
      top: -0.125rem;
      left: -0.125rem;

      height: 3.25rem;
      width: 3.25rem;
    }
  }

  &.user-avatar-sm {
    // Default size.
    width: 2.5rem;
    height: 2.5rem;

    .user-avatar__background {
      top: -0.125rem;
      left: -0.125rem;

      height: 2.75rem;
      width: 2.75rem;
    }
  }

  font-weight: 700;
  color: $white;

  // Background is just a bit wider than the avatar.
  &__background {
    position: absolute;
    background-color: $white;
    // That makes a rounded div no matter the size.
    border-radius: 99999px;
  }

  &__image,
  &__initials,
  &__icon {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    // That makes a rounded div no matter the size.
    border-radius: 99999px;

    font-size: 1.25rem;
  }

  &__initials,
  &__icon {
    // Don't add to image as it will create an inelegant rim.
    background-color: $locomotion-green;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    object-fit: cover;
    object-position: top;
  }
}
</style>
