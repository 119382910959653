export default {
  fr: {
    descriptions: {
      report: `Téléchargez et remplissez la fiche puis gardez-en une copie imprimée dans le carnet de bord et une copie virtuelle ici.
        Lorsque vous partagerez votre auto, vous pourrez vous appuyer sur cette fiche dans vos discussions. Gardez-la bien à jour !`,
    },
    engines: {
      fuel: "essence",
      diesel: "diesel",
      electric: "électrique",
      hybrid: "hybride",
    },
    fields: {
      brand: "marque",
      engine: "moteur",
      has_informed_insurer: "l'assureur a été informé",
      insurer: "assureur",
      value_category: "catégorie de valeur",
      model: "modèle",
      papers_location: "emplacement des papiers",
      plate_number: "numéro de plaque",
      pricing_category: "catégorie de tarification",
      report: "fiche - état de l'auto",
      report_download: "télécharger le gabarit de la fiche",
      transmission_mode: "transmission",
      year_of_circulation: "année de mise en circulation",
      has_onboard_notebook: "carnet de bord dans le coffre à gant",
      has_report_in_notebook: "fiche de l'état de l'auto placée dans le carnet de bord",
    },
    transmission_modes: {
      automatic: "automatique",
      manual: "manuelle",
    },
  },
};
