<script>
import DropdownSelect from "@/components/DropdownSelect.vue";
import LoanStatus from "@/components/Loan/Status/LoanStatusBadge.vue";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower, isLoanAdmin } from "@/helpers/permissions/loans";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export default {
  name: "LoanStatusAlertContainer",
  components: { LoanStatus, DropdownSelect },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    borrowerActionRequired: {
      type: Boolean,
      default: false,
    },
    ownerActionRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forceShowAs: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.user, this.loan.loanable);
    },
    isGlobalAdmin() {
      return isGlobalAdmin(this.user);
    },
    isLoanAdmin() {
      return isLoanAdmin(this.user, this.loan);
    },
    showAs() {
      if (this.forceShowAs) {
        return this.forceShowAs;
      }
      if (this.borrowerActionRequired && this.isBorrower) {
        return "borrower";
      }
      if (this.ownerActionRequired && this.isCoownerOrOwner) {
        return "owner";
      }
      if (this.isBorrower) {
        return "borrower";
      }
      if (this.isCoownerOrOwner) {
        return "owner";
      }
      if (this.isLoanAdmin) {
        return "admin";
      }
      return null;
    },
    actionRequired() {
      return (
        (this.showAs === "borrower" && this.borrowerActionRequired) ||
        (this.showAs === "owner" && this.ownerActionRequired)
      );
    },
  },
};
</script>

<template>
  <b-alert
    v-if="!!$slots[showAs]"
    show
    class="loan-status-alert loan-status"
    :class="actionRequired ? 'loan-status-action-required' : `loan-status-${loan.status}`"
  >
    <div v-if="loan.status" class="action-required-header">
      <loan-status :show-incidents="false" :item="loan" :show-self-service="false" no-hover>
        <template v-if="actionRequired" #prefix
          ><span class="font-weight-bold">Action Requise</span> &bull;</template
        >
      </loan-status>
    </div>
    <slot :name="showAs" />
    <template v-if="isGlobalAdmin">
      <hr />
      <div class="button-list">
        <dropdown-select
          :options="[
            { value: 'admin', label: 'admin' },
            { value: 'owner', label: 'responsable' },
            { value: 'borrower', label: 'emprunteur-euse' },
          ]"
          :value="showAs"
          icon="person-bounding-box"
          size="sm"
          label="Afficher comme"
          @select="forceShowAs = $event"
        >
        </dropdown-select>
      </div>
    </template>
  </b-alert>
</template>

<style>
.loan-status-alert {
  h4:last-child {
    margin-bottom: 0;
  }
  .action-required-header .badge {
    font-size: 1rem;
  }
}
</style>

<style scoped lang="scss">
.loan-status-alert.alert {
  border-radius: 1rem;
  padding: 1rem;
  border-width: 1px;

  .action-required-header {
    margin-bottom: 1rem;
  }
}
</style>
