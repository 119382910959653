<script>
import IconButton from "@/components/shared/IconButton.vue";
import { del, put } from "@/requests/server";
import dayjs from "dayjs";
import Vue from "vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import SafeImage from "@/components/shared/SafeImage.vue";
import { isGlobalAdmin } from "@/helpers/permissions/users";

export default Vue.extend({
  name: "CommunityUserDetails",
  components: { IconButton, SafeImage, SafeFile },
  props: {
    communityUser: {
      type: Object,
      required: true,
    },
    changingItem: {
      type: Boolean,
      default: false,
    },
    fetchFields: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      approvalNote: null,
      show: false,
      suspending: false,
      unsuspending: false,
      deleting: false,
      approving: false,
      evaluatingProof: false,
      changingSubscription: false,
      grantReason: this.communityUser.current_granted_subscription?.reason,
      subscriptionSectionVisible: false,
    };
  },
  computed: {
    canSeeProof() {
      return (
        this.communityUser.proof_state === "unevaluated" ||
        this.communityUser.proof_state === "invalid" ||
        (isGlobalAdmin(this.$store.state.user) && this.communityUser.proof_state === "valid")
      );
    },
    proofHiddenForPrivacy() {
      return !isGlobalAdmin(this.$store.state.user) && this.communityUser.proof_state === "valid";
    },
    disabled() {
      return (
        this.changingItem ||
        this.suspending ||
        this.approving ||
        this.deleting ||
        this.unsuspending ||
        this.evaluatingProof ||
        this.changingSubscription
      );
    },
    subscriptionExpiresInLessThanAMonth() {
      return (
        !this.communityUser.current_granted_subscription ||
        dayjs(this.communityUser.current_granted_subscription.end_date)
          .startOf("day")
          .diff(dayjs().startOf("day"), "months") < 1
      );
    },
    subscriptionExpiresInLessThanAYear() {
      return (
        !this.communityUser.current_granted_subscription ||
        dayjs(this.communityUser.current_granted_subscription.end_date)
          .startOf("day")
          .diff(dayjs().startOf("day"), "years") < 1
      );
    },
    daysSinceStartOfSubscription() {
      return this.communityUser.current_granted_subscription
        ? dayjs()
            .startOf("day")
            .diff(this.communityUser.current_granted_subscription.start_date, "days")
        : 0;
    },
  },
  mounted() {
    this.$nextTick(() => (this.show = true));
  },
  methods: {
    async grantMonthSubscription() {
      const today = dayjs().startOf("day");
      await this.grantSubscription(
        today.add(1, "month").diff(today, "days") + this.daysSinceStartOfSubscription
      );
    },
    async grantYearSubscription() {
      const today = dayjs().startOf("day");
      await this.grantSubscription(
        today.add(1, "year").diff(today, "days") + this.daysSinceStartOfSubscription
      );
    },
    async grantSubscription(duration) {
      this.changingSubscription = true;
      const { data } = await put(
        "subscriptions/grant",
        {
          user_id: this.communityUser.user.id,
          community_id: this.communityUser.community.id,
          duration_in_days: duration,
          reason: this.grantReason,
        },
        {
          cleanupCallback: () => (this.changingSubscription = false),
          notifications: { action: "octroi du statut de bénévole" },
        }
      );
      Object.assign(this.communityUser, { current_granted_subscription: data });
    },
    async revokeSubscription() {
      this.changingSubscription = true;
      await del(`subscriptions/${this.communityUser.current_granted_subscription.id}`, {
        cleanupCallback: () => (this.changingSubscription = false),
        notifications: { action: "révocation du statut de bénévole" },
      });
      this.grantReason = null;
      Object.assign(this.communityUser, { current_granted_subscription: null });
    },
    async approveUser() {
      this.approving = true;
      const { data } = await put(
        `communityUsers/${this.communityUser.id}/approve`,
        {
          fields: this.fetchFields,
          note: this.approvalNote,
        },
        {
          cleanupCallback: () => (this.approving = false),
          notifications: {
            action: "approbation",
          },
        }
      );
      Object.assign(this.communityUser, data);
    },
    async suspendUser() {
      this.suspending = true;

      const { data } = await put(
        `communityUsers/${this.communityUser.id}/suspend`,
        {
          fields: this.fetchFields,
        },
        {
          cleanupCallback: () => (this.suspending = false),
          notifications: {
            action: "suspension",
          },
        }
      );

      Object.assign(this.communityUser, data);
    },
    async unsuspendUser() {
      this.unsuspending = true;

      const { data } = await put(
        `communityUsers/${this.communityUser.id}/unsuspend`,
        {
          fields: this.fetchFields,
        },
        {
          cleanupCallback: () => (this.unsuspending = false),
          notifications: {
            action: "rétablissement",
          },
        }
      );

      Object.assign(this.communityUser, data);
    },
    async unEvaluateProof() {
      await this.evaluateProof("unevaluated");
    },
    async invalidateProof() {
      await this.evaluateProof("invalid");
    },
    async evaluateProof(evaluation) {
      this.evaluatingProof = true;

      const { data } = await put(
        `communityUsers/${this.communityUser.id}`,
        {
          fields: this.fetchFields,
          proof_evaluation: evaluation,
        },
        {
          cleanupCallback: () => (this.evaluatingProof = false),
          notifications: {
            action: "rétablissement",
          },
        }
      );

      Object.assign(this.communityUser, data);
    },
    async removeUser() {
      this.deleting = true;
      try {
        await this.$store.dispatch(`communities/removeUser`, {
          id: this.communityUser.community.id,
          userId: this.communityUser.user_id,
        });
      } finally {
        this.deleting = false;
      }

      this.$emit("deleted");
    },
  },
});
</script>

<template>
  <div class="user-details-row">
    <b-row>
      <b-col sm="4">
        <dl>
          <dt><span class="sr-only">Photo</span></dt>
          <dd v-if="communityUser.user.avatar">
            <safe-image
              :image="communityUser.user.avatar"
              aspect-ratio="1 / 1"
              class="w-auto user-details-image"
            />
          </dd>
          <dd v-else>Aucune photo.</dd>
        </dl>
      </b-col>
      <b-col sm="8">
        <dl>
          <dt>Courriel</dt>
          <dd>
            {{ communityUser.user.email }}
          </dd>
          <dt>Description</dt>
          <dd>
            {{ communityUser.user.description }}
          </dd>
          <dt>Adresse</dt>
          <dd>
            {{ communityUser.user.address }}
          </dd>
        </dl>
        <hr />

        <div class="mb-3">
          <b-badge v-if="communityUser.proof_state === 'absent'" variant="danger">
            Sans preuves
          </b-badge>
          <b-badge v-else-if="communityUser.proof_state === 'unevaluated'" variant="warning">
            Preuves à évaluer
          </b-badge>
          <b-badge v-else-if="communityUser.proof_state === 'valid'" variant="success">
            Preuves validées
          </b-badge>
          <b-badge v-else-if="communityUser.proof_state === 'invalid'" variant="danger">
            Preuves insuffisantes
          </b-badge>
        </div>

        <dl v-if="canSeeProof" class="mb-3">
          <template v-if="communityUser.community.requires_identity_proof">
            <dt>Preuves d'identité</dt>
            <dd>
              <ul>
                <li v-for="proof in communityUser.user.identity_proof" :key="proof.id">
                  <safe-file :file="proof" />
                </li>
              </ul>
            </dd>
          </template>
          <dt>Preuves de résidence</dt>
          <dd>
            <ul>
              <li v-for="proof in communityUser.user.residency_proof" :key="proof.id">
                <safe-file :file="proof" />
              </li>
            </ul>
          </dd>
        </dl>

        <div v-if="proofHiddenForPrivacy" class="text-muted">
          Les preuves ont été validées précédemment et sont désormais cachées pour respecter les
          données confidentielles des membres.
        </div>

        <icon-button
          v-if="communityUser.proof_state === 'unevaluated'"
          v-b-tooltip.hover="
            'Cette action n\'envoie aucun courriel au participant. Assurez-vous d\'envoyer un courriel manuellement à l\'utilisateur avec la raison du refus.'
          "
          size="sm"
          :disabled="disabled"
          variant="ghost-danger"
          :onclick="invalidateProof"
        >
          Marquer les preuves comme insuffisantes
        </icon-button>
        <icon-button
          v-if="communityUser.proof_state === 'invalid'"
          v-b-tooltip.hover="'Marque la preuve comme étant \'à évaluer\''"
          size="sm"
          :disabled="disabled"
          variant="ghost-secondary"
          :onclick="unEvaluateProof"
        >
          Réinitialiser l'évaluation
        </icon-button>

        <template v-if="communityUser.approved_at && !communityUser.suspended_at">
          <hr />
          <div class="position-relative">
            <dl>
              <dt @click="subscriptionSectionVisible = !subscriptionSectionVisible">
                Statut de gratuité

                <b-icon
                  icon="caret-right-fill"
                  class="collapse-icon"
                  :class="{
                    'collapse-icon-open': subscriptionSectionVisible,
                  }"
                />
                <b-icon
                  v-b-tooltip="
                    'Une gratuité évite au membre de payer les contributions demandées lors des emprunts.'
                  "
                  class="text-muted info-icon"
                  icon="info-circle"
                  scale="0.8"
                />
              </dt>
              <dd>
                <span v-if="communityUser.current_granted_subscription">
                  Gratuité accordée jusqu'au
                  {{ communityUser.current_granted_subscription.end_date | date }}
                  par
                  <b-link
                    :to="`/admin/users/${communityUser.current_granted_subscription.granted_by_user.id}`"
                  >
                    {{
                      communityUser.current_granted_subscription.granted_by_user.full_name
                    }}</b-link
                  >.
                  {{
                    communityUser.current_granted_subscription.reason
                      ? `Raison: ${communityUser.current_granted_subscription.reason}.`
                      : ""
                  }}
                </span>
                <span v-else>Aucune gratuité</span>

                <b-collapse
                  :id="`subscription-${communityUser.id}`"
                  v-model="subscriptionSectionVisible"
                >
                  <div class="font-weight-bold mt-2">
                    {{
                      communityUser.current_granted_subscription
                        ? "Modifier la gratuité"
                        : "Accorder une gratuité"
                    }}
                  </div>
                  <div>
                    <b-form-group class="mb-2" size="sm" label="Raison de la gratuité (optionnel)">
                      <b-input
                        v-model="grantReason"
                        size="sm"
                        placeholder="Ex: Super bénévole, membre du comité, etc."
                      />
                    </b-form-group>
                  </div>
                  <div class="user-details-buttons">
                    <icon-button
                      v-if="subscriptionExpiresInLessThanAMonth"
                      variant="ghost-secondary"
                      size="sm"
                      :disabled="disabled"
                      :onclick="grantMonthSubscription"
                    >
                      Accorder pour un mois
                    </icon-button>
                    <icon-button
                      v-if="subscriptionExpiresInLessThanAYear"
                      variant="ghost-secondary"
                      size="sm"
                      :disabled="disabled"
                      :onclick="grantYearSubscription"
                    >
                      Accorder pour un an
                    </icon-button>
                    <icon-button
                      v-if="communityUser.current_granted_subscription"
                      size="sm"
                      :disabled="disabled"
                      variant="ghost-danger"
                      :onclick="revokeSubscription"
                    >
                      Révoquer
                    </icon-button>
                  </div>
                </b-collapse>
              </dd>
            </dl>
          </div>
        </template>
        <hr />
        <b-form-group
          v-if="!communityUser.approved_at"
          size="sm"
          class="mb-2"
          label="Note d'approbation (optionnel)"
        >
          <b-textarea
            v-model="approvalNote"
            :rows="1"
            :max-rows="3"
            size="sm"
            placeholder="Je l'ai rencontré en personne lors de l'évènement X et j'ai vu sa preuve de résidence."
          />
        </b-form-group>
        <dl v-else-if="communityUser.approval_note || communityUser.approver" class="mb-2">
          <template v-if="communityUser.approver">
            <dt>Approuvé par</dt>
            <dd>
              <b-link :to="`/admin/users/${communityUser.approver.id}`">
                {{ communityUser.approver.full_name }}
              </b-link>
            </dd>
          </template>
          <template v-if="communityUser.approval_note">
            <dt>Note d'approbation</dt>
            <dd>{{ communityUser.approval_note }}</dd>
          </template>
        </dl>

        <div class="user-details-buttons">
          <icon-button
            v-if="!communityUser.approved_at && !communityUser.suspended_at"
            size="sm"
            variant="success"
            :onclick="approveUser"
            :disabled="disabled"
            icon="check2-square"
          >
            {{ $t("communities.fields.user.action_labels.approve") | capitalize }}
          </icon-button>

          <icon-button
            v-if="communityUser.approved_at && !communityUser.suspended_at"
            variant="outline-warning"
            size="sm"
            :onclick="suspendUser"
            :disabled="disabled"
            icon="pause-circle"
          >
            {{ $t("communities.fields.user.action_labels.suspend") | capitalize }}
          </icon-button>

          <icon-button
            v-if="communityUser.suspended_at"
            variant="success"
            size="sm"
            :onclick="unsuspendUser"
            :disabled="disabled"
            icon="play-circle"
          >
            {{ $t("communities.fields.user.action_labels.unsuspend") | capitalize }}
          </icon-button>

          <icon-button role="delete" size="sm" :onclick="removeUser" :disabled="disabled">
            {{ $t("communities.fields.user.action_labels.remove") | capitalize }}
          </icon-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
.b-icon.bi.info-icon {
  position: absolute;
  right: 0;
  // Larger size and padding so it's a better tap target on mobile
  width: 1.5em;
  height: 2em;
  padding: 0 0.25em 1em;
  vertical-align: middle;
}

.collapse-icon {
  transition: 0.3s;

  &.collapse-icon-open {
    transform: rotate(90deg);
  }
}
</style>
