import { get } from "@/requests/server";

let actions = {
  async loadTypes({ commit }) {
    commit("startLoading");
    const { data } = await get("/loanableTypes", {
      cleanupCallback: () => commit("finishLoading"),
    });
    commit("setTypes", data);
  },
};
let mutations = {
  setTypes(state, types) {
    state.types = types;
    state.loaded = true;
  },
  startLoading(state) {
    state.loaded = false;
    state.loading = true;
  },
  finishLoading(state) {
    state.loading = false;
  },
};
export default {
  namespaced: true,
  state: {
    types: [],
    loaded: false,
    loading: false,
  },
  actions,
  mutations,
};
