<template>
  <pricing-table />
</template>

<script>
import PricingTable from "@/components/Pricing/PricingTable.vue";

export default {
  name: "AdminPricings",
  components: {
    PricingTable,
  },
};
</script>

<style lang="scss"></style>
