<template>
  <tr class="invoice-group">
    <td colspan="2">
      <table>
        <tiny-invoice-line
          :label="item.label"
          :subtext="item.subtext"
          :total="show ? null : total"
          :class="{ show: show }"
          class="invoice-group-header"
          @click.native="show = !show"
        >
          <b-icon icon="caret-right-fill"></b-icon>
        </tiny-invoice-line>
      </table>
      <b-collapse v-model="show" class="pl-3">
        <table>
          <tiny-invoice-line
            v-for="(subItem, key) of item.items"
            :key="key"
            :class="subItem.class"
            :label="subItem.label"
            :subtext="subItem.subtext"
            :total="subItem.total"
            :loading="subItem.loading"
          />
        </table>
      </b-collapse>
    </td>
  </tr>
</template>

<script>
import TinyInvoiceLine from "@/components/Invoice/TinyInvoiceLine.vue";

export default {
  name: "TinyInvoiceGroup",
  components: { TinyInvoiceLine },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: this.item.showContents,
    };
  },
  computed: {
    total() {
      return (this.item.items || []).map((i) => i.total).reduce((a, b) => a + b, 0);
    },
  },
};
</script>

<style lang="scss">
.tiny-invoice {
  .invoice-group-header {
    th {
      cursor: pointer;
    }

    .b-icon {
      transition: 0.3s;
    }

    &.show .b-icon {
      transform: rotate(90deg);
    }
  }

  .invoice-group > td {
    padding-bottom: 0;
  }
}
</style>
