<template>
  <div class="admin-loan-page">
    <vue-headful :title="fullTitle" />

    <full-loan :loan-id="id" />
  </div>
</template>

<script>
import FullLoan from "@/components/Loan/FullLoan.vue";
import { capitalize } from "@/helpers/filters";

export default {
  name: "AdminLoan",
  components: {
    FullLoan,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    fullTitle() {
      const parts = [
        "LocoMotion",
        capitalize(this.$i18n.t("titles.admin")),
        capitalize(this.$i18n.tc("loans.model_name", 2)),
      ];

      return parts.reverse().join(" | ");
    },
  },
};
</script>
