import { post } from "@/requests/server";

export default {
  namespaced: true,
  state: {
    id: null,
    code: null,
    communityName: "",
    inviterName: "",
    accountExists: false,
    newlyAcceptedCommunityId: null,
  },
  mutations: {
    setInvitation(state, { id, code, communityName, inviterName, accountExists }) {
      state.id = id;
      state.code = code;
      state.communityName = communityName;
      state.inviterName = inviterName;
      state.accountExists = accountExists;
    },
    clear(state) {
      state.id = null;
      state.code = null;
      state.communityName = "";
      state.inviterName = "";
      state.accountExists = false;
    },
    setNewlyAcceptedCommunityId(state, communityId) {
      state.newlyAcceptedCommunityId = communityId;
    },
  },
  actions: {
    async accept({ state, commit, dispatch }) {
      const { data: acceptedInvitation } = await post(
        `invitations/${state.id}/accept`,
        {
          code: state.code,
        },
        { notifications: { action: "acceptation de l'invitation" } }
      );

      if (acceptedInvitation) {
        await commit("setNewlyAcceptedCommunityId", acceptedInvitation.community_id);
        await commit("clear");
        await dispatch("loadUser", {}, { root: true });
        await commit(
          "addNotification",
          {
            title: "Invitation acceptée!",
            variant: "success",
          },
          { root: true }
        );
      }
    },
    async acceptOnRegistration({ commit, dispatch }) {
      const { data: acceptedInvitation } = await post(`invitations/accept/registration`, {
        notifications: { action: "acceptation d'invitations" },
      });
      if (acceptedInvitation) {
        await commit("setNewlyAcceptedCommunityId", acceptedInvitation.community_id);
        await commit("clear");
        await dispatch("loadUser", {}, { root: true });
        await commit(
          "addNotification",
          {
            title: "Invitation acceptée!",
            variant: "success",
          },
          { root: true }
        );
      }
    },
  },
};
