import RestModule from "../RestModule";

export default new RestModule("invoiceitems", {
  exportFields: [
    "id",
    "invoice.id",
    "invoice.user.id",
    "invoice.user.name",
    "invoice.user.last_name",
    "label",
    "item_type",
    "amount",
    "taxes_tps",
    "taxes_tvq",
    "meta",
  ],
});
