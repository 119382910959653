<script>
import LazyLoad from "@/components/shared/Lazy.vue";

// How many pixels should a user swipe to consider it a valid tab change intent
const swipeSensitivity = 50;
export default {
  name: "TabLayout",
  components: { LazyLoad },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    initialTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTab: this.initialTab,
      prevTab: this.initialTab,
      touchStartX: null,
      touchStartY: null,
      scrollingLeftOnly: false,
      scrollingRightOnly: false,
      tabHeights: {},
    };
  },
  methods: {
    jumpToTab(tabKey) {
      this.activeTab = this.tabs.findIndex((t) => t.key === tabKey);
    },
    onTabActivated(newTabIndex, prevTabIndex) {
      // Save the scroll position for the tab we're leaving
      this.tabHeights[prevTabIndex] = window.scrollY;
      // If we're scrolled below the sticky tab headers, we scroll to the previously saved position
      // or the top of the tab header
      if (this.$refs.container.getBoundingClientRect().top < 0) {
        setTimeout(() => {
          let scrollTo = this.tabHeights[newTabIndex];
          let minScrollHeight = this.$refs.container.getBoundingClientRect().top + window.scrollY;
          if (!scrollTo || scrollTo < minScrollHeight) {
            scrollTo = minScrollHeight;
          }
          window.scrollTo({
            top: scrollTo,
            behavior: "instant",
          });
        }, 17);
      }

      this.$emit("tab-activated", this.tabs[newTabIndex].key);
    },
    touchStart(event) {
      this.scrollingRightOnly = false;
      this.scrollingLeftOnly = false;

      if (!event.touches || event.touches.length < 1) {
        return;
      }

      let element = event.touches[0].target;
      if (!element) {
        return;
      }

      while (
        element &&
        element.parentElement &&
        !element.classList.contains("tab-layout-content")
      ) {
        if (element.scrollWidth > element.clientWidth) {
          if (element.scrollLeft === 0) {
            this.scrollingLeftOnly = true;
          } else if (element.scrollLeft + element.clientWidth === element.scrollWidth) {
            this.scrollingRightOnly = true;
          } else {
            // Cancel scroll: let the scrolling element handle it.
            return;
          }
          break;
        }
        element = element.parentElement;
      }

      this.touchStartX = event.touches[0].clientX;
      this.touchStartY = event.touches[0].clientY;
    },
    touchMove(event) {
      if (!this.touchStartX) return;
      const yDelta = event.changedTouches[0].clientY - this.touchStartY;
      const delta = event.changedTouches[0].clientX - this.touchStartX;

      if ((delta > 0 && this.scrollingRightOnly) || (delta < 0 && this.scrollingLeftOnly)) {
        this.touchStartX = null;
        this.touchStartY = null;
        return;
      }

      // if user has scrolled vertically a bit, do not change tab
      if (Math.abs(yDelta) > swipeSensitivity) {
        return;
      }

      if (delta < -swipeSensitivity) {
        this.touchStartX = null;
        this.touchStartY = null;
        this.prevTab = this.activeTab;
        this.activeTab++;
        return;
      }

      if (delta > swipeSensitivity) {
        this.touchStartX = null;
        this.touchStartY = null;
        this.prevTab = this.activeTab;
        this.activeTab--;
      }
    },
  },
};
</script>

<template>
  <div ref="container" class="tab-layout" :class="{ 'from-left': prevTab < activeTab }">
    <b-tabs
      ref="tabs"
      nav-wrapper-class="sticky-when-small"
      :value="activeTab"
      @activate-tab="onTabActivated"
      @input="
        (e) => {
          if (e !== activeTab) {
            prevTab = activeTab;
            activeTab = e;
          }
        }
      "
    >
      <b-tab v-for="(tab, index) in tabs" :key="tab.key">
        <template #title>
          <slot :name="`icon-${tab.key}`">
            <b-icon v-if="activeTab === index" :icon="tab.activeIcon ? tab.activeIcon : tab.icon" />
            <b-icon v-else :icon="tab.icon" />
          </slot>
          <b-badge v-if="tab.badge" class="tab-badge" variant="warning">{{ tab.badge }}</b-badge>
          {{ tab.label | capitalize }}
        </template>
        <lazy-load v-if="tab.lazy">
          <div class="tab-layout-content" @touchstart="touchStart" @touchmove="touchMove">
            <slot :name="`tab-${tab.key}`"> </slot>
          </div>
        </lazy-load>
        <div v-else class="tab-layout-content" @touchstart="touchStart" @touchmove="touchMove">
          <slot :name="`tab-${tab.key}`"> </slot>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";

.tab-layout {
  .tab-pane.fade {
    transition: all 0.15s;
  }
  &.from-left .tab-pane.fade:not(.show) {
    transform: translateX(2rem);
  }
  &:not(.from-left) .tab-pane.fade:not(.show) {
    transform: translateX(-2rem);
  }

  @include media-breakpoint-down(md) {
    .sticky-when-small {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 100;
      margin: 0 -15px 0;
      background: white;
      padding-bottom: 1px;
      box-shadow: $small-shadow;
      .nav-tabs {
        gap: 0;
      }

      .nav-item {
        flex: 1;
      }
    }
  }

  .nav-tabs {
    display: flex;
    gap: 1rem;
    border: none;
    .nav-item {
      background: transparent;
    }

    .nav-link {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      font-size: 0.8rem;
      background: transparent;
      border: 1px solid transparent;
      color: $dark;
      fill: $dark;
      border-radius: 1rem;
      transition: 0.3s;
      position: relative;

      .tab-badge {
        position: absolute;
        top: 0;
        left: 55%;
      }

      &:hover {
        color: $locomotion-dark-green;
        fill: $locomotion-dark-green;
        border: 1px solid transparent;
      }
      &.active {
        color: $primary;
        fill: $primary;
        border: 1px solid transparent;
      }

      @include media-breakpoint-up(lg) {
        min-width: 6rem;
        &.active {
          background: #e5f8f6;
          border: 1px solid $primary;
        }
      }

      > svg {
        height: 1.5rem;
        width: 100%;
      }
    }
  }
}
</style>
