import CommunityOverview from "@/views/community/Overview.vue";

export default [
  {
    path: "/communities",
    name: "communities-overview",
    component: CommunityOverview,
    meta: {
      slug: "communities",
      title: "titles.communities_overview",
    },
  },
];
