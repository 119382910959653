<template>
  <div
    :class="{
      'layout-loading': true,
      'inline-with-buttons': withButton,
      'inline-with-text': withText,
      'full-height': fullHeight,
      overlay: overlay,
    }"
  >
    <img src="/loading.svg" />
  </div>
</template>

<script>
export default {
  name: "LayoutLoading",
  props: {
    withButton: {
      type: Boolean,
      default: false,
    },
    withText: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
/* Helper class to create a container for the overlay. */
.layout-loading-overlay-container {
  position: relative;
}
.layout-loading {
  display: flex;
  justify-content: center;
  text-align: center;
  &.inline-with-buttons {
    display: inline-block;
    img {
      height: 30px;
      width: 30px;
    }
  }
  &.inline-with-text {
    display: inline-block;
    img {
      height: 1em;
      width: 1em;
      vertical-align: bottom;
    }
  }
  &.full-height {
    height: 100%;
    img {
      height: 100%;
    }
  }
  &.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
