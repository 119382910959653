export default {
  fr: {
    current_email: "Courriel actuel",
    email_change_modal: {
      title: "Courriel mis à jour",
      content: `
      <p>Vous pouvez maintenant vous connecter avec votre nouveau courriel.</p>
      `,
    },
    password_change_modal: {
      title: "Mot de passe mis à jour",
      content: `<p>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>`,
    },
  },
};
