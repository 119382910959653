var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('paginated-table',_vm._b({ref:"incidentsTable",staticClass:"mt-4",attrs:{"label":"Incidents","fetch-params":_vm.fetchParams,"endpoint":"incidents","columns":_vm.columns,"extra-data":[
      'loanable',
      'notes.author.avatar',
      'assignee',
      'reported_by_user.avatar',
      'resolved_by_user.avatar',
    ],"initial-filters":_vm.initialFilters,"has-row-details":!_vm.hideDetails,"editable-items":"","show-action-column":!_vm.hideDetails,"show-generate-csv":false},scopedSlots:_vm._u([{key:"row-details",fn:function({ item, updateItem }){return [(item.details_hidden)?_c('div',{staticClass:"font-italic"},[_vm._v(" Vous n'avez pas accès aux détails de l'incident. ")]):_c('incident-form',{attrs:{"incident":item,"loanable":item.loanable},on:{"update":updateItem}})]}},{key:"head-buttons",fn:function(){return [_vm._t("head-buttons")]},proxy:true}],null,true)},'paginated-table',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }