<template>
  <div v-if="itemLoaded && routeDataLoaded" class="profile-communities">
    <b-card v-if="item.communities.length > 0" class="profile-communities__communities">
      <proof-form :user="$store.state.users.item" :loading="loading" @submit="submit" />
    </b-card>

    <b-card v-else class="profile-communities__nocommunities">
      <div>
        <h3>Vous n'êtes pas encore inscrit au sein d'un quartier soutenu par LocoMotion.</h3>
      </div>
      <b-card-body>
        <p>
          Il se peut que LocoMotion ne soit pas encore ouvert dans votre quartier ou que votre
          adresse n'est pas à jour.
        </p>
        <icon-button variant="primary" to="/profile/locomotion"> Modifier mon adresse </icon-button>
      </b-card-body>
    </b-card>
  </div>
  <layout-loading v-else />
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import ProofForm from "@/components/User/ProofForm.vue";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import LayoutLoading from "@/components/Layout/Loading.vue";

export default {
  name: "ProfileCommunities",
  components: { IconButton, LayoutLoading, ProofForm },
  mixins: [DataRouteGuards, FormMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: "me",
    },
  },
  data() {
    return {
      savingToast: {
        content: "Un membre de notre équipe va vérifier vos preuves et valider votre profil.",
        title: "Preuve d'adresse mise à jour!",
        variant: "success",
      },
    };
  },
  methods: {
    afterSubmit() {
      this.$router.push("/app");
    },
  },
};
</script>

<style lang="scss">
.profile-communities {
  &__headers {
    padding-left: 15px;
    h4 {
      color: grey;
      font-size: 16px;
    }
  }
  &__communities {
    box-shadow: $small-shadow;
  }
  &__nocommunities {
    .btn {
      margin-left: 0;
    }
  }
}
</style>
