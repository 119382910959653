<template>
  <loan-status-alert-container :loan="loan">
    <template v-if="loan.loanable.type === 'car'" #borrower>
      <h4 class="mt-0">
        {{ $t("insurance_warning.title") }}
      </h4>

      <i18n path="insurance_warning.warning" tag="p">
        <template #link>
          <a :href="$t('insurance_warning.terms_link')" target="_blank">{{
            $t("insurance_warning.terms")
          }}</a>
        </template>
        <template #emphasis>
          <strong>
            {{ $t("insurance_warning.car_stays_in_quebec") }}
          </strong>
        </template>
      </i18n>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import { isBorrower } from "@/helpers/permissions/loans";
import locales from "@/locales";

export default {
  name: "NewLoan",
  components: {
    LoanStatusAlertContainer,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isBorrower() {
      return isBorrower(this.user, this.loan);
    },
  },
  methods: {},
  i18n: {
    messages: {
      en: {
        ...locales.en.loans,
      },
      fr: {
        ...locales.fr.loans,
      },
    },
  },
};
</script>

<style lang="scss"></style>
