<template>
  <loan-status-alert-container :loan="loan">
    <template #borrower
      >Cet emprunt a été annulé. Les personnes responsables ont été avisées, mais n'hésitez pas à
      les contacter pour vous assurer qu'ils ou elles soient bien au courant.
    </template>

    <template #owner
      >Cet emprunt a été annulé. {{ loan.borrower_user.name }} a été avisé-e, mais n'hésitez pas à
      le-la contacter directement pour vous assurer qu'il ou elle soit bien au courant.</template
    >

    <template #admin>Cet emprunt a été annulé.</template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";

export default {
  name: "LoanCanceled",
  components: {
    LoanStatusAlertContainer,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
