import { isGlobalAdmin } from "@/helpers/permissions/users";

import store from "@/store";
import ConditionsPage from "@/views/ConditionsPage.vue";
import UserDashboard from "@/views/Dashboard.vue";
import MainFAQ from "@/views/Faq.vue";
import HelpPage from "@/views/Help.vue";
import InsuranceOverview from "@/views/Insurance.vue";

export default [
  {
    path: "/",
    beforeEnter(to, from, next) {
      if (!store.state.user) {
        next("/login");
        return;
      }

      if (isGlobalAdmin(store.state.user)) {
        next("/admin/dashboard");
        return;
      }

      next("/app");
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: ConditionsPage,
    meta: {
      title: "titles.privacy",
    },
  },
  {
    path: "/conditions",
    name: "conditions",
    component: ConditionsPage,
    meta: {
      title: "titles.privacy",
    },
  },
  {
    path: "/assurances-desjardins",
    name: "insurance",
    component: InsuranceOverview,
    meta: {
      title: "titles.insurance",
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: MainFAQ,
    meta: {
      title: "titles.faq",
    },
  },
  {
    path: "/app",
    name: "dashboard",
    component: UserDashboard,
    meta: {
      auth: true,
      title: "titles.dashboard",
    },
  },
  {
    path: "/help",
    name: "help",
    component: HelpPage,
    meta: {
      title: "Aide de LocoMotion",
    },
  },
];
