import AdminFilters from "@/locales/components/Admin/Filters";
import LoginBox from "@/locales/components/Login/Box";
import RegisterForm from "@/locales/components/Register/RegisterForm";
import Dashboard from "@/locales/views/Dashboard";
import communities from "@/locales/models/communities";
import loanables from "@/locales/models/loanables";

export default {
  communities: communities.en,
  components: {
    admin: {
      filters: AdminFilters.en,
    },
    login: {
      box: LoginBox.en,
    },
    register: {
      register_form: RegisterForm.en,
    },
  },
  views: {
    dashboard: Dashboard.en,
  },
  forms: {
    actions: "actions",
    archive: "archive",
    details: "details",
    edit: "modify",
    restore: "restore",
    delete: "delete",
    view: "view",
  },
  lists: {
    // Generic label for list ids.
    id: "ID",
  },
  loanables: loanables.en,
  locales: {
    en: "English",
    fr: "Français",
  },
  profile: {
    titles: {
      account: "Account informations",
      payment: "Payment",
      reservations: "Reservations",
      vehicles: "Vehicles",
    },
  },
  titles: {
    community: "community | communities",
    dashboard: "dashboard",
    login: "login",
    account: "Account",
  },
};
