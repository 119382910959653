/* eslint-disable */
export default {
  fr: {
    title: "",
    sections: [
      {
        title: "Emprunter&nbsp;: auto, vélo, remorque à vélo",
        questions: [
          {
            title: "Comment faire une réservation?",
            content:
              "<p>Il n’y a pas d’application à télécharger, locomotion.app est simplement un site web et vous y êtes! Il fonctionne à l’aide d’un navigateur (Firefox, Chrome, Safari…) sur votre ordinateur ou votre téléphone. Vous pouvez ajouter un raccourci sur votre téléphone intelligent, ça aura l’air d’une app!</p> <p>Il y a deux affichages possibles&nbsp;: carte ou liste.</p><p>Indiquez la période de réservation souhaitée puis cliquez sur Rechercher. Les véhicules disponibles affichent alors le bouton &laquo;&nbsp;Demande d’emprunt&nbsp;&raquo;.</p><p>Si le véhicule est collectif, complétez les étapes jusqu'à ce que s'affichent les informations avant de partir, et il est réservé pour vous!</p><p>Si le véhicule appartient à une personne, contactez-la! Texto, appel... Profitez-en pour faire connaissance. Si son véhicule est disponible, la personne acceptera votre demande sur locomotion.app. Vous pourrez alors prépayer l’emprunt et poursuivre le processus sur locomotion.app jusqu’à la fin de l’emprunt.</p>",
          },
          {
            title: "Pourquoi je ne vois pas les autos, les vélos ou les remorques?",
            content:
              "<p>Il faut d’abord que votre compte soit validé par votre comité avant de pouvoir voir la carte avec les véhicules de votre communauté en cliquant sur l’onglet «&nbsp;Emprunter un véhicule&nbsp;».</p> <p>Il est possible que votre communauté ne permette pas le partage d'un certain type de véhicule pour l'instant.</p> <p>Pour emprunter une auto, il est nécessaire de remplir votre dossier de conduite dans votre profil avant de pouvoir emprunter. Si vous ne voyez pas cette option, c'est que votre communauté ne permet pas encore le partage d'autos.</p>",
          },
        ],
      },
      {
        title: "Remorques à vélo et attache-remorque",
        questions: [
          {
            title: "Les remorques sont disponibles durant quelle période?",
            content:
              "<p>La plupart sont disponibles de la mi-avril à la mi-novembre. Une remorque peut rester disponible pour l’hiver si on trouve un endroit pour l’installer sur un espace privé et qu’une personne se porte volontaire pour la déneiger!</p>",
          },
          {
            title: "Comment attacher la remorque à mon vélo?",
            content:
              "<p>Il existe 2 types de remorques en partage selon les quartiers&nbsp;: les «&nbsp;Wike&nbsp;» (remorques conçues et fabriquées par un manufacturier en Ontario) et les «&nbsp;LocoMobile&nbsp;» (remorques artisanales fabriquées dans des ateliers communautaires de Montréal par des participants).</p><p>Pour les remorques artisanales «&nbsp;LocoMobile&nbsp;» il n’y a pas d’attache à installer sur votre vélo car la remorque se fixe directement sur la tige de selle. La tige de selle et le porte-bagage doivent être dégagés pour pouvoir fixer le bras d’attache.</p><p>Pour les remorques «&nbsp;Wike&nbsp;», vous pouvez vous procurer une attache-remorque à installer sur votre vélo dans un des points de chute du quartier.</p><p>Tous les détails se trouvent dans votre guide de départ. Accédez à votre guide de départ  à partir de la section «&nbsp;Ressources&nbsp;» du tableau de bord.</p>",
          },
        ],
      },
      {
        title: "Emprunter une auto",
        carOnly: true,
        questions: [
          {
            title: "Qui peut emprunter une auto?",
            content:
              '<p>Il faut avoir au moins 21 ans et avoir complété son dossier de conduite sur locomotion.app (via Mon profil). Deux documents sont nécessaires&nbsp;:<ul><li><a href="https://saaq.gouv.qc.ca/permis-conduire/obtenir-dossier-conduite/" target="_blank">Dossier de conduite de la SAAQ</a></li><li><a href="https://mondossier.gaa.qc.ca/fr/DemandeWeb/DemandeReleve" target="_blank">Relevé de dossier de sinistres du GAA</a></li></ul><p>Votre dossier sera ensuite approuvé par notre équipe et vous pourrez commencer à faire des demandes d’emprunt pour les autos de vos voisin-e-s!</p>',
          },
          {
            title: "Ça fonctionne avec les permis de conduire étrangers?",
            content:
              "<p>Non, malheureusement. Pour conduire une auto en partage avec LocoMotion, vous avez besoin d’un permis québécois.</p>",
          },
          {
            title: "Combien ça coûte?",
            content:
              '<p>Pour que ça soit juste, on contribue&nbsp;:</p><ul><li>aux coûts de possession de l’auto (dépréciation, frais d’intérêt) selon la durée du trajet;</li><li>aux coûts d\'utilisation (entretien, essence, etc.) selon la distance parcourue.</li></ul><p>À ça s’ajoute l’assurance.</p><p>En gros, la formule ressemble à&nbsp;:</p><blockquote style="text-align: center;">Coût d’un emprunt = distance + durée + assurance</blockquote><p>Il y a deux catégories d’auto, qui correspondent à des coûts différents. Pour le détail des coûts, voir la <a href="https://info.locomotion.app/tarifs-autopartage/" target="_blank">tarification LocoMotion</a>.</p><p>Tous autres frais encourus lors d’un emprunt sont à la charge de l’emprunteur-se (ex.&nbsp;: pont payant, stationnement non payé, etc.)</p>',
          },
          {
            title: "Comment payer l’emprunt d’une auto?",
            content:
              "<p>Il faut payer l’emprunt avant de partir avec une auto. Approvisionnez votre compte via le tableau de bord ou faites-le via la page de votre emprunt (après acceptation de la demande d’emprunt de l’auto par son propriétaire).</p><p>Ce prépaiement est une estimation du coût, le montant exact sera pris de votre compte LocoMotion après l’emprunt (kilomètres parcourus réels, achat de carburant, etc.).</p>",
          },
          {
            title: "Partir avec l’auto d’un-e voisin-e",
            content:
              "<p>Une fois la réservation acceptée, c’est la responsabilité de la personne qui emprunte de s’arranger avec le ou la propriétaire sur un lieu et une heure pour récupérer les clés de l’auto.</p>",
          },
          {
            title: "IMPORTANT - Vous avez 48 h pour entrer les infos finales (km, essence)",
            content:
              "<p>Une fois de retour, il est très important de mettre à jour le km parcouru et vos dépenses en essence. Sans ces informations, il sera difficile de donner sa juste part au ou à la propriétaire de l’auto.</p>",
          },
          {
            title: "Modifier les infos d’un emprunt passé  (km départ, km retour, achat carburant)",
            content:
              '<p>Il n’est pas possible de modifier les informations une fois l’emprunt terminé. Si vous vous rendez compte d’une erreur après que le paiement a été fait, il faudra calculer quel montant est dû (<a href="https://docs.google.com/spreadsheets/d/1zUpzATu-hy8YRrMSXwXDCDx4RpOAiR5j5hhMK63JTIs/edit#gid=1708416944" target="_blank">le calculateur</a> peut vous aider) et faire un échange d\'argent entre vous. Nous pouvons vous aider à résoudre la situation. Contactez-nous&nbsp;: <a href="mailto:info@locomotion.app">info@locomotion.app</a>.</p>',
          },
        ],
      },
      {
        title: "Retard, annulation et frais supplémentaires",
        questions: [
          {
            title: "Je suis en retard pour le retour d’un véhicule",
            content:
              '<p>Par respect pour les autres, évitons les retards. :-) Mais évidemment, ce genre de choses peut arriver.</p><p style="text-decoration: underline;">S’il s’agit de l’auto d’un-e voisin-e</p><p>Avertissez directement le ou la propriétaire du véhicule. Ensuite, vous pourrez indiquer l’heure réelle de votre retour en cliquant sur le bouton de la page de l’emprunt.</p><p style="text-decoration: underline;">S’il s’agit d’un véhicule collectif</p><p>Dès que possible, allez sur la page de l’emprunt de locomotion.app. Utilisez le bouton &laquo;&nbsp;Signaler un retard&nbsp;&raquo; pour obtenir les coordonnées de la prochaine personne qui a réservé le véhicule. Vous devez la contacter si vous empiétez sur sa réservation. Indiquez votre heure réelle de retour.</p>',
          },
          {
            title: "J'ai annulé ma réservation d’auto, comment puis-je me faire rembourser?",
            carOnly: true,
            content:
              "<p>Si vous annulez votre réservation, l'argent est simplement remis sur votre compte LocoMotion. Vous pouvez retirer cet argent ou l’utiliser sur un prochain trajet. L'info sur l’argent disponible dans votre compte LocoMotion se retrouve sur le tableau de bord (solde).</p><p>Si vous annulez à moins de 24 heures de préavis ou vous ne vous présentez pas pour emprunter l’auto, vous paierez l’assurance.</p>",
          },
        ],
      },
      {
        title: "Prêter mon auto",
        carOnly: true,
        questions: [
          {
            title: "Partager mon auto, comment ça marche?",
            content:
              "<p>Une personne au compte LocoMotion validé (pièces d’identité et dossier de conduite validé) vous fera une demande. Vous pourrez ajuster les disponibilités de votre auto. Comme propriétaire d’une auto en partage, vous avez toujours le choix d’accepter ou non une demande de réservation. Aussi, en apprenant à connaître vos voisin-e-s, il est plus facile de se faire confiance. Vous recevrez une contribution financière de votre voisin-e. Et enfin, n’oubliez pas qu’il y a une assurance pour couvrir les trajets!</p>",
          },
          {
            title: "Quels sont les critères pour partager mon auto?",
            content:
              "<p>Votre auto doit avoir une valeur à neuf maximale de 100&nbsp;000&nbsp;$. Vous devrez aussi aviser votre assureur (lettre fournie).</p>",
          },
          {
            title: "Combien je reçois lorsque je prête mon auto?",
            content:
              '<p>Consultez le document sur la <a href="https://info.locomotion.app/tarifs-autopartage/" target="_blank">tarification LocoMotion</a>.</p>',
          },
          {
            title: "Vérifier un prêt d'auto terminé (km, carburant, etc.)",
            content:
              "<p>À la fin d’un prêt d’auto, il est important de vérifier les informations entrées par votre voisin-e dans les 48&nbsp;h. Après ce délai, il ne sera plus possible de contester en cas d’erreur. Pour vérifier, aller sur la page de l’emprunt, via le menu à droite du tableau de bord (&laquo;&nbsp;voir tous les trajets&nbsp;&raquo;) ou via votre profil (&laquo;&nbsp;Historiques des emprunts&nbsp;&raquo;).</p>",
          },
          {
            title: "Annuler un prêt",
            content:
              "<p>Si vous devez annuler le partage de votre auto pour un trajet, contactez rapidement la personne qui pensait pouvoir emprunter votre auto. Elle devra se réorganiser rapidement. Ensuite, annulez l’emprunt sur locomotion.app. La page de l’emprunt est accessible via le tableau de bord, section &laquo;&nbsp;Emprunts en cours&nbsp;&raquo;.</p>",
          },
          {
            title: "Récupérer mon argent",
            content:
              "<p>Une fois le trajet terminé, vous recevrez l’argent du trajet sur votre compte LocoMotion. Dès que vous avez un minimum de 10&nbsp;$ sur votre compte, vous pouvez le récupérer en cliquant sur &laquo;&nbsp;Réclamer&nbsp;&raquo; (bouton sous l’information de votre solde, sur le tableau de bord).</p>",
          },
          {
            title:
              "Quoi faire si je reçois une facture liée à l’emprunt de mon auto par un-e voisin-e?",
            content:
              "<p>Si vous recevez une demande de paiement due à l'utilisation de votre auto par un-e voisin-e (pont payant, contravention, etc.), faites le paiement puis demandez le remboursement à l'emprunteur-se. Pour ce faire, soit vous contactez directement la personne, soit vous demandez à LocoMotion de faire l'intermédiaire en écrivant à <a href=\"mailto:info@locomotion.app\">info@locomotion.app</a>. Le remboursement se fera via un virement interac entre les deux personnes.</p>",
          },
        ],
      },
      {
        title: "Assurances pour les emprunts d’auto",
        carOnly: true,
        questions: [
          {
            title:
              "Je partage mon auto, est-ce que c’est mon assurance qui est considérée lors d'un accident?",
            content:
              '<p>Non, lorsque vous prêtez votre auto avec LocoMotion, c’est l’assurance Desjardins qui protège votre auto. Vous devez envoyer <a href="https://drive.google.com/file/d/1UPfFkgMRKajgYUtTG9LfpqVqvu75ML_h/view" target="_blank">cette lettre</a> à votre assureur ou courtier d’assurance pour l’aviser que vous participez à LocoMotion (sauf si c’est Desjardins ou La Personnelle, directement). Ça n’a pas d’incidence sur votre contrat.</p>',
          },
          {
            title: "J’emprunte l’auto d’un-e voisin-e, comment suis-je assuré-e?",
            content:
              "<p>Pas besoin de votre assurance personnelle (maison ou auto), dès que vous empruntez une auto avec LocoMotion, c'est notre assurance Desjardins qui couvre votre trajet.</p>",
          },
          {
            title: "Que se passe-t-il en cas de panne?",
            content:
              "<p>L’assurance Desjardins couvre les accidents mais pas les pannes. En cas de panne, le remorquage est aux frais de l’emprunteur-se et la réparation est aux frais du ou de la propriétaire.</p>",
          },
          {
            title: "Quoi faire en cas d’accident ou vandalisme lors d’un emprunt d’auto?",
            content:
              "<p>La procédure à suivre en cas d'accident ou vandalisme lors d’un emprunt d’auto est détaillée dans le document &laquo;&nbsp;<a href=\"https://drive.google.com/file/d/1755OkK35_aph-ol9LpB3BJ2JCnkvkIp1/view\" target=\"_blank\">En cas d'accident</a>&nbsp;&raquo;. Elle est également accessible via le carnet de bord de l'auto et via la page de l'emprunt.</p>",
          },
        ],
      },
      {
        title: "Compte LocoMotion, facturation et paiement",
        questions: [
          {
            title: "Comment ça marche?",
            content:
              "<p>Les transactions monétaires sur locomotion.app se font par l’intermédiaire de votre compte LocoMotion. Le solde de votre compte est visible en tout temps sur le Tableau de bord.</p><p>Votre solde augmente lorsque&nbsp;:</p><ul><li>Vous approvisionnez votre compte avec votre carte de crédit;</li><li>Vous prêtez votre auto&nbsp;: l’argent de l’emprunteur-se va sur votre compte LocoMotion.</li></ul><p>Votre solde diminue lorsque&nbsp;:</p><ul><li>Vous payez un emprunt (il faut avoir clôturé votre emprunt pour que la transaction se fasse);</li><li>Vous réclamez votre solde. L’argent quitte votre compte LocoMotion et est transféré sur votre compte bancaire.</li></ul>",
          },
          {
            title: "Approvisionner mon compte LocoMotion",
            content:
              "<p>Vous avez besoin d’approvisionner votre compte LocoMotion pour payer les emprunts d’auto et les contributions volontaires. Vous pouvez approvisionner votre compte via le tableau de bord (bouton &laquo;&nbsp;Approvisionner&nbsp;&raquo; sous l’information de votre solde) ou le faire via lors de l’emprunt.</p><p>Des frais de transactions sont prélevés (par Stripe), soit  2,2&nbsp;% + 0,30&nbsp;$. Ainsi, si vous mettez 100&nbsp;$ sur votre compte LocoMotion, c’est 102,50&nbsp;$ qui seront facturés à votre carte de crédit. Vous pouvez réduire les frais de transaction en approvisionnant votre compte avec des montants plus élevés plutôt qu’en payant le montant exact à chaque emprunt.</p>",
          },
          {
            title: "Réclamer l’argent qui est sur mon compte LocoMotion",
            content:
              "<p>Vous pouvez réclamer l’argent qui est sur votre compte en cliquant sur le bouton &laquo;&nbsp;Réclamer&nbsp;&raquo; sur le tableau de bord (sous l’information de votre solde). Un montant minimal de 10&nbsp;$ est requis.</p>",
          },
          {
            title: "Compte bancaire, carte de crédit : quelle différence?",
            content:
              "<p>Sur locomotion.app, la carte de crédit sert à mettre de l’argent sur votre compte LocoMotion et votre compte bancaire sert à récupérer votre argent (si un-e voisin-e vous a emprunté votre auto, par exemple).</p>",
          },
          {
            title: "Comment puis-je faire le suivi de mes paiements?",
            content:
              "<p>Vous trouverez vos factures dans &laquo;&nbsp;Mon profil&nbsp;&raquo;. Vous recevez une facture à chaque fois que votre solde LocoMotion change&nbsp;:</p><ul><li>pour chaque trajet (prêt ou emprunt), ou</li><li>quand vous approvisionnez ou réclamez l’argent de votre compte LocoMotion.</li></ul>",
          },
          {
            title: "Pourquoi des contributions volontaires?",
            content:
              "<p>Pour le moment, les coûts de fonctionnement de la plateforme ne sont pas facturés aux participant-e-s car nous sommes encore en phase pilote. Nous testons notamment comment couvrir ces frais et la contribution volontaire est la piste que nous explorons pour le moment!</p>",
          },
        ],
      },
      {
        title: "Protection de la vie privée",
        questions: [
          {
            title:
              "Je partage ma photo, mes infos… est-ce que mes données personnelles sont protégées?",
            content:
              '<p>Vos données personnelles, on en prend soin. Voir notre <a href="/politique-confidentialite.html">politique de confidentialité</a>.</p>',
          },
        ],
      },
    ],
  },
};
