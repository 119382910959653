export default {
  fr: {
    fields: {
      bike_type: "type",
      id: "ID",
      model: "modèle",
      size: "taille",
    },
  },
};
