<script>
import Vue from "vue";

export default Vue.extend({
  name: "ToggleLink",
  props: {
    collapseId: {
      type: String,
      required: true,
    },
    initiallyOpen: {
      type: Boolean,
      default: false,
    },
    noToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.initiallyOpen,
    };
  },
});
</script>

<template>
  <span v-if="noToggle"><slot /></span>
  <span v-else v-b-toggle="collapseId" class="toggle-link">
    <slot />&nbsp;<b-icon icon="caret-right-fill"></b-icon
  ></span>
</template>

<style scoped lang="scss">
.toggle-link {
  &:hover {
    text-decoration: underline;
  }
  .b-icon {
    transition: 0.3s;
  }
  &.not-collapsed .b-icon {
    transform: rotate(90deg);
  }
}
</style>
