<template>
  <loan-status-alert-container
    :loan="loan"
    :borrower-action-required="needsMoreInformation || needsBorrorwerValidation"
    :owner-action-required="needsMoreInformation || needsOwnerValidation"
  >
    <template #borrower>
      <p v-if="needsMoreInformation">
        Veuillez entrer les
        <span class="text-nowrap"
          ><icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'info')"
            >informations manquantes</icon-button
          >.</span
        >
      </p>
      <p v-else-if="needsBorrorwerValidation">
        Vous avez jusqu'au {{ validationLimit | datetime }} pour modifier ou valider les
        <icon-button
          variant="ghost-secondary"
          size="inline"
          @click="$emit('jump', { target: 'info', open: false })"
          >informations</icon-button
        >.
      </p>
      <p v-else-if="needsValidation">
        Une personne responsable du véhicule doit valider les informations que vous avez fournies.
        <template v-if="borrowerMustAddToBalance"
          >Vous pouvez dès maintenant
          <icon-button
            variant="ghost-secondary"
            size="inline"
            @click="$emit('jump', 'contribution')"
            >ajouter</icon-button
          >
          le montant nécessaire à votre solde afin que le paiement s'effectue automatiquement
          lorsque validé.</template
        >
      </p>
      <template v-else>
        <p>État inattendu&nbsp;: aucune validation nécessaire!</p>

        <icon-button variant="ghost-secondary" size="sm" :onclick="validateToFixInvalidState"
          >Continuer</icon-button
        >
      </template>

      <p v-if="needsMoreInformation">
        Si vous avez besoin de plus de temps avec le véhicule, demandez une
        <span class="text-nowrap">
          <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'dates')"
            >prolongation</icon-button
          >.</span
        >
      </p>
    </template>

    <template #owner>
      <p v-if="needsMoreInformation">
        Veuillez entrer les
        <span class="text-nowrap">
          <icon-button variant="ghost-secondary" size="inline" @click="$emit('jump', 'info')"
            >informations manquantes</icon-button
          >.</span
        >
      </p>
      <p v-else-if="needsOwnerValidation">
        Vous avez jusqu'au {{ validationLimit | datetime }} pour modifier ou valider les
        <span class="text-nowrap">
          <icon-button
            variant="ghost-secondary"
            size="inline"
            @click="$emit('jump', { target: 'info', open: false })"
            >informations</icon-button
          >.</span
        >
      </p>
      <p v-else-if="needsValidation">
        {{ loan.borrower_user.name }} doit valider les informations que vous avez fournies.
      </p>
      <template v-else>
        <p>État inattendu&nbsp;: aucune validation nécessaire!</p>

        <icon-button variant="ghost-secondary" size="sm" :onclick="validateToFixInvalidState"
          >Continuer</icon-button
        >
      </template>
    </template>
    <template #admin>
      <p v-if="needsMoreInformation">
        Les participants doivent ajouter les informations manquantes (kilométrage, dépenses).
      </p>
      <p v-else-if="needsBorrorwerValidation || needsOwnerValidation">
        En attente de la validation par
        <template v-if="needsBorrorwerValidation">{{ loan.borrower_user.full_name }}</template>
        <template v-if="needsBorrorwerValidation && needsOwnerValidation"> et </template>
        <template v-if="needsOwnerValidation">une personne responsable</template>.
      </p>
      <template v-else>
        <p>État inattendu&nbsp;: aucune validation nécessaire!</p>

        <icon-button variant="ghost-secondary" size="sm" :onclick="validateToFixInvalidState"
          >Corriger</icon-button
        >
      </template>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { isCoownerOrOwner } from "@/helpers/permissions/loanables";
import { isBorrower } from "@/helpers/permissions/loans";
import { put } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "LoanEnded",
  components: {
    IconButton,
    LoanStatusAlertContainer,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isBorrower() {
      return isBorrower(this.$store.state.user, this.loan);
    },
    isCoownerOrOwner() {
      return isCoownerOrOwner(this.$store.state.user, this.loan.loanable);
    },
    needsMoreInformation() {
      return (
        this.loan.requires_detailed_mileage &&
        (this.loan.mileage_start === null || this.loan.mileage_end === null)
      );
    },
    needsValidation() {
      return this.loan.needs_validation;
    },

    needsOwnerValidation() {
      return this.needsValidation && !this.loan.owner_validated_at;
    },
    needsBorrorwerValidation() {
      // Or if user has modified tip!
      return this.needsValidation && !this.loan.borrower_validated_at;
    },
    borrowerMustAddToBalance() {
      return this.$store.state.user.balance < -this.loan.borrower_invoice.user_balance_change;
    },
    validationLimit() {
      if (this.loan.attempt_autocomplete_at) {
        return this.loan.attempt_autocomplete_at;
      }

      return dayjs(this.loan.actual_return_at).add(2, "days");
    },
  },
  methods: {
    async validateToFixInvalidState() {
      const { data } = await put(
        `/loans/${this.loan.id}/validate`,
        {},
        {
          notifications: { action: "correction du statut de l'emprunt" },
        }
      );
      this.$emit("input", data);
    },
  },
};
</script>

<style lang="scss"></style>
