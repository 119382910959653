<template>
  <div class="dashboard-resources-list">
    <h3 class="dashboard-h3">Ressources</h3>

    <ul class="dashboard-resources-list__list">
      <li v-if="inCarLoaningCommunity" class="dashboard-resources-list__item">
        <router-link to="/assurances-desjardins" class="dashboard-resources-list__item-header">
          <img class="dashboard-resources-list__item-image" src="/icons/Assurance_vecteur.svg" />

          <div class="dashboard-resources-list__item-content">Mon assurance</div>
        </router-link>
      </li>

      <li v-if="startingGuideUrls.length > 0" class="dashboard-resources-list__item">
        <div class="dashboard-resources-list__item-header">
          <img class="dashboard-resources-list__item-image" src="/icons/Guide-depart_vecteur.svg" />

          <div class="dashboard-resources-list__item-content">
            <!-- If approved in more than one community, then display bullet lists for chat groups and starting guides -->
            <a v-if="approvedCommunities.length === 1" :href="startingGuideUrls[0].url">
              Guide de départ
            </a>
            <template v-else> Guides de départ </template>
          </div>
        </div>

        <div v-if="approvedCommunities.length > 1" class="dashboard-resources-list__item-list">
          <ul>
            <li v-for="c in startingGuideUrls" :key="c.url">
              <a :href="c.url" target="_blank">{{ c.community }}</a>
            </li>
          </ul>
        </div>
      </li>

      <li v-if="communitiesWithChatGroup.length > 0" class="dashboard-resources-list__item">
        <div class="dashboard-resources-list__item-header">
          <img class="dashboard-resources-list__item-image" src="/icons/messenger.png" />

          <div class="dashboard-resources-list__item-content">
            <!-- If approved in more than one community, then display bullet lists for chat groups and starting guides -->
            <a
              v-if="approvedCommunities.length == 1"
              :href="communitiesWithChatGroup[0].chat_group_url"
            >
              Discuter avec mon quartier LocoMotion
            </a>
            <template v-else> Discuter avec mon quartier LocoMotion </template>
          </div>
        </div>

        <div v-if="approvedCommunities.length > 1" class="dashboard-resources-list__item-list">
          <ul>
            <li v-for="c in communitiesWithChatGroup" :key="c.id">
              <a :href="c.chat_group_url" target="_blank">{{ c.name }}</a>
            </li>
          </ul>
        </div>
      </li>

      <li v-if="inCarLoaningCommunity" class="dashboard-resources-list__item">
        <a
          href="https://info.locomotion.app/tarifs-autopartage/"
          class="dashboard-resources-list__item-header"
        >
          <img class="dashboard-resources-list__item-image" src="/icons/Tarification_vecteur.svg" />

          <div class="dashboard-resources-list__item-content">Combien ça coute?</div>
        </a>
      </li>

      <li class="dashboard-resources-list__item">
        <router-link to="/faq" class="dashboard-resources-list__item-header">
          <img class="dashboard-resources-list__item-image" src="/icons/FAQ_vecteur.svg" />

          <div class="dashboard-resources-list__item-content">FAQ</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DashboardResourcesList",
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  computed: {
    approvedCommunities() {
      return (this?.user?.communities ?? []).filter((c) => c.approved_at && !c.suspended_at);
    },
    communitiesWithChatGroup() {
      if (!this.user || !this.approvedCommunities) {
        return [];
      }
      return this.approvedCommunities.filter(
        (c) => !!c.chat_group_url && c.chat_group_url.length > 0
      );
    },
    inCarLoaningCommunity() {
      return this.user.available_loanable_types.includes("car");
    },
    startingGuideUrls() {
      let communityStartingGuides = [];

      communityStartingGuides = this.approvedCommunities
        .filter((c) => c.starting_guide_url && c.starting_guide_url.length > 0)
        .map((c) => ({ community: c.name, url: c.starting_guide_url }));

      if (communityStartingGuides.length > 0) {
        return communityStartingGuides;
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
.dashboard-resources-list {
  a {
    &,
    &:hover,
    &:active,
    &:focus {
      color: $locomotion-green;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    position: relative;
    margin-top: 1rem;
  }

  &__item-header {
    display: flex;
  }

  &__item-image {
    top: 0;
    left: 0;

    height: 3.5rem;
    width: 3.5rem;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3.5rem;
    margin-left: 1rem;
  }

  &__item-list {
    & > ul {
      padding-left: 1.5rem;
    }
    & > ul > li {
      margin-top: 0.5rem;
    }
  }
}
</style>
