export default {
  fr: {
    email: "Courriel",
    google: "Inscription via Google",
    or: "OU VIA COURRIEL",
    password: "Mot de passe",
    password_length: "Minimum 8 caractères",
    password_repeat: "Mot de passe (confirmation)",
    register: "Bienvenue sur LocoMotion",
    register_error: "Erreur d'inscription",
    register_submit: "S'inscrire",
  },
  en: {
    email: "Email",
    google: "Sign up with Google",
    or: "OR",
    password: "Password",
    password_length: "Minimum of 8 characters",
    password_repeat: "Password (confirmation)",
    register: "Register",
    register_error: "Registration failed",
    register_submit: "Register",
  },
};
