var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[(_vm.type === 'select')?_c('b-form-select',{attrs:{"size":"sm","name":_vm.name,"value":typeof _vm.value === 'undefined' ? null : _vm.value},on:{"input":_vm.onChange}},_vm._l((_vm.options),function(option){return _c('b-form-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(option.label))+" ")])}),1):(_vm.type === 'boolean')?_c('div',[_c('b-form-checkbox',{attrs:{"name":_vm.name,"value":true,"unchecked-value":false,"checked":_vm.value},on:{"change":_vm.onCheckChange}})],1):(_vm.type === 'date')?_c('forms-date-range-picker',{attrs:{"value":_vm.value,"filter-format":"","size":"sm"},on:{"input":_vm.onChange}}):(_vm.type === 'number' || _vm.type === 'currency')?_c('div',[_c('forms-number-range-picker',{attrs:{"value":_vm.value},on:{"input":_vm.onChange}})],1):(_vm.type === 'relation')?_c('div',{staticClass:"flex-fill"},[_c('relation-input',{ref:"relationInput",staticClass:"filter-input-relation",attrs:{"min-search-length":0,"show-clear-button":false,"query":{
        slug: _vm.options.relation,
        value: _vm.options.field,
        text: _vm.options.label,
        params: _vm.options.params,
        details: _vm.options.details,
      },"name":_vm.name,"value":_vm.value},on:{"input":(c) => _vm.onChange(c[_vm.options.field])},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}})],1):_c('b-form-input',{ref:"input",attrs:{"size":"sm","value":_vm.value,"debounce":"300"},on:{"update":_vm.onChange}}),(_vm.type !== 'select' && _vm.type !== 'boolean' && _vm.type !== 'date' && _vm.value)?_c('icon-button',{attrs:{"variant":"link","size":"sm","icon":"x"},on:{"click":_vm.clear}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }