<template>
  <div class="mb-3">
    <div v-for="incident in incidents" :key="incident.id" class="incident-wrapper">
      <div v-if="!incident.id" id="loan-incident-new" class="incident">
        <b-alert variant="warning" show class="mb-0 incident-header not-collapsed">
          <h4 class="alert-heading">
            <b-icon icon="exclamation-triangle-fill" />
            Déclarer un incident
          </h4>

          <ol v-if="loan.loanable.type === 'car'">
            <li><strong>Asseyez-vous et respirez</strong> : voici les étapes à suivre.</li>
            <li>
              <strong>Sélectionnez le type d'incident</strong>:<br />

              Si le véhicule est immobilisé et n’est plus en état de rouler, sélectionnez
              <em>Véhicule immobilisé</em><br />

              Si les dégâts sont mineurs et que le véhicule peut encore rouler, sélectionnez
              <em>Dégâts mineurs</em>
            </li>
            <li><strong>Suivez les instructions à l'étape suivante</strong></li>
            <li><strong>Remise à disposition</strong></li>
          </ol>
          <div class="incident-details">
            <validation-observer ref="observer" v-slot="{ passes }">
              <b-form :novalidate="true" class="form loan-actions-incident__form">
                <forms-validated-input
                  v-if="incidentTypes.length > 1"
                  v-model="newIncident.incident_type"
                  name="incident_type"
                  :rules="{ required: true }"
                  label="Type d'incident"
                  type="select"
                  :options="incidentTypes"
                />

                <forms-validated-input
                  v-model="newIncident.comments_on_incident"
                  name="comments_on_incident"
                  :rules="{ required: true }"
                  label="Commentaire"
                  type="textarea"
                  :rows="3"
                />

                <div class="loan-actions-incident__buttons text-center">
                  <icon-button
                    size="sm"
                    variant="success"
                    class="mr-2"
                    :onclick="async () => passes(() => createIncident(incident))"
                  >
                    Créer
                  </icon-button>

                  <icon-button size="sm" role="cancel" @click="cancelAdding"> Annuler </icon-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-alert>
      </div>
      <div v-else class="incident">
        <b-alert
          class="mb-0 incident-header"
          show
          :variant="incident.is_blocking ? 'danger' : 'warning'"
        >
          <h4 class="alert-heading">
            <b-icon icon="exclamation-triangle-fill" />
            Incident {{ incident.is_blocking ? "" : "non-bloquant" }}
          </h4>

          <div
            v-if="
              incident.reported_by_user &&
              incident.reported_by_user.id === userId &&
              (!incident.loan_id || incident.loan_id === loan.id)
            "
            class="mb-2"
          >
            <strong>Instructions</strong>
            <div v-if="incident.incident_type === 'accident'">
              <ol>
                <li>
                  Prévenez Desjardins Assurances en précisant que le véhicule est loué dans le cadre
                  de LocoMotion<br />
                  <strong>Numéro de téléphone pour Desjardins Assurances</strong><br />
                  1 888 PROTÉGÉ (1 888 776-8343)<br />
                  option #4 “Assurance des entreprises”<br />
                  <strong>Numéro du contrat d’assurance (Solon / LocoMotion)</strong><br />
                  BHDC54H3
                </li>
                <li>Prévenez le-la propriétaire qu’un dossier est ouvert chez Desjardins</li>
                <li>
                  Desjardins Assurances propose un carrossier pour réaliser les réparations (et
                  éventuellement proposer un véhicule de courtoisie au propriétaire, au besoin).
                </li>
              </ol>
            </div>
            <div v-else-if="incident.incident_type === 'small_incident'">
              <ol>
                <li>Prévenez le-la propriétaire</li>
                <li>
                  Le-la propriétaire communiquera avec Desjardins Assurances pour ouvrir un dossier
                </li>
                <li>
                  LocoMotion transmet à Desjardins Assurances les renseignements nécessaires sur
                  votre inscription à LocoMotion et sur la réservation du véhicule.
                </li>
                <li>
                  Desjardins Assurances propose un carrossier pour réaliser les réparations (et
                  éventuellement proposer un véhicule de courtoisie au propriétaire, au besoin).
                </li>
              </ol>
            </div>

            <p v-else>
              Un courriel a été envoyé aux personnes responsables. Vous pouvez tout de même les
              aviser directement pour une résolution plus rapide.
            </p>
          </div>
          <div v-else-if="!loan.id || loan.id === 'new'" class="mb-2">
            <template v-if="userIsBorrower && incident.is_blocking">
              <span v-if="dayjs(incident.blocking_until).isAfter(loan.departure_at, 'minute')">
                Un incident est en cours de résolution pour ce véhicule. Malheureusement, cet
                incident bloque les nouveaux emprunts jusqu'au
                {{ incident.blocking_until | datetime }}. Pour effectuer votre trajet, considérez
                modifier la date ou emprunter un autre véhicule.
              </span>
              <span v-else>
                Un incident est en cours de résolution pour ce véhicule et bloque les emprunts
                jusqu'au {{ incident.blocking_until | datetime }}.
              </span>
            </template>
          </div>
          <div v-else class="mb-2">
            Un incident (#{{ incident.id }}) qui pourrait vous affecter est en cours de résolution
            pour ce véhicule.
            <span v-if="loanStartsInFuture && userIsBorrower && incident.is_blocking">
              Cet incident bloque les emprunts jusqu'au {{ incident.blocking_until | datetime }}.
              Vous serez avisé lorsque le problème sera résolu. Pour vous assurer de pouvoir
              effectuer votre trajet, considérez changer la date de départ ou annuler votre emprunt
              et réserver un autre véhicule.
            </span>
            <span v-else-if="!loanStartsInFuture">
              Vous pouvez tout de même compléter les étapes de l'emprunt ici, s'il a eu lieu, ou
              sinon annuler.
            </span>
          </div>

          <template v-if="!incident.details_hidden">
            <toggle-link class="d-block mt-1 mb-2" :collapse-id="`incident-details-${incident.id}`"
              >Détails</toggle-link
            >

            <b-collapse :id="`incident-details-${incident.id}`">
              <incident-form
                class="incident-details"
                :incident="incident"
                :loanable="loan.loanable"
                @update="updateIncident"
              />
            </b-collapse>
          </template>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IncidentForm from "@/components/Incident/IncidentForm.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ToggleLink from "@/components/shared/ToggleLink.vue";
import { isBorrower } from "@/helpers/permissions/loans";

import { post } from "@/requests/server";
import dayjs from "dayjs";

export default {
  name: "LoanIncidents",
  components: {
    ToggleLink,
    IncidentForm,
    IconButton,
    FormsValidatedInput,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
    incidents: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      newIncident: {
        incident_type: null,
        comments_on_incident: null,
      },
    };
  },
  computed: {
    dayjs() {
      return dayjs;
    },
    incidentTypes() {
      switch (this.loan.loanable.type) {
        case "car":
          return [
            { value: "accident", text: "Véhicule immobilisé" },
            { value: "small_incident", text: "Dégâts mineurs" },
          ];
        default:
          return [{ value: "general", text: "Incident avec une remorque ou un vélo" }];
      }
    },
    loanStartsInFuture() {
      return this.$second.isBefore(this.loan.departure_at, "minute");
    },
    userIsBorrower() {
      return isBorrower(this.$store.state.user, this.loan);
    },
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    updateIncident(incident) {
      if (incident.status === "completed") {
        // If updated incident has completed, simply remove it
        this.loan.loanable.active_incidents = this.loan.loanable.active_incidents.filter(
          (i) => i.id !== incident.id
        );
        return;
      }

      this.loan.loanable.active_incidents = this.loan.loanable.active_incidents.map((i) => {
        if (i.id === incident.id) {
          return incident;
        }
        return i;
      });
    },
    cancelAdding() {
      this.loan.loanable.active_incidents = this.loan.loanable.active_incidents.filter(
        (i) => !!i.id
      );
    },
    async createIncident() {
      if (this.loan.loanable.type !== "car") {
        this.newIncident.incident_type = "general";
      }
      const { data } = await post(
        `/incidents`,
        {
          type: "incident",
          loan_id: this.loan.id,
          loanable_id: this.loan.loanable.id,
          ...this.newIncident,
        },
        {
          notifications: { action: "création d'incident" },
        }
      );
      this.loan.loanable.active_incidents = this.loan.loanable.active_incidents.filter(
        (i) => !!i.id
      );
      this.loan.loanable.active_incidents.push(data);
      this.$emit("created");
    },
  },
};
</script>

<style lang="scss">
.incident-wrapper + * {
  margin-top: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.incident-header.alert {
  padding-bottom: 0.25rem;
}
.incident-details {
  border-radius: 0.5rem;
  background: $white;
  box-shadow: $small-shadow;
  color: $dark;
  padding: 1rem;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  textarea {
    font-size: 0.9rem;
  }
}
</style>
