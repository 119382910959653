<script>
import IconButton from "@/components/shared/IconButton.vue";
import { get } from "@/requests/server";
import Vue from "vue";

export default Vue.extend({
  name: "GbfsStream",
  components: { IconButton },
  props: {
    root: {
      type: String,
      required: true,
    },
    stream: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      json: "",
      loaded: false,
      loading: false,
    };
  },
  computed: {
    url() {
      return this.root + "/" + this.stream;
    },
  },
  methods: {
    async load() {
      this.loading = true;
      const { data: json } = await get(this.url, {
        notifications: { action: "chargement du flux" },
        cleanupCallback: () => (this.loading = false),
      });
      this.loaded = true;
      this.json = json;
    },
  },
});
</script>

<template>
  <div class="mb-4">
    <h3 v-b-toggle:toggleable="'collapse_' + stream" class="toggle">
      <b-icon font-scale="0.75" icon="chevron-right"></b-icon> {{ stream }}
    </h3>
    <b-collapse :id="'collapse_' + stream" ref="collapse" :visible="false" @show="load">
      <div>
        <div class="stream-buttons">
          <icon-button role="load" :loading="loading" @click="load">Rafraichir</icon-button>
          <a :href="url">{{ url }}</a>
        </div>

        <layout-loading v-if="loading && !loaded" />
        <div v-else>
          <pre class="stream">{{ json }}</pre>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.stream-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
  }

  .b-icon.bi {
    font-size: 100%;
    vertical-align: -0.15em;
  }
}
.stream {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid $grey;
  max-height: 30rem;
  background: white;
}
.toggle {
  transition-duration: 0.5s;

  &.collapsed {
    margin-bottom: 0;
  }

  .b-icon {
    transition: 0.3s;
  }

  &.not-collapsed .b-icon {
    transform: rotate(90deg);
  }
}
</style>
