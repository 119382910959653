<template>
  <div v-if="routeDataLoaded" class="profile-payment_methods">
    <h2>{{ $t("saved_payment_methods") }}</h2>
    <ul class="profile-payment_methods__payment_methods">
      <li
        v-for="paymentMethod in paymentMethods"
        :key="paymentMethod.id"
        class="d-flex align-items-center"
      >
        <router-link :to="'/profile/payment_methods/' + paymentMethod.id"
          >{{ paymentMethod.name }}
        </router-link>
        <icon-button
          size="sm"
          role="remove-item"
          class="ml-2"
          :loading="deletingIds[paymentMethod.id]"
          @click="() => destroy(paymentMethod.id)"
        >
          {{ $t("forms.delete") | capitalize }}
        </icon-button>
      </li>
    </ul>
    <div>
      <icon-button v-if="!addingPaymentMethod" role="add" @click="addPaymentMethod">
        {{ $t("add_new") }}
      </icon-button>
      <b-card v-else>
        <payment-method-form
          show-cancel
          @created="addedMethod"
          @cancel="addingPaymentMethod = false"
        />
      </b-card>
    </div>
    <hr />
    <bank-details-form />
  </div>
  <layout-loading v-else />
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import Authenticated from "@/mixins/Authenticated";
import DataRouteGuards from "@/mixins/DataRouteGuards";
import UserMixin from "@/mixins/UserMixin";

import locales from "@/locales";
import BankDetailsForm from "@/components/PaymentMethod/BankDetailsForm.vue";
import LayoutLoading from "@/components/Layout/Loading.vue";

export default {
  name: "ProfilePaymentMethods",
  components: {
    IconButton,
    PaymentMethodForm: () => import("@/components/PaymentMethod/PaymentMethodForm.vue"),
    LayoutLoading,
    BankDetailsForm,
  },
  mixins: [Authenticated, DataRouteGuards, UserMixin],
  i18n: {
    messages: {
      en: {
        ...locales.en.paymentMethods,
      },
      fr: {
        ...locales.fr.paymentMethods,
      },
    },
  },
  data() {
    return {
      deletingIds: {},
      addingPaymentMethod: false,
    };
  },
  computed: {
    paymentMethods() {
      return this.$store.state.paymentMethods.data;
    },
  },
  methods: {
    async destroy(id) {
      this.$set(this.deletingIds, id, true);
      await this.$store.dispatch("paymentMethods/destroy", { id });
      const currentMethods = this.$store.state.paymentMethods.data;
      this.$store.commit(
        "paymentMethods/data",
        currentMethods.filter((m) => m.id !== id)
      );
      this.deletingIds[id] = false;
    },
    addPaymentMethod() {
      this.$store.dispatch("paymentMethods/loadEmpty");
      this.addingPaymentMethod = true;
    },
    async addedMethod() {
      const currentMethods = this.$store.state.paymentMethods.data;
      const addedItem = this.$store.state.paymentMethods.item;
      this.$store.commit("paymentMethods/data", [...currentMethods, addedItem]);
      this.addingPaymentMethod = false;
    },
  },
};
</script>
<style lang="scss">
.profile-payment_methods {
  &__payment_methods {
    list-style: none;
    padding-left: 0;
    li {
      margin-top: 0.5rem;
    }
  }
}
</style>
