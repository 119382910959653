<template>
  <div class="profile-form">
    <validation-observer ref="profileFormObserver" v-slot="{ passes, pristine }">
      <b-form class="profile-form__form form" @submit.stop.prevent="passes(submit)">
        <forms-validated-input
          v-model="user.name"
          name="name"
          :label="$t('fields.name') | capitalize"
          :rules="form.general.name.rules"
          type="text"
          @keypress.native="onlyChars"
        />
        <forms-validated-input
          v-model="user.last_name"
          name="last_name"
          :label="$t('fields.last_name') | capitalize"
          :rules="{ required: true }"
          type="text"
        />

        <forms-validated-input
          v-model="user.phone"
          name="phone"
          :label="$t('fields.phone') | capitalize"
          description="Pour aider la communication entre membres lors d'une réservation"
          :rules="form.general.phone.rules"
          type="text"
          mask="(###) ###-####"
        />

        <forms-validated-input
          v-model="user.description"
          name="description"
          description="Vos passions, votre film préféré ou vos plus grandes folies!"
          :rules="{ required: true }"
          label="Présentez-vous à votre communauté"
          type="textarea"
        />

        <div class="profile-picture-uploader">
          <div class="circle">
            <forms-validated-input
              v-model="user.avatar"
              type="image"
              :rules="{ required: true }"
              :label="$t('fields.avatar') | capitalize"
              :description="$t('descriptions.avatar')"
              name="avatar"
              preview-aspect-ratio="1 / 1"
            />
          </div>
          <svg-smiling-heart class="smiling-heart" />
        </div>

        <forms-validated-input
          v-model="user.date_of_birth"
          name="date_of_birth"
          :label="$t('fields.date_of_birth') | capitalize"
          :rules="dateOfBirthRules"
          type="date"
          :open-date="openDate"
          :disabled-dates-fct="datesInTheFuture"
        >
          <template #description>
            Uniquement pour notre partenaire d'assurance. La
            <b-link href="/politique-confidentialite.html" target="_blank"
              >protection des donnés</b-link
            >
            nous tient à coeur.
          </template>
        </forms-validated-input>

        <b-alert :variant="age < 18 ? 'danger' : 'warning'" :show="age < 21">
          L'âge minimal pour utiliser LocoMotion est de 18 ans pour les vélos et les remorques; 21
          ans pour les autos.
        </b-alert>

        <div v-if="!skipMap" class="form-group">
          <label>Adresse</label>

          <validation-provider
            ref="addressValidator"
            v-slot="{ validated, valid, errors }"
            :rules="{ required: true, isFromGoogle: true }"
            name="adresse"
            mode="eager"
          >
            <place-autocomplete-input
              v-model="user.address"
              class="form-control"
              :class="{ 'is-invalid': validated && !valid, 'is-valid': validated && valid }"
              :options="{
                language: 'fr',
                fields: ['formatted_address'],
                componentRestrictions: { country: 'ca' },
                types: ['address'],
              }"
              @place-selected="placeSelected"
            />
            <b-form-invalid-feedback :state="validated ? valid : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <small class="text-muted">
            Pour vous ajouter au bon quartier. <br />
            LocoMotion communiquera votre adresse seulement à son équipe et à votre comité local
            ayant signé une entente de confidentialité.
          </small>
        </div>

        <b-row v-else>
          <b-col>
            <forms-validated-input
              v-model="user.address"
              type="text"
              name="address"
              label="Addresse (mode dev, ne sera pas associée à un quartier)"
            />
          </b-col>
        </b-row>

        <slot />

        <div v-if="!hideButtons" class="form__buttons">
          <icon-button :loading="loading" variant="success" type="submit" :disabled="pristine">
            {{ $t("save") | capitalize }}
          </icon-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import SmilingHeart from "@/assets/svg/smiling-heart.svg";
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import PlaceAutocompleteInput from "@/components/shared/PlaceAutocompleteInput.vue";
import { isDevMode } from "@/helpers/devMode";

import locales from "@/locales";

import FormLabelsMixin from "@/mixins/FormLabelsMixin";
import dayjs from "dayjs";

import { extend } from "vee-validate";

export default {
  name: "ProfileForm",
  components: {
    IconButton,
    PlaceAutocompleteInput,
    FormsValidatedInput,
    "svg-smiling-heart": SmilingHeart,
  },
  mixins: [FormLabelsMixin],
  props: {
    changed: {
      type: Boolean,
      required: false,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPerson: true,
      openDate: new Date("1985-01-07"),
      submitted: false,
      addressFromGoogle: null,
    };
  },
  computed: {
    age() {
      const age = this.$dayjs(this.$dayjs()).diff(this.user.date_of_birth, "year");

      if (Number.isNaN(age)) {
        return Number.MAX_SAFE_INTEGER;
      }

      return age;
    },
    dateOfBirthRules() {
      return {
        ...this.form.general.date_of_birth.rules,
        required: true,
      };
    },
    skipMap() {
      return isDevMode();
    },
  },
  mounted() {
    if (!this.skipMap) {
      extend("isFromGoogle", {
        validate: (address) => address === this.addressFromGoogle,
        message: "L'adresse doit provenir de la liste de suggestions.",
      });
      // We assume that initially created addresses are correct.
      this.addressFromGoogle = this.user.address ? this.user.address : null;
    }
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.users,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.users,
        ...locales.fr.forms,
      },
    },
  },
  methods: {
    placeSelected(event) {
      if (event && event.formatted_address) {
        this.addressFromGoogle = event.formatted_address;
        this.$nextTick(this.validate);
      }
    },
    onlyChars(event) {
      if (!this.isPerson) {
        return true;
      }

      if (!event?.key) {
        return true;
      }

      if (event.key.match(/[0-9]/)) {
        event.preventDefault();
        return false;
      }

      return true;
    },
    datesInTheFuture(date) {
      return dayjs().isSameOrBefore(date);
    },
    submit(...params) {
      this.$emit("submit", ...params);
      this.submitted = true;
    },
  },
};
</script>

<style lang="scss">
.profile-form {
  label {
    color: #1a1a1a;
    font-weight: 400;
    font-size: 14px;
  }

  .hide-label {
    label {
      display: none !important;
    }
  }

  h2.allo-title {
    text-align: left;
    margin: 15px 0 25px 0;
    svg {
      width: 60px;
      position: relative;
      top: -3px;
    }
  }

  .profile-picture-uploader {
    // Make the smiling heart position position relative to this container
    position: relative;

    // Selected image
    figure {
      max-width: 200px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: $medium-shadow;
    }

    // hidden input, needs aspect ratio for the placeholder to fit the height
    .custom-file {
      max-width: 200px;
      aspect-ratio: 1 / 1;
      min-height: unset;
      height: unset;
      margin: auto;
      display: block;
    }

    // Image placeholder
    .custom-file-label {
      border: none;
      width: 100%;
      height: 100%;
      background-color: #00ada8;
      border-radius: 50%;
      overflow: inherit;
      background-image: url("/white-female-face.svg");
      background-position: top 40% center;
      &::after {
        background-color: #245ae9;
        border-radius: 32px;
        font-size: 16px;
        color: white;
        bottom: 1rem;
        font-weight: 600;
        z-index: 4;
      }
    }

    .smiling-heart {
      position: absolute;
      z-index: 10;
      left: 50%;
      margin-left: 1rem;
      top: 0;
      width: 6rem;
      max-width: 50%;
      transform: rotate(15deg);
    }

    button {
      display: block;
      margin: 20px auto;
    }
  }

  .form__buttons {
    margin-top: 20px;
    .btn-primary {
      margin-left: 0;
    }
  }

  .safety-questions {
    h2,
    h3 {
      color: #047249;
    }

    h3 {
      font-size: 18px;
    }
  }
}
</style>
