import { del, put } from "@/requests/server";

export async function addTrustedBorrower(loanableId, userId) {
  await put(
    `/loanables/${loanableId}/trusted_borrowers`,
    {
      user_id: userId,
    },
    {
      notifications: {
        action: "ajout au réseau de confiance",
        onSuccess: "Membre ajouté(e) au réseau de confiance!",
      },
    }
  );
}

export async function removeTrustedBorrower(trustedBorrower) {
  if (!trustedBorrower) {
    return;
  }

  await del(`/loanables/roles/${trustedBorrower.id}`, {
    notifications: {
      action: "retrait du réseau de confiance",
      onSuccess: "Membre retiré(e) du réseau de confiance!",
    },
  });
}
