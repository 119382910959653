<template>
  <div class="step" :class="{ slim: slim }">
    <div v-if="showHeader" class="step__title">
      <rounded-tag class="step__title__number">{{ number }}</rounded-tag>
      <slot name="title">{{ title }}</slot>
    </div>
    <slot />
  </div>
</template>

<script>
import RoundedTag from "@/components/shared/RoundedTag.vue";

export default {
  name: "ActionStep",
  components: { RoundedTag },
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    number: {
      type: Number,
      required: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.step {
  margin-bottom: 1rem;

  &__title {
    counter-increment: step-counter 1;
    border-radius: 0.5rem;
    min-height: 3rem;
    background: #eef8f7;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;

    &__number {
      content: counter(step-counter) ". ";
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
    }
  }

  &.slim {
    margin-bottom: 0;
    .step__title {
      background: none;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
