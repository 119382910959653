<template>
  <div v-if="itemLoaded && routeDataLoaded" class="profile-account">
    <div class="form__section">
      <h2>Changer mon mot de passe</h2>

      <user-password-form
        ref="passwordForm"
        :user="item"
        :loading="loading"
        :required="true"
        :must-validate-current="true"
        @updated="resetPasswordFormAndShowModal"
      />

      <b-modal
        id="password-change-modal"
        size="md"
        header-bg-variant="success"
        title-class="font-weight-bold"
        ok-only
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        :title="$t('views.profile.account.password_change_modal.title')"
      >
        <!-- eslint-disable-next-line vue/no-v-html-->
        <div v-html="$t('views.profile.account.password_change_modal.content')" />
      </b-modal>
    </div>

    <div class="form__section">
      <h2>Changer mon courriel</h2>

      <p>{{ $t("views.profile.account.current_email") }}&nbsp;: {{ item.email }}.</p>

      <user-email-form
        ref="emailForm"
        :user="item"
        :loading="loading"
        @updated="resetEmailFormAndShowModal"
      />

      <b-modal
        id="email-change-modal"
        size="md"
        header-bg-variant="success"
        title-class="font-weight-bold"
        ok-only
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        :title="$t('views.profile.account.email_change_modal.title')"
      >
        <!-- eslint-disable-next-line vue/no-v-html-->
        <div v-html="$t('views.profile.account.email_change_modal.content')" />
      </b-modal>
    </div>

    <div class="form__section">
      <a id="deactivate" href="#deactivate"></a>
      <h2>Gestion du compte</h2>

      <p>
        Pour désactiver ou supprimer votre compte, assurez-vous en premier que vous n'ayez plus
        <b-link :to="`/profile/loans?status=in_process`">d'emprunts en cours</b-link
        ><template v-if="item.balance > 0">
          et que
          <b-link v-b-modal.archive-account-claim-modal>votre solde est à 0</b-link> </template
        >.
      </p>

      <b-modal
        v-if="item.balance > 0"
        id="archive-account-claim-modal"
        title="Demande de transfert du solde du compte LocoMotion vers un compte bancaire"
        size="lg"
        hide-footer
      >
        <user-claim-credits-box
          :user="$store.state.user"
          @claimed="() => $bvModal.hide('archive-account-claim-modal')"
          @cancel="() => $bvModal.hide('archive-account-claim-modal')"
        />
      </b-modal>

      <b-card class="mb-3 border-danger border">
        <p>
          La désactivation de votre compte permet de masquer vos données aux autres membres et
          empêche une connexion future. La réactivation est possible, mais doit être demandée par
          courriel à info@locomotion.app et peut prendre plusieurs jours.
        </p>

        <validated-button
          variant="outline-danger"
          icon="person-x"
          :action="deactivateAccount"
          label="Désactiver votre compte"
          :question="`Êtes-vous certains de vouloir désactiver votre compte ?`"
        />
      </b-card>

      <b-card class="border-danger border">
        <p>
          La suppression de vos données est définitive et votre compte ne pourra donc pas être
          rétabli.
        </p>

        <validated-button
          variant="outline-danger"
          icon="x-octagon-fill"
          :action="deleteAccountAndData"
          label="Supprimer votre compte et vos données"
          :question="`Êtes-vous certains de vouloir supprimer vos données et archiver votre compte? Cette action est finale. Les données ne pourront pas être récupérées ultérieurement.`"
        />
      </b-card>
    </div>
  </div>
  <layout-loading v-else />
</template>

<script>
import ValidatedButton from "@/components/Admin/ValidatedButton.vue";
import UserClaimCreditsBox from "@/components/User/ClaimCreditsBox.vue";
import UserEmailForm from "@/components/User/EmailForm.vue";
import UserPasswordForm from "@/components/User/PasswordForm.vue";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";
import { del } from "@/requests/server";

export default {
  name: "ProfileAccount",
  components: {
    ValidatedButton,
    UserClaimCreditsBox,
    UserEmailForm,
    UserPasswordForm,
  },
  mixins: [DataRouteGuards, FormMixin],
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "me",
    },
  },
  methods: {
    resetEmailFormAndShowModal() {
      this.$refs.emailForm.reset();
      this.$bvModal.show("email-change-modal");
    },
    resetPasswordFormAndShowModal() {
      this.$refs.passwordForm.reset();
      this.$bvModal.show("password-change-modal");
    },
    async deactivateAccount() {
      await del(`/users/${this.item.id}`, {
        notifications: { action: "Désactivation du compte", onSuccess: "Compte désactivé!" },
      });

      await this.$router.push("/login?logout=1");
      await this.$store.dispatch("logout");
    },
    async deleteAccountAndData() {
      await del(`/users/${this.item.id}`, {
        axiosRequestConfig: { data: { delete_data: true } },
        notifications: { action: "Supression des données", onSuccess: "Données supprimées!" },
      });

      await this.$router.push("/login?logout=1");
      await this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss">
.profile-account {
  margin-bottom: 3em;
}
</style>
