<template>
  <b-form-group class="forms-file-uploader" :label="label" :label-for="field">
    <div v-if="loading">
      <img src="/loading.svg" />
    </div>
    <div v-else-if="!value">
      <b-form-file
        :id="field"
        :ref="`${field}fileinput`"
        :value="value"
        :state="validationState"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="field"
        :accept="accept.join(',')"
        browse-text="Sélectionner"
        drop-placeholder="Déposer le fichier ici..."
        @change="handleChange"
      />
      <div v-if="error" class="invalid-feedback">
        {{ error }}
      </div>
    </div>
    <div v-else>
      <safe-file :file="value" />
      <icon-button v-if="!disabled" class="ml-2" size="sm" role="remove-item" @click="removeFile" />
    </div>
  </b-form-group>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import SafeFile from "@/components/shared/SafeFile.vue";
import { uploadFile } from "@/requests/fileRequests";

export default {
  name: "FormsFileUploader",
  components: { IconButton, SafeFile },
  props: {
    accept: {
      default: () => [
        "*.png",
        "*.jpg",
        "*.jpeg",
        "image/png",
        "image/jpg",
        "image/jpeg",
        "*.pdf",
        "application/pdf",
      ],
      type: Array,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    field: {
      required: true,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    placeholder: {
      default: "Téléverser...",
      type: String,
    },
    value: {
      type: Object,
      require: false,
      default: undefined,
    },
    state: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  computed: {
    validationState() {
      // state set by the parent
      if (this.state !== null) {
        return this.state;
      }

      if (this.error) {
        return false;
      }
      return null;
    },
  },
  methods: {
    handleChange(event) {
      switch (event.type) {
        case "drop":
          this.uploadFile(event.dataTransfer.files);
          break;
        default:
          this.uploadFile(event.target.files);
          break;
      }
    },
    removeFile() {
      this.$emit("input", null);

      if (this.$refs[`${this.field}fileinput`]) {
        this.$refs[`${this.field}fileinput`].reset();
      }
    },
    async uploadFile(fileList) {
      this.loading = true;
      const { file, error } = await uploadFile(this.field, fileList);
      this.loading = false;

      if (file) {
        this.$emit("input", file);
      }
      this.error = error;
    },
  },
};
</script>

<style lang="scss">
.forms-file-uploader {
  .preview img {
    max-width: 100%;
  }

  .custom-file-label {
    overflow: hidden;
  }
}
</style>
