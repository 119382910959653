export default {
  namespaced: true,
  state: {
    borough: null,
    center: null,
  },
  mutations: {
    borough(state, value) {
      state.borough = value;
    },
    center(state, value) {
      state.center = value;
    },
  },
};
