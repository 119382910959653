import store from "../store";

export function mustBeAuthenticated(to, from, next) {
  if (store.state.token) {
    if (!store.state.loaded && !store.state.loading) {
      try {
        store.dispatch("loadUser").then(next);
        return;
      } catch (e) {
        // failed loading user, fallback to login if needed
        store.commit("user", null);
        next(`/login?r=${encodeURIComponent(to.fullPath)}`);
        return;
      }
    }
    // user is loaded
    next();
    return;
  }

  // if no token is set, ensure user is null and redirect to login
  store.commit("user", null);
  next(`/login?r=${encodeURIComponent(to.fullPath)}`);
}

export default {
  beforeRouteEnter: mustBeAuthenticated,
  beforeRouteUpdate: mustBeAuthenticated,
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
