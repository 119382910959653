<script>
import IncidentForm from "@/components/Incident/IncidentForm.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";
import { Column } from "@/components/shared/PaginatedTableColumns";
import { isAdminOfLoanable } from "@/helpers/permissions/loanables";
import locales from "@/locales";
import Vue from "vue";

export default Vue.extend({
  name: "IncidentTable",
  components: {
    IncidentForm,
    PaginatedTable,
  },
  props: {
    loanableId: {
      type: String,
      default: null,
    },
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
    blockingOnly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let fetchParams = {};
    if (this.loanableId) {
      fetchParams.loanable_id = this.loanableId;
    } else {
      fetchParams.for = "edit";
    }
    if (this.blockingOnly) {
      fetchParams.is_blocking = true;
    }
    return {
      fetchParams,
    };
  },
  computed: {
    columns() {
      let columns = [
        new Column("id", "ID", "id"),
        Column.withSelect("status", "Statut", [
          { value: null, label: "tous" },
          {
            value: "in_process",
            label: "en cours",
            variant: "warning",
          },
          {
            value: "completed",
            label: "Résolu",
            variant: "success",
          },
        ]),

        Column.withSelect(
          "incident_type",
          "Type",
          [
            { value: null, label: "tous" },
            {
              value: "accident",
              label: "véhicule immobilisé",
              variant: "danger",
            },
            {
              value: "small_incident",
              label: "dégats mineurs",
              variant: "warning",
            },
            {
              value: "general",
              label: "incident général",
              variant: "secondary",
            },
          ],
          { showByDefault: false }
        ),

        new Column("blocking_until", "Bloque les emprunts jusqu'au", "date"),
        new Column("created_at", "Déclaré le", "date", { showByDefault: false }),
        new Column("updated_at", "Dernière mise à jour", "date"),
      ];

      if (!this.loanableId) {
        columns.splice(
          1,
          0,
          Column.withRelation(
            "loanable.name",
            "Véhicule",
            { relation: "loanables", label: "name", field: "id", params: { for: "admin" } },
            "loan.loanable_id",
            { urlFct: (incident) => `/loanables/${incident.loanable.id}` }
          )
        );
      }

      if (!this.hideDetails) {
        columns.splice(
          3,
          0,
          Column.withRelation(
            "assignee.full_name",
            "Responsable",
            { relation: "users", label: "full_name", field: "id", params: { for: "admin" } },
            "assignee_id",
            {
              urlFct: (incident) =>
                this.isAdmin(incident.loanable) ? `/admin/users/${incident.assignee.id}` : null,
            }
          ),
          new Column("loan_id", "Source", "id", {
            urlFct: (incident) =>
              this.isAdmin(incident.loanable)
                ? `/admin/loans/${incident.loan_id}`
                : `/loans/${incident.loan_id}`,
            formatter: (id) => (id ? `Emprunt #${id}` : ""),
          }),
          Column.withRelation(
            "reported_by_user.full_name",
            "Déclaré par",
            { relation: "users", label: "full_name", field: "id", params: { for: "admin" } },
            "reported_by_user_id",
            {
              urlFct: (incident) =>
                this.isAdmin(incident.loanable)
                  ? `/admin/users/${incident.reported_by_user.id}`
                  : null,
              showByDefault: false,
            }
          ),
          Column.withRelation(
            "resolved_by_user.full_name",
            "Résolu par",
            { relation: "users", label: "full_name", field: "id", params: { for: "admin" } },
            "resolved_by_user_id",
            {
              urlFct: (incident) =>
                this.isAdmin(incident.loanable)
                  ? `/admin/users/${incident.resolved_by_user.id}`
                  : null,
              showByDefault: false,
            }
          )
        );
      }

      return columns;
    },
  },
  methods: {
    isAdmin(loanable) {
      return isAdminOfLoanable(this.$store.state.user, loanable);
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.forms,
      },
    },
  },
});
</script>

<template>
  <div>
    <paginated-table
      ref="incidentsTable"
      class="mt-4"
      label="Incidents"
      :fetch-params="fetchParams"
      endpoint="incidents"
      :columns="columns"
      :extra-data="[
        'loanable',
        'notes.author.avatar',
        'assignee',
        'reported_by_user.avatar',
        'resolved_by_user.avatar',
      ]"
      :initial-filters="initialFilters"
      :has-row-details="!hideDetails"
      editable-items
      :show-action-column="!hideDetails"
      :show-generate-csv="false"
      v-bind="$attrs"
    >
      <template #row-details="{ item, updateItem }">
        <div v-if="item.details_hidden" class="font-italic">
          Vous n'avez pas accès aux détails de l'incident.
        </div>
        <incident-form v-else :incident="item" :loanable="item.loanable" @update="updateItem" />
      </template>
      <template #head-buttons>
        <slot name="head-buttons" />
      </template>
    </paginated-table>
  </div>
</template>

<style scoped lang="scss"></style>
