<template>
  <b-navbar class="layout-navbar" toggleable="lg" variant="transparent" type="light">
    <b-collapse class="layout-navbar__collapse" is-nav>
      <b-navbar-nav class="mr-auto">
        <b-navbar-brand :href="logoUrl" :to="logoRoute">
          <img src="/logo.svg" alt="Locomotion" />
        </b-navbar-brand>

        <b-navbar-brand v-if="title" class="layout-navbar__separator d-none d-sm-block" />
        <b-navbar-brand
          v-if="title"
          class="navbar-brand--title d-none d-sm-block"
          :style="{ 'view-transition-name': 'page-title' }"
        >
          {{ title }}
        </b-navbar-brand>
      </b-navbar-nav>
    </b-collapse>

    <b-navbar-brand :href="logoUrl" :to="logoRoute">
      <img src="/logo.svg" alt="Locomotion" class="logo d-lg-none" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse id="nav-collapse" class="layout-navbar__collapse" is-nav>
      <div class="layout-navbar__collapse__illustration d-md-none" />

      <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
        <b-nav-item v-if="!isGlobalAdmin && isRegistered" to="/app">
          <span class="nav-link__icon d-lg-none">
            <svg-dashboard />
          </span>
          <span class="nav-link__text">{{ $t("titles.dashboard") | capitalize }}</span>
        </b-nav-item>

        <b-nav-item v-if="!isGlobalAdmin && canLoanVehicle" to="/search/map">
          <span class="nav-link__icon d-lg-none">
            <svg-location />
          </span>
          <span class="nav-link__text">Emprunter un véhicule</span>
        </b-nav-item>

        <b-nav-item
          v-if="!isGlobalAdmin && hasCompletedRegistration"
          to="/profile/locomotion"
          class="d-block d-lg-none"
        >
          <span class="nav-link__icon">
            <svg-profile />
          </span>
          <span class="nav-link__text">Profil</span>
        </b-nav-item>

        <b-nav-item to="/faq" class="d-block d-lg-none">
          <span class="nav-link__icon">
            <svg-help />
          </span>
          <span class="nav-link__text">Contact / FAQ</span>
        </b-nav-item>

        <b-nav-item class="d-block d-lg-none" @click="logout">
          <span class="nav-link__icon">
            <svg-logout />
          </span>
          <span class="nav-link__text">Déconnexion</span>
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="isAdmin"
          ref="admindropdown"
          class="layout-navbar__admin"
          text="Admin"
          right
        >
          <admin-sidebar
            section-class="dropdown-item"
            :is-global-admin="isGlobalAdmin"
            item-component="b-dropdown-item"
            section-component="li"
            @click="$refs.admindropdown.hide()"
          >
          </admin-sidebar>
        </b-nav-item-dropdown>

        <locale-switcher />

        <b-nav-item-dropdown class="layout-navbar__dropdown d-none d-lg-block" text="" right>
          <template #button-content>
            <user-avatar :user="user" />
          </template>
          <b-dropdown-text
            v-if="!isGlobalAdmin && hasCompletedRegistration"
            text-class="d-flex p-2 align-items-center"
          >
            <user-avatar :user="user" />
            <span class="dropdown-container">
              <span class="username"> {{ user.name }}</span>
            </span>
          </b-dropdown-text>
          <b-dropdown-divider v-if="!isGlobalAdmin && hasCompletedRegistration" />
          <b-dropdown-item v-if="!isGlobalAdmin && hasCompletedRegistration" to="/app">
            <span class="nav-link__icon">
              <svg-category />
            </span>
            <span class="nav-link__text">Tableau de bord</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isGlobalAdmin && hasCompletedRegistration"
            to="/profile/locomotion"
          >
            <span class="nav-link__icon">
              <svg-profile />
            </span>
            <span class="nav-link__text">Mon profil</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="!isGlobalAdmin && hasCompletedRegistration"
            to="/profile/loanables"
          >
            <span class="nav-link__icon">
              <svg-vector />
            </span>
            <span class="nav-link__text">Mes véhicules</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="!hasCompletedRegistration" to="/faq">
            <span class="nav-link__icon">
              <svg-help />
            </span>
            <span class="nav-link__text">FAQ</span>
          </b-dropdown-item>
          <b-dropdown-divider v-if="!hasCompletedRegistration || !isGlobalAdmin" />
          <b-dropdown-item @click="logout">
            <span class="nav-link__icon">
              <svg-logout />
            </span>
            <span class="nav-link__text">Déconnexion</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-else class="ml-auto">
        <b-nav-item to="/register">
          <span class="nav-link__icon d-lg-none">
            <svg-register />
          </span>
          <span class="nav-link__text">S'inscrire</span>
        </b-nav-item>
        <b-nav-item to="/login">
          <span class="nav-link__icon d-lg-none">
            <svg-login />
          </span>
          <span class="nav-link__text">Se connecter</span>
        </b-nav-item>

        <locale-switcher />
      </b-navbar-nav>

      <div class="layout-navbar__collapse__buffer" />
    </b-collapse>
  </b-navbar>
</template>

<script>
import Category from "@/assets/svg/category.svg";
import Dashboard from "@/assets/svg/dashboard.svg";
import Help from "@/assets/svg/help.svg";
import Location from "@/assets/svg/location.svg";
import Login from "@/assets/svg/login.svg";
import Logout from "@/assets/svg/logout.svg";
import Profile from "@/assets/svg/profile.svg";
import Register from "@/assets/svg/register.svg";
import Vector from "@/assets/svg/vector.svg";

import AdminSidebar from "@/components/Admin/Sidebar.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UserAvatar from "@/components/User/Avatar.vue";

import UserMixin from "@/mixins/UserMixin";

export default {
  name: "MainNavbar",
  components: {
    AdminSidebar,
    LocaleSwitcher,
    UserAvatar,
    "svg-category": Category,
    "svg-dashboard": Dashboard,
    "svg-help": Help,
    "svg-location": Location,
    "svg-login": Login,
    "svg-logout": Logout,
    "svg-profile": Profile,
    "svg-register": Register,
    "svg-vector": Vector,
  },
  mixins: [UserMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    logoUrl() {
      if (process.env.VUE_APP_INFO_WEBSITE_URL) {
        return process.env.VUE_APP_INFO_WEBSITE_URL;
      }
      return "";
    },
    logoRoute() {
      if (!process.env.VUE_APP_INFO_WEBSITE_URL) {
        return "/app";
      }
      return "";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");

      this.$store.commit("addNotification", {
        content: "Vous n'êtes plus connecté à LocoMotion. À bientôt!",
        title: "Déconnexion réussie.",
        variant: "success",
        type: "logout",
      });

      // We want to navigate after the notification so that the login input takes the focus away
      // from the alert
      this.$router.push("/login?logout=1");
    },
  },
};
</script>

<style lang="scss"></style>
