<template>
  <loan-status-alert-container :loan="loan">
    <template #borrower>
      <p v-if="exemptFromContributions">Merci d'avoir participé à LocoMotion!</p>
      <template v-if="!exemptFromContributions">
        <awareness-banner
          class="loan_contribution_thanks"
          :title="
            loan.platform_tip > 0
              ? 'Merci pour votre contribution au projet'
              : 'Profitez et contribuez ...à votre façon'
          "
          :image="'/coeur-du-partage-locomotion.svg'"
        >
          <template v-if="loan.platform_tip > 0">
            <p>
              Grâce à vous, LocoMotion continue sa route vers ses rêves&nbsp;: des quartiers
              apaisés, des inégalités atténuées et des possibilités pour se libérer de vies centrées
              sur l’auto privée.
            </p>
            <p>
              En plus des dons comme le vôtre, le réseau vit aussi grâce à des communautés vivantes
              et près d’une centaine de bénévoles.
            </p>
            <p>Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!</p>
            <div>
              <icon-button variant="primary" href="https://info.locomotion.app/contribuer/">
                En savoir plus
              </icon-button>
            </div>
          </template>
          <template v-else>
            <p>
              Le réseau LocoMotion roule grâce aux dons, mais aussi grâce à des bénévoles aux cœurs
              engagés!
            </p>
            <p>
              Vous pourriez, par exemple, décider en comité des véhicules à partager, présenter le
              projet, coder en PHP ou encore fabriquer des remorques…
            </p>
            <p>Il y a mille et une façons de s’impliquer. Pour aller plus loin, découvrez-les!</p>
            <div>
              <icon-button variant="primary" href="https://info.locomotion.app/contribuer/">
                Contribuer
              </icon-button>
            </div>
          </template>
        </awareness-banner>
      </template>

      <template v-if="loan.borrower_invoice">
        <hr v-if="!exemptFromContributions" />
        <p>
          <icon-button
            icon="receipt"
            size="sm"
            variant="ghost-secondary"
            @click="$router.push(`/profile/invoices/${loan.borrower_invoice.id}`)"
            >Voir ma facture</icon-button
          >
        </p>
      </template>
    </template>

    <template #owner>
      <p>Merci d'avoir prêté votre véhicule avec LocoMotion.</p>
      <p v-if="loan.owner_invoice && isOwner">
        <icon-button
          icon="receipt"
          size="sm"
          variant="ghost-secondary"
          @click="$router.push(`/profile/invoices/${loan.owner_invoice.id}`)"
          >Voir ma facture</icon-button
        >
      </p>
      <template
        v-if="
          !loan.is_self_service &&
          loan.loanable.sharing_mode === 'hybrid' &&
          !loan.is_borrower_trusted
        "
      >
        <h4 class="alert-heading">Ajouter au réseau de confiance</h4>
        <p>
          Si l'emprunt s'est bien déroulé vous pouvez ajouter
          {{ loan.borrower_user.name }} au réseau de confiance pour ce véhicule. Leurs futurs
          emprunts n'auront plus besoin de votre approbation (mode libre partage).
        </p>
        <p>
          <icon-button
            variant="outline-success"
            icon="shield-fill-check"
            :onclick="addBorrowerToTrustedBorrowers"
          >
            Ajouter {{ loan.borrower_user.name }}
          </icon-button>
          <b-button variant="link" :to="`/profile/loanables/${loan.loanable.id}#trusted_borrowers`">
            Voir le réseau de confiance
          </b-button>
        </p>
      </template>
    </template>

    <template #admin>
      <p v-if="isGlobalAdmin && (loan.borrower_invoice || loan.owner_invoice)">
        Voir les factures&nbsp;:
        <span class="button-list mt-2">
          <icon-button
            v-if="loan.borrower_invoice"
            icon="receipt"
            size="sm"
            variant="ghost-secondary"
            @click="$router.push(`/admin/invoices/${loan.borrower_invoice.id}`)"
            >Emprunteur-euse</icon-button
          >
          <icon-button
            v-if="loan.owner_invoice"
            icon="receipt"
            size="sm"
            variant="ghost-secondary"
            @click="$router.push(`/admin/invoices/${loan.owner_invoice.id}`)"
            >Propriétaire</icon-button
          >
        </span>
      </p>
      <p v-else>Emprunt complété.</p>
    </template>
  </loan-status-alert-container>
</template>

<script>
import LoanStatusAlertContainer from "@/components/Loan/Status/Alerts/LoanStatusAlertContainer.vue";
import AwarenessBanner from "@/components/shared/AwarenessBanner.vue";
import IconButton from "@/components/shared/IconButton.vue";
import { isOwner } from "@/helpers/permissions/loanables";
import { isGlobalAdmin } from "@/helpers/permissions/users";
import { addTrustedBorrower } from "@/requests/trustedBorrowersRequests";

export default {
  name: "LoanCompleted",
  components: {
    AwarenessBanner,
    LoanStatusAlertContainer,
    IconButton,
  },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isOwner() {
      return isOwner(this.$store.state.user, this.loan.loanable);
    },
    exemptFromContributions() {
      return this.loan.applicable_amount_types.contributions === "not_applicable";
    },
    isGlobalAdmin() {
      return isGlobalAdmin(this.$store.state.user);
    },
  },
  methods: {
    async addBorrowerToTrustedBorrowers() {
      await addTrustedBorrower(this.loan.loanable.id, this.loan.borrower_user.id);
      this.$emit(
        "input",
        { ...this.loan, is_borrower_trusted: true },
        {
          refreshDashboard: false,
          freshlyLoaded: false,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.loan_contribution_thanks {
  grid-template-columns: 12rem auto;
  margin-top: -3rem;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    margin-top: -1rem;
  }

  .awareness-banner__image {
    // Unset object-fit: cover which affects svgs weirdly.
    object-fit: unset;
    max-width: 12rem;
  }
}
</style>
