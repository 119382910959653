<script>
import Bike from "@/assets/svg/bike.svg";
import BikeElectric from "@/assets/svg/bike_electric.svg";
import BikeCargo from "@/assets/svg/bike_cargo.svg";
import BikeCargoElectric from "@/assets/svg/bike_cargo_electric.svg";
import CarLarge from "@/assets/svg/car_large.svg";
import CarSmall from "@/assets/svg/car_small.svg";
import CarElectric from "@/assets/svg/car_electric.svg";
import Trailer from "@/assets/svg/trailer.svg";
import { capitalize } from "@/helpers/filters";
import Vue from "vue";

const sortedPricingLoanableTypes = [
  "bike_regular",
  "bike_electric",
  "bike_cargo_regular",
  "bike_cargo_electric",
  "trailer",
  "car_small",
  "car_large",
  "car_electric",
];

export default Vue.extend({
  name: "PricingLoanableTypes",
  components: {
    bike_regular: Bike,
    bike_electric: BikeElectric,
    bike_cargo_regular: BikeCargo,
    bike_cargo_electric: BikeCargoElectric,
    car_small: CarSmall,
    car_large: CarLarge,
    car_electric: CarElectric,
    trailer: Trailer,
  },
  props: {
    pricing: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loanableTypes() {
      // Duplicate the array to avoid sorting in place (which will result in a hard to debug
      // infinite render loop if used from PaginatedTable. Guess how I found out!)
      let sortedLoanableTypes = [...this.pricing.pricing_loanable_types];
      return sortedLoanableTypes.sort(
        (a, b) => sortedPricingLoanableTypes.indexOf(a) - sortedPricingLoanableTypes.indexOf(b)
      );
    },
  },
  methods: {
    capitalize,
    getVariant(loanableType) {
      if (loanableType.startsWith("car")) {
        return "warning";
      }
      if (loanableType.startsWith("bike")) {
        return "success";
      }
      return "primary";
    },
  },
});
</script>

<template>
  <div class="badge-list">
    <b-badge
      v-for="loanableType in loanableTypes"
      :key="loanableType"
      :variant="getVariant(loanableType)"
    >
      <component
        :is="loanableType"
        v-b-tooltip="capitalize($t(`pricings.loanableTypes.${loanableType}`))"
        class="loanable-type-icon"
      />
    </b-badge>
  </div>
</template>

<style scoped lang="scss">
.badge-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.loanable-type-icon {
  height: 1.125rem;
}
</style>
