<template>
  <div>
    <h2 id="claim-credit-methods">Coordonnées bancaires</h2>
    <p>
      Lorsque vous voulez réclamer votre solde, remplissez vos informations ci-dessous pour
      simplifier la procédure. Vous pouvez aussi nous envoyer un courriel à
      <a
        href="mailto:info@locomotion.app?subject=Demande de transfert du solde du compte LocoMotion vers un compte bancaire&body=Veuillez joindre un spécimen chèque à ce courriel si besoin. %0D%0A
---- %0D%0A
Bonjour,%0D%0A%0D%0A

Je souhaite transférer la totalité de mon solde LocoMotion vers mon compte bancaire. %0D%0A%0D%0A

Voici les coordonnées de mon compte: %0D%0A%0D%0A

Numéro de compte:%0D%0A
Numéro de transit: %0D%0A
Code banque: %0D%0A
%0D%0A
OU %0D%0A  %0D%0A
Voici un spécimen chèque en pièce jointe. %0D%0A %0D%0A
Cordialement, %0D%0A"
        >info@locomotion.app</a
      >
      avec cette information à chaque fois que vous désirez réclamer votre compte.
    </p>
    <img src="/cheque.png" alt="exemplaire de chèque" class="mx-auto d-block mb-2 mw-100" />
    <ActionStep :number="1" slim>
      <template #title>
        <span>Numéro de chèque&nbsp;:&nbsp; <strong>non nécessaire</strong></span>
      </template>
    </ActionStep>
    <validation-observer ref="profileFormObserver" v-slot="{ passes, pristine, valid }">
      <b-form @submit.prevent.stop="passes(() => submit(!pristine))">
        <ActionStep :number="2" slim>
          <template #title>
            <forms-validated-input
              v-model="user.bank_transit_number"
              class="flex-fill"
              name="bank_transit_number"
              :label="'Numéro de succursale'"
              :rules="{ required: true, length: 5, numeric: true }"
              type="text"
              size="5"
            />
          </template>
        </ActionStep>

        <ActionStep :number="3" slim>
          <template #title>
            <forms-validated-input
              v-model="user.bank_institution_number"
              name="bank_institution_number"
              class="flex-fill"
              :label="'Numéro d\'institution'"
              :rules="{ required: true, length: 3, numeric: true }"
              type="text"
              size="3"
            />
          </template>
        </ActionStep>

        <ActionStep :number="4" slim>
          <template #title>
            <forms-validated-input
              v-model="user.bank_account_number"
              name="bank_account_number"
              class="flex-fill"
              :label="'Numéro de compte'"
              :rules="{ required: true, min: 7, max: 12, numeric: true }"
              type="text"
            />
          </template>
        </ActionStep>

        <p>En règle générale, nous effectuons les versements en moins d'une semaine.</p>

        <div class="form__buttons">
          <icon-button
            variant="success"
            type="submit"
            :disabled="!valid && pristine"
            :loading="loading || showLoading"
            :icon="saveButtonIcon"
          >
            {{ saveButtonLabel || $t("forms.save") | capitalize }}
          </icon-button>
          <template v-if="hadBankInfoInitially && showReset">
            &nbsp;
            <icon-button icon="trash" variant="outline-danger" :onclick="resetBankInfo">
              Effacer
            </icon-button>
          </template>
          <template v-if="showCancel">
            &nbsp;
            <icon-button role="cancel" @click="$emit('cancel')">Annuler</icon-button>
          </template>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import IconButton from "@/components/shared/IconButton.vue";
import ActionStep from "@/components/shared/Step.vue";
import Vue from "vue";

export default Vue.extend({
  name: "BankDetailsForm",
  components: { IconButton, ActionStep, FormsValidatedInput },
  props: {
    showCancel: {
      type: Boolean,
      default: false,
    },
    saveButtonLabel: {
      type: String,
      default: undefined,
    },
    saveButtonIcon: {
      type: String,
      default: "file-earmark-arrow-down",
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      hadBankInfoInitially: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    hasBankInfo() {
      return (
        this.user.bank_transit_number ||
        this.user.bank_institution_number ||
        this.user.bank_account_number
      );
    },
  },
  mounted() {
    this.hadBankInfoInitially = this.hasBankInfo;
  },
  methods: {
    async resetBankInfo() {
      this.user.bank_account_number = null;
      this.user.bank_transit_number = null;
      this.user.bank_institution_number = null;

      await this.submit(true);
    },
    async submit(changed) {
      if (changed) {
        this.loading = true;
        await this.$store.dispatch("users/update", {
          id: this.user.id,
          data: {
            id: this.user.id,
            bank_transit_number: this.user.bank_transit_number,
            bank_institution_number: this.user.bank_institution_number,
            bank_account_number: this.user.bank_account_number,
          },
          params: {
            fields: "id,bank_transit_number,bank_institution_number,bank_account_number",
          },
        });
      }
      this.$emit("saved");
      this.loading = false;
    },
  },
});
</script>

<style scoped lang="scss"></style>
