var render = function render(){var _vm=this,_c=_vm._self._c;return _c('awareness-banner',{staticClass:"donation",attrs:{"image":_vm.image,"title":_vm.title}},[_vm._t("default"),_c('div',{staticClass:"donation_amount_with_slider"},[_c('div',{staticClass:"custom-range-plus"},[_c('div',{staticClass:"custom-range-context"},_vm._l((_vm.stops),function(stop){return _c('div',{key:stop.amount,staticClass:"range-text-stop",class:{ 'stop-reached': _vm.amount >= stop.amount },style:(`left: ${stop.percent}%`),on:{"click":() => _vm.setAmount(stop.amount)}},[(stop.label)?_c('icon-button',{attrs:{"variant":"outline-primary","size":"inline"}},[_vm._v(" "+_vm._s(stop.label)+" ")]):_vm._e(),_c('div',{staticClass:"range-stop-icon"},[(stop.icon)?_c(stop.icon,{tag:"component"}):_c('div',{staticClass:"spacer"})],1)],1)}),0),_c('div',{staticClass:"slider",class:{ 'slider-focused': _vm.sliderInteractedLast }},[_c('div',{staticClass:"track"},[_c('div',{staticClass:"active-track",class:{
              disabled: _vm.disabled,
              'near-min': _vm.sliderValue < 10,
              'near-max': _vm.sliderValue > 90,
            },style:(`width: ${_vm.sliderValue}%; width: calc(2rem + (100% - 2rem) *  ${
              _vm.sliderValue / 100
            })`)},[_c('b-icon',{staticClass:"slider-arrow slider-arrow-left",attrs:{"icon":"arrow-left-short"}}),_c('b-icon',{staticClass:"slider-arrow slider-arrow-right",attrs:{"icon":"arrow-right-short"}})],1)]),_c('div',{staticClass:"real-range",on:{"touchmove":function($event){$event.stopPropagation();}}},[_c('label',{staticClass:"sr-only",attrs:{"for":"amount-slider"}},[_vm._v("Montant de la contribution")]),_c('b-form-input',{ref:"amountSlider",attrs:{"id":"amount-slider","disabled":_vm.disabled,"name":"amount-slider","value":_vm.sliderValue,"type":"range","min":0,"max":100,"step":_vm.currentStep,"aria-valuenow":_vm.amount,"aria-valuetext":`${_vm.amount} $`,"aria-label":"Montant de la contribution","aria-valuemin":_vm.minimum,"aria-valuemax":_vm.maximum,"role":"slider"},on:{"input":_vm.handleSlider,"blur":function($event){_vm.sliderInteractedLast = false},"touchend":function($event){_vm.sliderInteractedLast = false},"touchstart":() => _vm.$refs.amountSlider.focus(),"focus":function($event){_vm.sliderInteractedLast = true}}})],1)])]),_c('div',{staticClass:"donation__amount tabular-nums"},[_c('b-input',{directives:[{name:"currency",rawName:"v-currency",value:({
          locale: 'fr',
          currency: { suffix: ' $' },
          valueRange: { min: _vm.minimum },
          allowNegative: false,
          distractionFree: false,
        }),expression:"{\n          locale: 'fr',\n          currency: { suffix: ' $' },\n          valueRange: { min: minimum },\n          allowNegative: false,\n          distractionFree: false,\n        }"}],ref:"currencyInput",attrs:{"type":"text","aria-label":"Montant de la contribution","value":_vm.formattedAmount},on:{"focus":function($event){_vm.inputHasFocus = true},"blur":_vm.handleCurrencyInputChange}})],1)]),_c('small',{staticClass:"text-right text-muted"},[_vm._t("under"),(_vm.subscriptionAvailable && _vm.pricingLoanableType)?[_vm._v(" Pour faire disparaître ce module, financez le projet d’un coup avec la "),_c('b-link',{attrs:{"to":`/profile/subscriptions?loanable_type=${_vm.pricingLoanableType}&community_id=${_vm.communityId}`}},[_vm._v("contribution annuelle")]),_vm._v(". ")]:_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }