export default {
  fr: {
    filters: "Filtres",
    applied_filters: "aucun filtre appliqué | 1 filtre appliqué | {n} filtres appliqués",
  },
  en: {
    filters: "Filters",
    applied_filters: "No filter | 1 filter | {n} filters",
  },
};
