<template>
  <div class="password-form">
    <validation-observer ref="observer" v-slot="{ passes }">
      <b-form
        :novalidate="true"
        class="password-form__form"
        @submit.stop.prevent="passes(updatePassword)"
      >
        <forms-validated-input
          v-if="mustValidateCurrent"
          v-model="currentPassword"
          mode="lazy"
          name="current_password_for_password_change"
          :label="$t('current_password')"
          :rules="{ required: required || !!newPassword }"
          type="password"
          :placeholder="$t('current_password')"
        />

        <forms-validated-input
          v-model="newPassword"
          mode="lazy"
          name="new_password"
          :label="$t('new_password')"
          :rules="{ required: !user.id || required, min: 8 }"
          type="password"
          :placeholder="$t('new_password')"
          :description="$t('new_password_length')"
        />

        <forms-validated-input
          v-model="newPasswordRepeat"
          mode="lazy"
          name="new_password_repeat"
          :label="$t('new_password_repeat')"
          :rules="{ required: !user.id || required, is: newPassword }"
          type="password"
          :placeholder="$t('new_password_repeat')"
        />

        <icon-button
          v-if="!!user.id"
          type="submit"
          :disabled="loading"
          role="save"
          :loading="saving"
        >
          {{ $t("submit") }}
        </icon-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";

import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";

export default {
  name: "UserPasswordForm",
  components: {
    IconButton,
    FormsValidatedInput,
  },
  props: {
    mustValidateCurrent: {
      type: Boolean,
      required: false,
      default: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      saving: false,
    };
  },
  watch: {
    newPassword(val) {
      if (!this.user.id) {
        this.user.password = val;
      }
    },
  },
  methods: {
    async updatePassword() {
      const {
        currentPassword,
        newPassword,
        user: { id: userId },
      } = this;

      this.saving = true;
      try {
        const response = await this.$store.dispatch("users/updatePassword", {
          currentPassword,
          newPassword,
          userId,
        });
        // if response is received, the password has been succesfully updated, and we show the success message.
        if (response) this.$emit("updated");
      } finally {
        this.saving = false;
      }
    },
    reset() {
      this.currentPassword = "";
      this.newPassword = "";
      this.newPasswordRepeat = "";
      this.$refs.observer.reset();
    },
  },
  i18n: {
    messages: {
      fr: {
        ...locales.fr.components.user.password_form,
      },
    },
  },
};
</script>

<style lang="scss"></style>
