<template>
  <div class="register-form">
    <invitation-header v-if="hasInvitation" :invitation="invitation" />
    <h2 v-else class="text-center">
      {{ $t("register") }}
    </h2>

    <div class="register-form__google">
      <google-auth-button id="google-register-btn" :label="$t('google')" />
    </div>

    <div class="form__separator">
      <span class="form__separator__text">{{ $t("or") }}</span>
    </div>

    <validation-observer ref="observer" v-slot="{ passes }">
      <b-form
        :novalidate="true"
        class="register-form__form"
        @submit.stop.prevent="passes(register)"
      >
        <forms-validated-input
          v-model="email"
          autofocus
          mode="eager"
          name="email"
          :label="$t('email')"
          :rules="{ required: true, email: true }"
          type="email"
          :placeholder="$t('email')"
        />

        <forms-validated-input
          v-model="password"
          mode="eager"
          name="password"
          :label="$t('password')"
          :rules="{ required: true, min: 8 }"
          type="password"
          :placeholder="$t('password')"
          :description="$t('password_length')"
        />

        <forms-validated-input
          v-model="passwordRepeat"
          mode="eager"
          name="password_repeat"
          :label="$t('password_repeat')"
          :rules="{ required: true, is: password }"
          type="password"
          :placeholder="$t('password_repeat')"
        />

        <b-alert v-if="hasInvitation && invitation.accountExists" show variant="warning">
          <p>
            L'invitation reçue correspond à un compte existant. Connectez-vous avec l'adresse
            courriel où l'invitation à été envoyée.
          </p>

          <icon-button variant="success" to="/login" replace>Se connecter</icon-button>&nbsp;
          <icon-button
            class="ml-2"
            variant="outline-warning"
            :loading="loading"
            @click="clearInvitationAndRegister"
          >
            Continuer l'inscription <strong>sans l'invitation</strong>
          </icon-button>
        </b-alert>

        <b-alert v-else-if="validation && !validation.valid" class="mb-0" variant="warning" show>
          <h4>Erreur avec l'invitation</h4>
          <p>
            {{ validation.message }}
          </p>

          <p>
            Vous pouvez néanmoins continuer votre inscription, mais vous serez seulement ajoutés à
            votre communauté géographique, si elle existe.
          </p>
          <icon-button
            :loading="loading"
            variant="outline-warning"
            class="mt-3"
            block
            @click="passes(clearInvitationAndRegister)"
          >
            {{ $t("register_submit") }} <strong> sans invitation</strong>
          </icon-button>
        </b-alert>

        <icon-button v-else type="submit" :loading="loading" variant="success" block>
          {{ $t("register_submit") }}
        </icon-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import FormsValidatedInput from "@/components/Forms/ValidatedInput.vue";
import InvitationHeader from "@/components/Invitation/InvitationHeader.vue";
import GoogleAuthButton from "@/components/Misc/GoogleAuthButton.vue";
import IconButton from "@/components/shared/IconButton.vue";
import locales from "@/locales";
import { validateInvitation } from "@/requests/invitationRequests";

export default {
  name: "RegisterBox",
  components: {
    IconButton,
    InvitationHeader,
    FormsValidatedInput,
    GoogleAuthButton,
  },
  data() {
    return {
      password: "",
      passwordRepeat: "",
      validation: null,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    email: {
      get() {
        return this.$store.state.register.email;
      },
      set(value) {
        return this.$store.commit("register/email", value);
      },
    },
    invitation() {
      return this.$store.state.invitation;
    },
    hasInvitation() {
      return this.invitation.id && this.invitation.code;
    },
  },
  watch: {
    email() {
      // If validation error is caused by wrong email, reset validation on email change.
      if (
        this.validation &&
        !this.validation.notFound &&
        this.validation.isActive &&
        this.validation.codeValid &&
        !this.validation.emailValid
      ) {
        this.validation = null;
      }
    },
  },
  methods: {
    clearInvitationAndRegister() {
      this.$store.commit("invitation/clear");
      this.register();
    },
    async register() {
      this.$store.commit("loading", true);

      const registerPayload = {
        email: this.email,
        password: this.password,
      };

      if (this.hasInvitation) {
        // Validate the current invitation to avoid the creation of accounts with emails that do
        // not match the invitation emails, mainly.
        this.validation = await validateInvitation(
          this.invitation.id,
          this.invitation.code,
          this.email
        );

        if (!this.validation.valid) {
          this.$store.commit("loading", false);
          return;
        }

        registerPayload.code = this.invitation.code;
      }

      await this.$store.dispatch("register", registerPayload);

      await this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });

      this.$router.replace("/register/2");
    },
  },
  i18n: {
    messages: {
      fr: {
        ...locales.fr.components.register.register_form,
      },
      en: {
        ...locales.en.components.register.register_form,
      },
    },
  },
};
</script>

<style lang="scss">
.register-form {
  .btn-primary {
    margin-left: 0;
  }

  &__google {
    margin-top: 2rem;
    text-align: center;
  }

  &__form {
    margin-top: 32px;
  }
}
</style>
