<template>
  <div>
    <div class="exceptions">
      <div v-for="(exception, index) in exceptions" :key="index">
        <loanable-exception
          :exception="exception"
          :index="index"
          @change="emitChange"
          @delete="() => removeException(exception)"
        >
        </loanable-exception>
      </div>
    </div>

    <div>
      Ajouter une exception
      <div class="new_exception_row">
        <icon-button
          icon="calendar-day"
          variant="ghost-secondary"
          @click="addException('weekdays')"
        >
          Jours de la semaine
        </icon-button>
        <icon-button icon="calendar-week" variant="ghost-secondary" @click="addException('dates')">
          Dates en particulier
        </icon-button>
        <icon-button
          icon="calendar-range"
          variant="ghost-secondary"
          @click="addException('dateRange')"
        >
          Période
        </icon-button>
      </div>
    </div>
  </div>
</template>

<script>
import LoanableException from "@/components/Loanable/Exception.vue";
import IconButton from "@/components/shared/IconButton.vue";

export default {
  name: "LoanableExceptions",
  components: { LoanableException, IconButton },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    exceptions: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  methods: {
    addException(type) {
      if (!this.disabled) {
        this.$emit("input", [
          ...this.exceptions,
          {
            available: this.mode === "never",
            type,
            scope: [],
            period: "00:00-24:00",
          },
        ]);
      }
    },
    emitChange(target, key, value) {
      const newExceptions = [...this.exceptions];
      const index = newExceptions.indexOf(target);
      const newTarget = { ...target };
      newTarget[key] = value;

      newExceptions.splice(index, 1, newTarget);
      this.$emit("input", newExceptions);
    },
    removeException(exception) {
      if (!this.disabled) {
        const newExceptions = [...this.exceptions];
        const index = newExceptions.indexOf(exception);
        newExceptions.splice(index, 1);
        this.$emit("input", newExceptions);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/mixins/breakpoints";
.exceptions {
  .exception {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
.new_exception_row {
  flex-wrap: wrap;
  display: flex;
  gap: 0.5rem;
}
</style>
