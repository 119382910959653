<template>
  <div class="user-claim-credits-box">
    <bank-details-form
      save-button-label="Faire une demande de transfert"
      save-button-icon="arrow-right-circle"
      show-cancel
      :show-reset="false"
      @cancel="emitCancel"
      @saved="claimCredit"
    />
  </div>
</template>

<script>
import BankDetailsForm from "@/components/PaymentMethod/BankDetailsForm.vue";

export default {
  name: "UserClaimCreditsBox",
  components: { BankDetailsForm },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emitCancel() {
      this.$emit("cancel");
    },
    async claimCredit() {
      this.loading = true;

      try {
        await this.$store.dispatch("account/claimCredit");

        this.$emit("claimed");

        this.$store.commit("addNotification", {
          content: "Votre demande a bien été envoyée. Vous recevrez une réponse sous peu.",
          title: "Demande envoyée",
          variant: "success",
          type: "balance_claim",
        });
      } catch (e) {
        if (e.request?.status === 429) {
          this.$store.commit("addNotification", {
            content: "Votre demande a déjà été envoyée. Vous recevrez une réponse sous peu.",
            title: "Demande déjà envoyée",
            variant: "warning",
            type: "balance_claim",
          });

          this.$emit("cancel");
        }
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss"></style>
