<template>
  <layout-page name="faq" class="faq__content">
    <vue-headful :title="fullTitle" />

    <b-row tag="section" class="page__section">
      <b-col>
        <b-row>
          <template v-for="(section, s) in faq.fr.sections">
            <b-col
              v-if="!section.carOnly || inCarLoaningCommunity"
              :key="`section-${s}`"
              lg="6"
              class="page__section__content"
            >
              <!-- eslint-disable-next-line vue/no-v-html-->
              <h2 v-html="$t('faq.sections.' + s + '.title')" />

              <div role="tablist">
                <template v-for="(question, q) in section.questions">
                  <faq-item
                    v-if="!question.carOnly || inCarLoaningCommunity"
                    :id="`section-${s}-question-${q}`"
                    :key="`section-${s}-question-${q}`"
                    :title="$t(`faq.sections.${s}.questions.${q}.title`)"
                  >
                    <!-- eslint-disable-next-line vue/no-v-html-->
                    <div v-html="$t(`faq.sections.${s}.questions.${q}.content`)" />
                  </faq-item>
                </template>
              </div>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <b-row tag="section" class="page__section">
      <b-col>
        <b-card>
          <b-card-body>
            <b-card-text>
              <p>
                Pour toute autre question joignez l’Équipe LocoMotion à
                <a href="mailto:info@locomotion.app">info@locomotion.app</a>.
              </p>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </layout-page>
</template>

<script>
import FaqItem from "@/components/Misc/FaqItem.vue";

import { capitalize } from "@/helpers/filters";

import faq from "@/locales/faq";

export default {
  name: "MainFAQ",
  components: {
    FaqItem,
  },
  data() {
    return {
      faq,
    };
  },
  computed: {
    fullTitle() {
      const parts = ["LocoMotion", capitalize(this.$i18n.t("titles.faq"))];

      return parts.reverse().join(" | ");
    },
    inCarLoaningCommunity() {
      const user = this.$store.state.user;
      if (!user) {
        return true;
      }

      return user.available_loanable_types.includes("car");
    },
  },
};
</script>

<style lang="scss">
.page.faq {
  .page__background {
    background: #f5f8fb;
    background-image: url("/home-motif.png");
    background-repeat: repeat;
  }

  .page__section__content {
    margin-top: 60px;
    margin-bottom: 60px;

    h2 {
      color: $secondary;
    }
  }

  .card {
    border: 0;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .card-header {
    margin: 0;
    padding: 0;
  }
}
</style>
