var render = function render(){var _vm=this,_c=_vm._self._c;return _c('paginated-table',{ref:"table",attrs:{"id":"profile-loans-table","columns":_vm.columns,"extra-filters":_vm.extraFilters,"extra-data":[
    'extension_duration_in_minutes',
    'community.deleted_at',
    'loanable.can_edit',
    'loanable.timezone',
    'loanable.active_incidents',
    'borrower_validated_at',
    'owner_validated_at',
    'borrower_must_pay_compensation',
    'borrower_must_pay_insurance',
    'borrower_may_contribute',
    'owner_action_required',
    'borrower_action_required',
  ],"endpoint":"loans","fetch-params":{ for: 'profile' },"sync-filters-with-url":"","show-action-column":false},on:{"filters-changed":_vm.filtersUpdated,"row-clicked":(item) => _vm.$router.push(`/loans/${item.id}`)},scopedSlots:_vm._u([{key:"cell(status)",fn:function({ item }){return [_c('loan-status',{attrs:{"item":item}})]}},{key:"head-buttons",fn:function(){return [_c('b-dropdown',{attrs:{"right":"","text":_vm.modeText,"variant":"ghost-secondary","toggle-class":{ active: _vm.modeText !== 'Tous les emprunts' }}},[(_vm.modeText !== 'Tous les emprunts')?_c('b-dropdown-item',{on:{"click":_vm.showAllLoans}},[_vm._v(" Tous les emprunts ")]):_vm._e(),(_vm.modeText !== 'Mes emprunts')?_c('b-dropdown-item',{on:{"click":_vm.showMyLoans}},[_vm._v(" Mes emprunts ")]):_vm._e(),(_vm.modeText !== 'Emprunts de mes véhicules')?_c('b-dropdown-item',{on:{"click":_vm.showLoansOfMyLoanables}},[_vm._v(" Emprunts de mes véhicules ")]):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }