import dayjs from "@/helpers/dayjs";

/**
 * @param loan
 * @return {{returnAt: dayjs.Dayjs, departureAt: dayjs.Dayjs, timezoneAbbrev: String, differsFromCurrentTimezone: boolean}}
 */
export function getZonedTimes(loan) {
  const departureAt = dayjs(loan.departure_at).tz(loan.loanable.timezone);
  let returnAt;

  if (!loan.actual_return_at) {
    returnAt = dayjs(loan.departure_at)
      .add(loan.duration_in_minutes, "minute")
      .tz(loan.loanable.timezone);
  } else {
    returnAt = dayjs.atTz(loan.actual_return_at, loan.loanable.timezone);
  }

  const timezoneAbbrev = departureAt.format("z (Z)");
  const differsFromCurrentTimezone =
    dayjs().tz(loan.loanable.timezone).format("z") !== dayjs().format("z");

  return {
    departureAt,
    returnAt,
    timezoneAbbrev,
    differsFromCurrentTimezone,
  };
}
