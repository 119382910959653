export default {
  fr: {
    email: "Courriel",
    instructions:
      "Entrez l'adresse courriel associée à votre compte. Un courriel vous sera envoyé avec un lien pour réinitialiser votre mot de passe.",
    request_response:
      "Un courriel a été envoyé à l'adresse indiquée avec des instructions pour réinitialiser le mot de passe.",
    request_response_error: "Une erreur s'est produite. Vérifiez l'adresse utilisée et réessayez.",
    request_password: "J'ai oublié mon mot de passe",
    submit: "Envoyer",
  },
};
