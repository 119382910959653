export default {
  fr: {
    item_types: {
      loan_price: "Temps et distance",
      loan_expenses: "Dépenses déduites",
      loan_insurance: "Assurances",
      donation_loan: "Contribution volontaire",
    },
    fields: {
      item_types: {
        "balance.provision": "Approvisionnement du compte",
        "balance.refund": "Remboursement de solde",
        "contribution.yearly": "Contribution annuelle",
        "loan.price": "Tarif de l'emprunt (temps et distance)",
        "loan.expenses": "Dépenses liées à l'emprunt (essence, etc.)",
        "loan.insurance": "Assurance pour l'emprunt",
        "loan.contribution": "Contribution pour l'emprunt",
        "donation.loan": "Don dans le cadre d'un emprunt",
        "donation.balance": "Don à partir du solde de l'utilisateur",
        "fees.stripe": "Frais de transaction Stripe",
      },
    },
  },
};
