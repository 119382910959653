<template>
  <div v-if="itemLoaded && routeDataLoaded" class="profile-borrower">
    <div v-if="item.borrower.validated" class="profile-borrower__completed-alert">
      <b-alert v-if="changed" variant="danger" show>
        <h4 class="alert-heading">Attention!</h4>
        <p>
          Vous modifiez les informations de votre dossier de conduite déjà validé. Ce changement
          révoquera l'approbation de votre dossier et vous ne pourrez plus emprunter d'auto tant
          qu'un administrateur ne l'aura pas validé.
        </p>
      </b-alert>
      <b-alert v-else variant="success" show>
        <p>Votre dossier de conduite est complet et a été validé par l'équipe de LocoMotion.</p>
      </b-alert>
    </div>
    <div
      v-else-if="item.borrower.submitted_at && !item.borrower.suspended"
      class="profile-borrower__completed-alert"
    >
      <b-alert variant="warning" show>
        <p>
          Votre dossier de conduite est complet et doit maintenant être validé par l'équipe de
          LocoMotion.
        </p>
      </b-alert>
    </div>

    <b-alert variant="info" show>
      <p>Notez que vous devez avoir 21 ans ou plus pour pouvoir emprunter une auto.</p>
      <p>
        Les informations ci-contre ne sont requises que si vous désirez emprunter des autos.
        Celles-ci ne seront accessibles que par l'équipe de LocoMotion ou les représentants des
        assurances Desjardins. Consultez notre
        <a href="/privacy" target="_blank">politique de confidentialité</a>.
      </p>
    </b-alert>

    <borrower-form
      v-if="item"
      :loading="loading"
      :borrower="item.borrower"
      :changed="changed"
      @reset="reset"
      @save="submit"
      @submit="submitBorrower"
    />
  </div>
  <layout-loading v-else />
</template>

<script>
import BorrowerForm from "@/components/Borrower/BorrowerForm.vue";

import DataRouteGuards from "@/mixins/DataRouteGuards";
import FormMixin from "@/mixins/FormMixin";

export default {
  name: "ProfileBorrower",
  components: { BorrowerForm },
  mixins: [DataRouteGuards, FormMixin],
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "me",
    },
  },
  methods: {
    async submitBorrower() {
      await this.$store.dispatch("users/submitBorrower");

      // send up notification and navigate to dashboard.
      this.$store.commit("addNotification", {
        content: "Un membre de notre équipe va valider votre dossier.",
        title: "Dossier de conduite mis à jour!",
        variant: "success",
      });

      this.$router.push("/app");
    },
  },
};
</script>

<style lang="scss">
.profile-borrower {
  &__completed-alert {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
