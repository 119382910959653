import prefixFields from "@/locales/prefixFields";

import communities from "./communities";
import paymentMethods from "./paymentMethods";

export default {
  fr: {
    descriptions: {
      description:
        "Partagez ce qu’il vous plait! Vos passions, le nom de vos enfants, votre film préféré ou vos plus grandes folies!",
      avatar:
        "Pour partager, il faut se faire confiance. Montrer votre visage rassure votre communauté LocoMotion.",
    },
    fields: {
      accept_conditions: `conditions d'utilisation acceptées`,
      address: "adresse",
      avatar: "photo de profil",
      communities: prefixFields(communities.fr.fields, "(Communauté)"),
      created_at: "créé",
      date_of_birth: "date de naissance",
      deleted_at: "archivé",
      description: "description",
      email: "courriel",
      full_name: "nom complet",
      id: "ID",
      is_smart_phone: "téléphone intelligent?",
      last_name: "nom",
      name: "prénom",
      other_phone: "autre numéro de téléphone",
      phone: "téléphone",
      postal_code: "code postal",
      bank_account_number: "numéro de compte bancaire",
      bank_institution_number: "numéro d'institution bancaire",
      bank_transit_number: "numéro de transit bancaire",
    },
    list: {
      create: "ajouter un membre",
      selected: "{count} membre sélectionné | {count} membres sélectionnés",
    },
    password_change: {
      title: "Mot de passe mis à jour",
      content: `<p>Le mot de passe de l'utilisateur a été mis à jour.</p>`,
    },
    archive: "Désactiver",
    approve: "Approuver",
    mandate_tool_tip: "Cliquez ici pour vous connecter à la place de l'utilisateur",
    model_name: "membre | membres",
    payment_methods: paymentMethods.fr,
    restore: "Réactiver",
    suspend: "Suspendre",
  },
};
