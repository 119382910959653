<template>
  <div class="exception" :class="{ 'exception-lg': exception.type === 'dateRange' }">
    <div class="d-flex flex-column exception-parameters">
      <b-form-checkbox-group
        v-if="exception.type === 'weekdays'"
        :id="`weekdays-${index}`"
        :disabled="disabled"
        :checked="exception.scope"
        name="`weekdays-${index}`"
        @change="emitChange('scope', $event)"
      >
        <b-form-checkbox value="SU">Dimanche</b-form-checkbox><br />
        <b-form-checkbox value="MO">Lundi</b-form-checkbox><br />
        <b-form-checkbox value="TU">Mardi</b-form-checkbox><br />
        <b-form-checkbox value="WE">Mercredi</b-form-checkbox><br />
        <b-form-checkbox value="TH">Jeudi</b-form-checkbox><br />
        <b-form-checkbox value="FR">Vendredi</b-form-checkbox><br />
        <b-form-checkbox value="SA">Samedi</b-form-checkbox>
      </b-form-checkbox-group>
      <forms-date-picker
        v-if="exception.type === 'dates'"
        :disabled="disabled"
        inline
        multiple
        :value="exception.scope"
        @input="selectDates($event)"
      />
      <forms-date-range-picker
        v-if="exception.type === 'dateRange'"
        :disabled="disabled"
        inline
        :value="exception.scope"
        @input="selectDates($event)"
      />
      <div class="flex-grow-1 d-flex">
        <date-picker
          class="period-input"
          type="time"
          range
          :value="period"
          :show-second="false"
          :minute-step="15"
          range-separator="-"
          format="HH:mm"
          @change="setPeriod"
          @clear="makeFullDay"
        >
          <template v-if="fullDay" #input="data">
            <input v-bind="{ ...data.props, value: 'Toute la journée' }" />
          </template>
          <template #footer>
            <button class="mx-btn mx-btn-text" @click="makeFullDay">Toute la journée</button>
          </template>
        </date-picker>

        <div class="ml-2 text-right">
          <icon-button size="sm" role="remove-item" @click.prevent="$emit('delete')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormsDatePicker from "@/components/Forms/DatePicker.vue";
import FormsDateRangePicker from "@/components/Forms/DateRangePicker.vue";
import IconButton from "@/components/shared/IconButton.vue";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";

export default {
  name: "LoanableException",
  components: { FormsDateRangePicker, IconButton, FormsDatePicker, DatePicker },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    exception: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fullDay() {
      return this.exception.period === "00:00-24:00";
    },
    periodArray() {
      if (!this.exception.period) {
        return null;
      }

      return this.exception.period.split("-");
    },
    periodStart() {
      if (!this.periodArray) {
        return null;
      }

      return this.periodArray[0];
    },
    periodEnd() {
      if (!this.periodArray) {
        return null;
      }

      return this.periodArray[1];
    },
    period() {
      if (this.fullDay) {
        return [null, null];
      }

      if (!this.periodArray) {
        return null;
      }
      let [startHour, startMinute] = this.periodStart.split(":");
      let [endHour, endMinute] = this.periodEnd.split(":");

      return [
        dayjs().set("hours", startHour).set("minutes", startMinute).toDate(),
        dayjs().set("hours", endHour).set("minutes", endMinute).toDate(),
      ];
    },
  },
  methods: {
    setPeriod(dates) {
      if (!dates || !dates.length || !dates[0] || !dates[1]) {
        return;
      }

      let start = dates[0].format("HH:mm");
      let end = dates[1].format("HH:mm");

      if (dayjs(dates[0]).isSameOrAfter(dates[1], "minute")) {
        end = dayjs(dates[1]).add(15, "minutes").format("HH:mm");
      }

      this.emitChange("period", `${start}-${end}`);
    },
    emitChange(key, value) {
      this.$emit("change", this.exception, key, value);
    },
    selectDates(dates) {
      this.emitChange("scope", dates);
    },
    makeFullDay() {
      this.emitChange("period", "00:00-24:00");
    },
  },
};
</script>

<style lang="scss">
.exception {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: $small-shadow;
  background: $white;

  h4 {
    font-size: 1.25rem;
  }
  .exception-parameters {
    flex-wrap: wrap;
    gap: 1rem;
    .period-input {
      min-width: 13rem;
    }
  }
}
</style>
