import Vue from "vue";

export default {
  namespaced: true,
  state: {},
  mutations: {
    reset(state) {
      Object.keys(state).forEach((k) => delete state[k]);
    },
    updateListState(
      state,
      { listId, sortBy, sortDesc, filters, perPage, currentPage, columnsToShow }
    ) {
      Vue.set(state, listId, {
        currentPage,
        perPage,
        filters,
        sortBy,
        sortDesc,
        columnsToShow,
      });
    },
  },
};
