import prefixFields from "@/locales/prefixFields";

import borrowers from "./borrowers";
import communities from "./communities";
import loanables from "./loanables";

const statuss = {
  null: "-",
  in_process: "En cours",
  canceled: "Annulé",
  completed: "Complété",
};

export default {
  fr: {
    create_loan: "créer un emprunt",
    descriptions: {
      platform_tip:
        "LocoMotion est un projet citoyen et collaboratif. Participez à son " +
        "fonctionnement en donnant une contribution volontaire.",
    },
    loan_approved: "La réservation est confirmée!",
    modification_warning: {
      title: "Pour modifier votre réservation après l'heure de départ prévue",
      need_cancel: "merci de l'annuler et d'en créer une nouvelle ou signalez un retard.",
      can_modify: "Pour modifiez votre réservation, mettez à jour les informations ici.",
    },
    insurance_warning: {
      title: "Avant d'emprunter une auto",
      warning: "Lisez attentivement les {link}. {emphasis}",
      terms: "conditions d'utilisation de l'assurance",
      terms_link: "/conditions-utilisation-desjardins-assurances.html",
      car_stays_in_quebec: "Notez que l'assurance couvre seulement les trajets au Québec.",
    },
    fields: {
      id: "ID",
      alternative_to: "Mode de transport remplacé",
      alternative_to_other: "Précisez l'autre mode de transport",
      borrower_user_id: "emprunteur-se",
      borrower: borrowers.fr.fields,
      calendar_days: "jours calendaires",
      community_id: "communauté",
      community: {
        ...prefixFields(communities.fr.fields, "(Communauté)"),
        model_name: communities.fr.model_name,
      },
      current_step: "étape en cours",
      departure_at: "départ",
      duration_in_minutes: "durée (minutes)",
      estimated_distance: "distance prévue (km)",
      estimated_insurance: "coût estimé de l'assurance",
      estimated_price: "coût estimé",
      loanable_id: loanables.fr.model.singular,
      loanable: {
        ...prefixFields(loanables.fr.fields, "(Véhicule)"),
        model_name: loanables.fr.model_name,
      },
      status: "statut de l'emprunt",
      statuss: statuss,
      loanable_type: "type de véhicule",
      message_for_owner: "message pour les responsables",
      platform_tip: "contribution volontaire LocoMotion",
      price: "prix",
      return_at: "retour",
    },
    model: {
      singular: "emprunt",
    },
    model_name: "emprunt | emprunts",
    fields_description: {
      alternative_to:
        "Si vous n'aviez pas pu emprunter ce véhicule sur LocoMotion, quel moyen auriez-vous utilisé?",
      platform_tip:
        "LocoMotion est un projet citoyen et collaboratif. Les contributions volontaires aident à financer son fonctionnement.",
    },
    payment: {
      transferred_amount: "montant transféré entre utilisateurs",
      borrower_total: "total pour l'emprunteur-se",
      insurance: "assurances",
      tip: "contribution volontaire",
      total: "total",
    },
    self_service: {
      is_self_service: "en libre partage",
      on_demand: "sur demande",
      all: "tous",
    },
    details_box: {
      duration: "durée",
      duration_over_days: "{hours} | {hours} | {hours} sur {days} jours",
      estimated_distance: "distance prévue",
      distance: "distance",
      cost: "coût",
      compensation: "compensation",
      estimated_compensation: "compensation estimée",
      tip_modifiable: "Pourra être modifiée lors du paiement final.",
      pricing_explanation: "explication de la tarification",
    },
    statuses: {
      self_service: "En libre partage",
      new: {
        label: "Nouvel emprunt",
        tooltip: "Emprunt en cours de création!",
      },
      requested: {
        label: "Nouvelle demande",
        tooltip: "Une personne responsable doit approuver cet emprunt",
      },
      accepted: {
        label: "Prépaiement",
        tooltip: "L'emprunteur-euse doit ajouter des fonds à son solde avant de débuter l'emprunt",
      },
      confirmed: {
        label: "Confirmé",
        tooltip: "L'emprunt est confirmé!",
      },
      ongoing: {
        label: "En cours",
        tooltip: "L'emprunt est en cours",
      },
      ended: {
        label: "Validation",
        tooltip: "Les informations de l'emprunt doivent être ajoutées et validées.",
      },
      validated: {
        label: "Paiement",
        label_contribution: "Contribution",
        label_grace_period: "Terminé",
        tooltip: "L'emprunt est terminé, l'emprunteur-euse doit payer.",
      },
      canceled: {
        label: "Annulé",
        tooltip: "L'emprunt n'a pas pu être complété.",
      },
      rejected: {
        label: "Refusé",
        tooltip: "L'emprunt a été refusé par une personne responsable.",
      },
      active_incident: {
        label: "Incident",
        tooltip: "Un incident est en cours de résolution.",
      },
      completed: {
        label: "Complété",
        tooltip: "L'emprunt a été complété avec succès!",
      },
    },
  },
};
