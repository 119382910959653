export default {
  fr: {
    email: "Courriel",
    instructions: "Entrez un nouveau mot de passe pour le compte associé à cette adresse.",
    new_password: "Nouveau mot de passe",
    new_password_length: "Minimum 8 caractères",
    new_password_repeat: "Nouveau mot de passe (confirmation)",
    reset_response_error: "Une erreur s'est produite. Vérifiez l'adresse utilisée et réessayez.",
    reset_password: "Réinitialisation du mot de passe",
    submit: "Mettre à jour",
  },
};
