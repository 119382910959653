<template>
  <layout-page name="insurance" class="insurance__content" padded>
    <b-row tag="section" class="page__section">
      <b-col>
        <b-card>
          <p>
            <strong>À lire pour comprendre l'assurance LocoMotion par Desjardins Assurances</strong>
          </p>
          <ol>
            <li>
              <a href="https://info.locomotion.app/assurances/" target="_blank">
                L'assurance LocoMotion en résumé
              </a>
            </li>
            <li>
              <a href="/conditions-utilisation-desjardins-assurances.html">
                Conditions d'utilisation l'assurance LocoMotion
              </a>
            </li>
            <li>
              <a href="/assurances/police-assurance-locomotion-2023.pdf"> Police d’assurance </a>
            </li>
            <li>
              <a
                href="https://drive.google.com/file/d/1755OkK35_aph-ol9LpB3BJ2JCnkvkIp1/view"
                target="_blank"
              >
                En cas d'accident
              </a>
            </li>
          </ol>
          <p><strong>Vous prêtez votre auto?</strong></p>
          <p>
            👉 Si ce n'est pas déjà fait, voici la
            <a href="/assurances/lettre_assureur_courtier.pdf">lettre à envoyer à votre assureur</a
            >.
          </p>
        </b-card>
      </b-col>
    </b-row>
  </layout-page>
</template>

<script>
export default {
  name: "InsuranceOverview",
};
</script>

<style lang="scss">
.page.insurance {
  .page__background {
    background: #f5f8fb;
    background-image: url("/home-motif.png");
    background-repeat: repeat;
  }
  p,
  ul,
  ol {
    color: $secondary;
  }
}
</style>
