export default {
  fr: {
    new_credit_card: "nouvelle carte de crédit",
    credit_card: "carte de crédit",
    fields: {
      credit_card_type: "type de compte",
      external_id: "numéro de compte",
      name: "nom associé au compte",
    },
    types: {
      credit_card: "carte de crédit",
      bank_account: "compte de banque",
    },
    model_name: "mode de paiement | modes de paiement",
    saved_payment_methods: "Modes de paiement enregistrés",
    add_new: "Ajouter un mode de paiement",
    fees_notice: "Des frais de transaction sont applicables.",
    fee_types: {
      amex: "cartes American Express",
      non_canadian: "autres cartes non canadiennes",
      canadian: "autres cartes canadiennes",
    },
  },
};
