<template>
  <div :class="`forms-map-input ${stateClass}`">
    <div ref="map" class="forms-map-input__map"></div>
    <map-marker
      v-if="markerPosition && mapInitialized"
      :map="map"
      :loanable="loanable"
      :position="markerPosition"
      :ondrag="disabled ? null : savePosition"
    />
    <p v-if="description">{{ description }}</p>
  </div>
</template>

<script>
import MapMarker from "@/components/Loanable/MapMarker.vue";
import { loader, mapId } from "@/helpers/googleMaps";

export default {
  name: "FormsMapInput",
  components: { MapMarker },
  props: {
    bounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    center: {
      type: Object,
      required: false,
      default: () => null,
    },
    description: {
      type: String,
      required: false,
      default: undefined,
    },
    allowFullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: true,
    },
    polygons: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    polygonOptions: {
      type: Object,
      default: () => ({
        fillColor: "#16a59e",
        fillOpacity: 0.25,
        strokeOpacity: 0,
        clickable: false,
        zIndex: 2,
      }),
    },
    value: {
      required: false,
      type: Array,
      default: () => null,
    },
    loanable: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      mapOptions: {
        clickableIcons: false,
        fullscreenControl: this.allowFullscreen,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 19,
        zoom: 14,
      },
      mapInitialized: false,
      mapPolygon: null,
      map: null,
    };
  },
  computed: {
    markerPosition() {
      if (this.value?.length === 2) {
        return {
          lat: this.value[0],
          lng: this.value[1],
        };
      }

      return null;
    },
    stateClass() {
      if (this.state === null) {
        return "";
      }

      if (this.state) {
        return "is-valid";
      }

      return "is-invalid";
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    async initMap() {
      const { Map, Polygon } = await loader.importLibrary("maps");

      const markerPosition =
        this.value?.length === 2
          ? {
              lat: this.value[0],
              lng: this.value[1],
            }
          : null;

      if (!this.$refs.map) {
        // User navigated away from page with map while libraries were loading,
        // component will unload. Avoid creating map.
        return;
      }

      this.map = new Map(this.$refs.map, {
        ...this.mapOptions,
        center: markerPosition || this.center || { lat: 45.5342925, lng: -73.599039 },
        mapId,
        gestureHandling: "greedy",
      });

      this.map.addListener("click", this.savePosition);

      if (this.polygons) {
        this.mapPolygon = new Polygon({
          ...this.polygonOptions,
          paths: this.polygons,
          map: this.map,
        });

        if (!markerPosition && !this.center) {
          const { LatLngBounds } = await loader.importLibrary("core");

          const bounds = new LatLngBounds();

          for (const polygon of this.polygons) {
            for (const point of polygon) {
              bounds.extend(point);
            }
          }

          this.map.fitBounds(bounds);
        }
      }
      this.mapInitialized = true;
    },
    async savePosition(event) {
      if (!this.disabled) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        if (this.bounded && this.mapPolygon) {
          const { poly } = await loader.importLibrary("geometry");
          if (poly.containsLocation(event.latLng, this.mapPolygon)) {
            this.$emit("input", [lat, lng]);
          }
          return;
        }

        this.$emit("input", [lat, lng]);
      }
    },
  },
};
</script>

<style lang="scss">
.forms-map-input {
  // Match other form input border
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  overflow: hidden;

  &__map {
    height: 240px;
  }

  &.is-invalid {
    border: 1px solid $danger;
  }

  &.is-valid {
    border: 1px solid $success;
  }
}
</style>
