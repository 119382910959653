<template>
  <validation-observer ref="observer" v-slot="{ passes, changed }" tag="div">
    <b-form :novalidate="true" class="user-proof-form__form" @submit.stop.prevent="passes(submit)">
      <p>
        Pour favoriser un partage sécuritaire et protéger vos ressources collectives, votre comité
        veut s’assurer que vous êtes bel et bien une vraie personne habitant dans le quartier. Merci
        de fournir une {{ userNeedsIdentityProof ? "preuve d’identité et " : "" }}une preuve
        d’adresse.
      </p>
      <template v-if="userNeedsIdentityProof">
        <h3>Preuve d'identité</h3>
        <identity-proof-input class="mb-3" :loading="loading" :user="user" />
      </template>
      <h3>Preuve d'adresse</h3>
      <residency-proof-input
        :duplicate-drivers-license="userNeedsIdentityProof"
        class="mb-3"
        :loading="loading"
        :user="user"
      />

      <p class="mt-4">
        Une fois tous vos fichiers joints, il ne reste plus qu’à les envoyer! Maintenant, faisons
        preuve de patience. Ce sont de vraies personnes qui valident votre compte. 🙂
      </p>

      <div class="user-proof-form__buttons text-center">
        <icon-button :loading="loading" :disabled="loading || !changed" role="send" type="submit">
          Envoyer
        </icon-button>
        &nbsp;
        <icon-button v-if="skip" role="cancel" :to="skip"> Plus tard</icon-button>
      </div>
      <div class="mt-3">
        <small>
          * Si votre situation ne vous permet pas de démontrer que vous habitez le quartier au moyen
          de la méthode proposée, contactez l’Équipe LocoMotion (info@locomotion.app) pour savoir
          comment vous inscrire.
        </small>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import IconButton from "@/components/shared/IconButton.vue";
import IdentityProofInput from "@/components/User/IdentityProofInput.vue";
import ResidencyProofInput from "@/components/User/ResidencyProofInput.vue";
import locales from "@/locales";

export default {
  name: "ProofForm",
  components: {
    IconButton,
    ResidencyProofInput,
    IdentityProofInput,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    skip: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    userNeedsIdentityProof() {
      for (const community of this.user.communities) {
        if (community.requires_identity_proof) {
          return true;
        }
      }
      return false;
    },
  },
  i18n: {
    messages: {
      en: {
        ...locales.en.communities,
        ...locales.en.forms,
      },
      fr: {
        ...locales.fr.communities,
        ...locales.fr.forms,
      },
    },
  },
  methods: {
    submit(...params) {
      this.$emit("submit", ...params);
    },
  },
};
</script>
<style lang="scss"></style>
